// Core
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import posthog from "posthog-js";

// Root reducer
import { rootReducer } from "redux/reducers";

// User analytics
if (process.env.REACT_APP_USE_POSTHOG === "true") {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_URL,
  });
}

const middleware = [thunk];

// Redux dev logger
if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    duration: true,
    collapsed: true,
    colors: {
      title: () => "#139BFE",
      prevState: () => "#1C5FAF",
      action: () => "#149945",
      nextState: () => "#A47104",
      error: () => "#ff0005",
    },
  });

  middleware.push(logger);
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export { store };
