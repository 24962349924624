// Service
import { ApiError } from "api";
import { AlertService } from "services";

const getAlert = (alertID) => async () => {
  try {
    const { data } = await AlertService.getAlert(alertID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getCommunicationAlert = (alertID) => async () => {
  try {
    const { data } = await AlertService.getCommunicationAlert(alertID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateAlertStatus = (alertID, status) => async () => {
  try {
    const { data } = await AlertService.updateAlertStatus(alertID, status);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

export { getAlert, updateAlertStatus, getCommunicationAlert };
