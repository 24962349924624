// Core
import React from "react";

// Components
import {
  OrdersMobileSortBy,
  OrdersMobileStores,
  OrdersMobileDate,
} from "components";

// Styles
import { useStyles } from "./orders-filters.styles";

const OrdersMobileFilters = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.topFilters}>
        <OrdersMobileStores />
        <OrdersMobileDate />
      </div>

      <div className={classes.bottomFilters}>
        <OrdersMobileSortBy />
      </div>
    </>
  );
};

export default OrdersMobileFilters;
