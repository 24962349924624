// Core
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";

// Actions
import { getUserChannels, setUnreadCount } from "redux/actions/chat-actions";

// Components
import { List, Typography } from "@material-ui/core";
import { Channel } from "components";
import { MuiLoader } from "components/common";
import { Alert } from "@material-ui/lab";

import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./channels.styles";

const Channels = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchByChannelValue = useSelector(
    (state) => state.chat.searchByChannel
  );
  const [channels, setChannels] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["channels"], () => dispatch(getUserChannels()), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (apiResponse) {
      const unreadMessages = Object.assign(
        {},
        ...apiResponse.map((channel) => ({
          [channel.id]: {
            total: channel.unread.messages,
            alerts: channel.unread.alerts,
          },
        }))
      );
      dispatch(setUnreadCount(unreadMessages));
    }
  }, [apiResponse, dispatch]);

  useEffect(() => {
    if (apiResponse) {
      if (searchByChannelValue.length > 0) {
        const filteredChannels = apiResponse.filter(
          ({ name, description, store }) =>
            searchBy(
              {
                name,
                description,
                store_name: store?.store_name,
                store_group0: store?.store_group0,
                manager_district_name: store?.manager_district_name,
                manager_store_name: store?.manager_store_name,
                address_line: `${store?.city} ${store?.state}`,
              },
              searchByChannelValue
            )
        );

        setChannels(filteredChannels);
      } else {
        setChannels(apiResponse);
      }
    }
  }, [searchByChannelValue, apiResponse]);

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      {channels && (
        <List>
          <div className={classes.row}>
            {channels.length === 0 && (
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.empty}
              >
                No channels found
              </Typography>
            )}
          </div>

          {channels.map((channel) => (
            <div key={channel.id}>
              <Channel channel={channel} />
            </div>
          ))}
        </List>
      )}
    </>
  );
};

export default Channels;
