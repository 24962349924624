// Core
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useParams, useHistory } from "react-router";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

// Actions
import { getFeed, searchByFeedAlerts } from "redux/actions/feed-actions";

// Components
import { List } from "@material-ui/core";
import { Alert, ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import {
  MuiWrapper,
  MuiLoader,
  MuiSearch,
  MuiEmptySearch,
} from "components/common";
import { Catcher, ChatAlert } from "components";

// Tools
import { searchBy, toIsoFormat } from "utils/helpers";

// Styles
import { useStyles } from "./feed.styles";

const Feed = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { filter } = useParams();
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const searchFeedAlertsValue = useSelector(
    (state) => state.feed.searchFeedAlertsValue
  );
  const [feedAlerts, setFeedAlerts] = useState(null);
  const [total, setTotal] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
    mutate,
  } = useMutation((data) => dispatch(getFeed(data)), {
    onSuccess: (data) => {
      setTotal(data.total);
      setFeedAlerts(data.messages);
    },
  });

  const handleFilterChange = (_, value) => {
    if (value) {
      history.push(value);
    }
  };

  useEffect(() => {
    if (filter) {
      mutate({ type: filter, date: toIsoFormat(new Date()) });
    }
  }, [filter, mutate]);

  useEffect(() => {
    if (apiResponse) {
      if (searchFeedAlertsValue.length > 0) {
        const filteredAlerts = apiResponse?.messages?.filter(({ alert }) =>
          searchBy(
            {
              description: alert?.description,
              city: alert?.store?.city,
              store_name: alert?.store?.store_name,
              manager_district_name: alert?.store?.manager_district_name,
              manager_store_name: alert?.store?.manager_store_name,
            },
            searchFeedAlertsValue
          )
        );

        setFeedAlerts(filteredAlerts);
      } else {
        setFeedAlerts(apiResponse.messages);
      }
    }
  }, [searchFeedAlertsValue, apiResponse]);

  return (
    <Catcher>
      <MuiWrapper maxWidth="sm" isMobile={isMobile} className={classes.root}>
        <div className={clsx(isMobile && classes.container)}>
          <section className={classes.filters}>
            <ToggleButtonGroup
              value={filter}
              exclusive
              size="small"
              onChange={handleFilterChange}
              aria-label="filters"
              className={classes.filtersGroup}
            >
              <ToggleButton
                className={classes.filtersButton}
                value="unres"
                aria-label="unresolved"
              >
                Unresolved ({total?.unres})
              </ToggleButton>
              <ToggleButton
                className={classes.filtersButton}
                value="good"
                aria-label="awards"
              >
                Awards ({total?.good})
              </ToggleButton>
              <ToggleButton
                className={classes.filtersButton}
                value="all"
                aria-label="all"
              >
                All
              </ToggleButton>
            </ToggleButtonGroup>
            <MuiSearch
              className={classes.search}
              placeholder="Search alert"
              value={searchFeedAlertsValue}
              onChange={(alert) => dispatch(searchByFeedAlerts(alert))}
            />
          </section>
          {isError && <Alert severity="error">{error.message}</Alert>}

          <List className={classes.alerts}>
            {feedAlerts && feedAlerts?.length === 0 && (
              <MuiEmptySearch text="No alerts found" />
            )}
            {isLoading && (
              <div className={classes.loader}>
                <MuiLoader fullpage />
              </div>
            )}
            {!isLoading &&
              feedAlerts?.map((message) => (
                <div key={message.id}>
                  {message.type === "alert" && (
                    <ChatAlert message={message} isFeed />
                  )}
                </div>
              ))}
          </List>
        </div>
      </MuiWrapper>
    </Catcher>
  );
};

export default Feed;
