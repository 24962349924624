// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";
import {
  getCompanyUsers,
  searchByCompanyUsers,
  setActiveUserDetailsTab,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import {
  MuiBackButton,
  MuiLoader,
  MuiSearch,
  MuiEmptySearch,
} from "components/common";
import {
  Paper,
  CreateCard,
  AdminTitle,
  UserCard,
  UserCardCircle,
  UserCardTitle,
  UserCardEmail,
  UserPhoneNumber,
  UserCardButton,
} from "components/settings";
import { Link, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { ReactComponent as CreateUserIcon } from "theme/icons/create-user.svg";

// Tools
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./users.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminUsers = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useBreakpoints();
  const searchCompanyUsersValue = useSelector(
    (state) => state.admin.searchCompanyUsersValue
  );
  const [companyUsers, setCompanyUsers] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["admin_users"], () => dispatch(getCompanyUsers()), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (apiResponse) {
      if (searchCompanyUsersValue.length > 0) {
        const filteredUsers = apiResponse.filter(
          ({ first_name, last_name, email, phone_number }) =>
            searchBy(
              { first_name, last_name, email, phone_number },
              searchCompanyUsersValue
            )
        );

        setCompanyUsers(filteredUsers);
      } else {
        setCompanyUsers(apiResponse);
      }
    }
  }, [searchCompanyUsersValue, apiResponse]);

  useEffect(() => {
    dispatch(setActiveUserDetailsTab(0));
  }, [dispatch]);

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(true));

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/admin/users"
            onClick={handleBackButtonClick}
          />
          <AdminTitle title="Users" />
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          <div className={classes.titleRow}>
            {isDesktop && <AdminTitle title="Users" />}

            {companyUsers && (
              <MuiSearch
                className={classes.search}
                placeholder="Search user"
                value={searchCompanyUsersValue}
                onChange={(user) => dispatch(searchByCompanyUsers(user))}
              />
            )}
          </div>

          {isLoading && <MuiLoader fullpage />}
          {isError && <Alert severity="error">{error.message}</Alert>}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <CreateCard
                icon={<CreateUserIcon />}
                title="Create new user"
                linkTo="/settings/admin/users/create-user"
              />
            </Grid>

            {companyUsers &&
              companyUsers.map((user) => (
                <Grid key={user.id} item xs={12} sm={4} md={3}>
                  <UserCard>
                    <div className={classes.cardRow}>
                      <UserCardCircle user={user} />
                      <div className={classes.cardContent}>
                        <UserCardTitle
                          title={`${user.first_name} ${user.last_name}`}
                        />
                        {user.email && <UserCardEmail email={user.email} />}
                        {user.phone_number && (
                          <UserPhoneNumber phoneNumber={user.phone_number} />
                        )}
                      </div>
                    </div>
                    <Link
                      className={classes.cardLink}
                      component={RouterLink}
                      to={`/settings/admin/users/${user.id}`}
                      color="inherit"
                      underline="none"
                    >
                      <UserCardButton>Details</UserCardButton>
                    </Link>
                  </UserCard>
                </Grid>
              ))}
          </Grid>

          {companyUsers?.length === 0 && (
            <MuiEmptySearch text="No users found" />
          )}
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminUsers;
