// Core
import { makeStyles } from "@material-ui/core/styles";

const useTabPanelStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    minHeight: "120px",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.main,
  },
  appBar: {
    position: "fixed",
    zIndex: 99,
    left: 15,
    right: 15,
    top: 127,
    width: "auto",
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.background.main,
    boxShadow: theme.shadows[0],
    "& .MuiTabs-indicator": {
      height: 2,
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      alignItems: "baseline",
    },
  },
  tab: {
    position: "relative",
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    color: theme.palette.text.main,
    "& svg": {
      fill: theme.palette.text.main,
    },
    "&.Mui-selected": {
      "& svg": {
        fill: theme.palette.primary.main,
      },
      color: theme.palette.primary.main,
    },
  },
}));

export { useStyles, useTabPanelStyles };
