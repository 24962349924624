// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: "auto",
    backgroundColor: theme.palette.background.main,
  },
  fullHeight: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  },
}));

export { useStyles };
