// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { setSortBy, setCurrentPage } from "redux/actions/filters-actions";

// Components
import { FormControl, Select, MenuItem, Typography } from "@material-ui/core";

// Icons
import { ExpandMore } from "@material-ui/icons";

// Styles
import { useStyles } from "./orders-sortby.styles";

const OrdersSelect = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sortBy } = useSelector((state) => state.filters.orders.period);

  const handleChange = (event) => {
    dispatch(setCurrentPage(1));
    dispatch(setSortBy(event.target.value));
  };

  return (
    <FormControl className={classes.formControl}>
      <Typography
        variant="caption"
        color="textPrimary"
        className={classes.title}
      >
        Sort by:
      </Typography>
      <Select
        value={sortBy}
        defaultValue={sortBy}
        onChange={handleChange}
        className={classes.select}
        IconComponent={ExpandMore}
      >
        <MenuItem value="newest">Newest</MenuItem>
        <MenuItem value="oldest">Oldest</MenuItem>
        <MenuItem value="longest">Longest</MenuItem>
        <MenuItem value="shortest">Shortest</MenuItem>
      </Select>
    </FormControl>
  );
};

export default OrdersSelect;
