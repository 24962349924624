// Service
import { ApiError } from "api";
import { FeedService } from "services";

// Types
import * as types from "redux/actiontypes/feed-types";

const getFeed = (feed) => async () => {
  try {
    const { data } = await FeedService.getFeed(feed);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const searchByFeedAlerts = (alert) => ({
  type: types.SEARCH_BY_FEED_ALERTS,
  payload: alert,
});

export { getFeed, searchByFeedAlerts };
