// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: "16px 16px 12px 16px",
    borderRadius: 8,
    background: theme.palette.common.white,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
  },
  info: {
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  title: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.text.main,
    letterSpacing: "0.15px",
  },
  tooltip: {
    flex: "0 0 12px",
    marginLeft: 6,
  },
  rankTitle: {
    marginRight: 6,
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  metric: {
    marginBottom: theme.spacing(1),
  },
  metricTitle: {
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.text.main,
    letterSpacing: "0.25px",
  },
}));

export { useStyles };
