// Core
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

// Actions
import { changePassword, logout } from "redux/actions/auth-actions";
import { showSettingsSidebar } from "redux/actions/settings-actions";

// Components
import { Typography } from "@material-ui/core";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiLoader,
  MuiBackButton,
} from "components/common";
import { Paper, UserTitle } from "components/settings";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Styles
import { useStyles } from "./change-password.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const ChangePassword = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { isMobile, isDesktop } = useBreakpoints();
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = {
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password should be 8 characters or more")
      .required("Password is required"),
    newPassword: Yup.string()
      .min(8, "Password should be 8 characters or more")
      .required("Password is required"),
    confirmNewPassword: Yup.string()
      .min(8, "Password should be 8 characters or more")
      .required("Confirm password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords don't match"),
  });

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (passwords) => dispatch(changePassword(passwords)),
    {
      onSuccess: (data, { resetForm }) => {
        resetForm();
      },
    }
  );

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(true));

  const onSubmit = (values, { resetForm }) => {
    const { password, newPassword } = values;
    mutate({ password, newPassword, resetForm });
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        dispatch(logout());
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, dispatch, history]);

  return (
    <>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/security"
            onClick={handleBackButtonClick}
          />
          <UserTitle title="Security" />
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && <UserTitle title="Security" />}
          <Typography className={classes.subtitle} variant="body1">
            Change password
          </Typography>

          <MuiForm
            noContainer={isDesktop}
            centered={isMobile}
            className={classes.formWrapper}
          >
            {isError && <Alert severity="error">{error.message}</Alert>}
            {isSuccess && (
              <Alert severity="success">Password successfully changed</Alert>
            )}

            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form className={classes.form} noValidate autoComplete="off">
                  <MuiInput
                    type="password"
                    name="password"
                    label="Current password"
                    required
                  />
                  <MuiInput
                    type="password"
                    name="newPassword"
                    label="New password"
                    required
                  />
                  <MuiInput
                    type="password"
                    name="confirmNewPassword"
                    label="Confirm new password"
                    required
                  />

                  <MuiButton disabled={isLoading} className={classes.button}>
                    {isLoading ? <MuiLoader /> : "Change password"}
                  </MuiButton>
                </Form>
              )}
            </Formik>
          </MuiForm>
        </Paper>
      </div>
    </>
  );
};

export default ChangePassword;
