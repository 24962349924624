// Service
import { ApiError } from "api";
import { AdminService } from "services";

// Types
import * as types from "redux/actiontypes/admin-types";

const getCompanyUsers = () => async () => {
  try {
    const { data } = await AdminService.getCompanyUsers();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserDetails = (userId) => async () => {
  try {
    const { data } = await AdminService.getUserDetails(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateUserDetails = (userId, user) => async () => {
  try {
    const { data } = await AdminService.updateUserDetails(userId, user);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserChannels = (userId) => async () => {
  try {
    const { data } = await AdminService.getUserChannels(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getChannels = (userId) => async () => {
  try {
    const { data } = await AdminService.getChannels(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserNotifications = (userId) => async () => {
  try {
    const { data } = await AdminService.getUserNotifications(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateUserNotifications = (userId, notifications) => async () => {
  try {
    const { data } = await AdminService.updateUserNotifications(
      userId,
      notifications
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const addUserToChannel = (userId, channelId) => async () => {
  try {
    const { data } = await AdminService.addUserToChannel(userId, channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const removeUserFromChannel = (userId, channelId) => async () => {
  try {
    const { data } = await AdminService.removeUserFromChannel(
      userId,
      channelId
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const createUser = (user) => async () => {
  try {
    const { data } = await AdminService.createUser(user);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const disableUser = (userId) => async () => {
  try {
    const { data } = await AdminService.disableUser(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const sendAuthSMS = (userId) => async () => {
  try {
    const { data } = await AdminService.sendAuthSMS(userId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getCompanyChannels = () => async () => {
  try {
    const { data } = await AdminService.getCompanyChannels();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getChannelDetails = (channelId) => async () => {
  try {
    const { data } = await AdminService.getChannelDetails(channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateChannelDetails = (channelId, channel) => async () => {
  try {
    const { data } = await AdminService.updateChannelDetails(
      channelId,
      channel
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getCompanyActiveAlerts = () => async () => {
  try {
    const { data } = await AdminService.getCompanyActiveAlerts();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getCompanyNewAlerts = () => async () => {
  try {
    const { data } = await AdminService.getCompanyNewAlerts();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const addNewAlerts = (alerts) => async () => {
  try {
    const { data } = await AdminService.addNewAlerts(alerts);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getAlertDetails = (alertId) => async () => {
  try {
    const { data } = await AdminService.getAlertDetails(alertId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateAlertDetails = (alertId, alert) => async () => {
  try {
    const { data } = await AdminService.updateAlertDetails(alertId, alert);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const deleteAlert = (alertId) => async () => {
  try {
    const { data } = await AdminService.deleteAlert(alertId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getChannelAlerts = (channelId) => async () => {
  try {
    const { data } = await AdminService.getChannelAlerts(channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateChannelAlerts = (channelId, alerts) => async () => {
  try {
    const { data } = await AdminService.updateChannelAlerts(channelId, alerts);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const searchByCompanyUsers = (user) => ({
  type: types.SEARCH_BY_COMPANY_USERS,
  payload: user,
});

const searchByUserChannels = (channel) => ({
  type: types.SEARCH_BY_USER_CHANNELS,
  payload: channel,
});

const searchByChannels = (channel) => ({
  type: types.SEARCH_BY_CHANNELS,
  payload: channel,
});

const searchByAlerts = (alert) => ({
  type: types.SEARCH_BY_ALERTS,
  payload: alert,
});

const setActiveUserDetailsTab = (activeTab) => ({
  type: types.SET_ACTIVE_USER_DETAILS_TAB,
  payload: activeTab,
});

const setActiveChannelDetailsTab = (activeTab) => ({
  type: types.SET_ACTIVE_CHANNEL_DETAILS_TAB,
  payload: activeTab,
});

const searchUserInChannel = (user) => ({
  type: types.SEARCH_USER_IN_CHANNEL,
  payload: user,
});

const addAdminAlert = (alert) => ({
  type: types.ADD_ADMIN_ALERT,
  payload: alert,
});

const addAllAdminAlerts = (alerts) => ({
  type: types.ADD_ALL_ADMIN_ALERTS,
  payload: alerts,
});

const removeAdminAlert = (alert) => ({
  type: types.REMOVE_ADMIN_ALERT,
  payload: alert,
});

export {
  getCompanyUsers,
  getUserDetails,
  updateUserDetails,
  getUserChannels,
  getChannels,
  getUserNotifications,
  updateUserNotifications,
  addUserToChannel,
  removeUserFromChannel,
  createUser,
  disableUser,
  sendAuthSMS,
  getCompanyChannels,
  getChannelDetails,
  updateChannelDetails,
  getCompanyActiveAlerts,
  getCompanyNewAlerts,
  searchByCompanyUsers,
  searchByUserChannels,
  searchByChannels,
  setActiveUserDetailsTab,
  setActiveChannelDetailsTab,
  searchUserInChannel,
  searchByAlerts,
  addAdminAlert,
  removeAdminAlert,
  addNewAlerts,
  addAllAdminAlerts,
  getAlertDetails,
  updateAlertDetails,
  deleteAlert,
  getChannelAlerts,
  updateChannelAlerts,
};
