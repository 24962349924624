// Core
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

// Components
import { List, Typography } from "@material-ui/core";
import { ChatMessage, ChatAlert, ChatCommunicationAlert } from "components";

// Styles
import { useStyles } from "./chat-messages.styles";

const ChatMessages = React.memo(({ messages, socket, page }) => {
  const classes = useStyles();
  const userId = useSelector((state) => state.auth.user.id);
  const listEl = useRef(null);
  const endEl = useRef(null);

  useEffect(() => {
    if (messages[messages.length - 1]?.user?.id === userId) {
      endEl?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [messages, userId]);

  useEffect(() => {
    endEl?.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    if (page > 0) {
      setTimeout(() => {
        listEl?.current?.scrollIntoView();
      }, 0);
    }
  }, [page]);

  if (messages.length === 0) {
    return (
      <Typography
        variant="caption"
        color="textPrimary"
        className={classes.empty}
      ></Typography>
    );
  }

  return (
    <List className={classes.root} ref={listEl}>
      {messages?.map((message) => (
        <div key={message.id}>
          {message.type === "message" && (
            <ChatMessage message={message} socket={socket} />
          )}
          {message.type === "alert" && (
            <ChatAlert message={message} socket={socket} />
          )}
          {message.type === "communication" && (
            <ChatCommunicationAlert message={message} socket={socket} />
          )}
        </div>
      ))}
      <div ref={endEl}></div>
    </List>
  );
});

export default ChatMessages;
