// Core
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { useMediaQuery } from "@material-ui/core";
import { MuiHeader } from "components/common";
import { MuiHeaderMobile } from "components";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  fullHeight: {
    height: "100%",
  },
}));

const StaticSidebarLayout = ({ children, title, icon, fullHeight }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className={clsx(classes.root, fullHeight ? classes.fullHeight : "")}>
      {isMobile ? <MuiHeaderMobile title={title} icon={icon} /> : <MuiHeader />}
      {children}
    </div>
  );
};

StaticSidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StaticSidebarLayout;
