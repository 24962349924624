// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notifications: {
    marginBottom: theme.spacing(1.5),
  },
  item: {
    marginBottom: theme.spacing(1.5),
  },
  title: {
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.main,
  },
  subtitle: {
    marginBottom: theme.spacing(1.5),
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  timeSubtitle: {
    marginBottom: theme.spacing(1),
  },
}));

export { useStyles };
