// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  nav: {
    display: "flex",
    flexGrow: 1,
    marginLeft: theme.spacing(6),
  },
  list: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
  },
  item: {
    position: "relative",
    width: "auto",
    cursor: "pointer",
    "&:after": {
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      opacity: 0,
      width: "100%",
      height: "4px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  itemText: {
    fontSize: theme.typography.pxToRem(14),
    textTransform: "capitalize",
    color: theme.palette.text.main,
    opacity: 0.7,
    "& .MuiTypography-root": {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.pxToRem(14),
    },
  },
  active: {
    "&:after": {
      opacity: 1,
    },
    "& .MuiListItemText-root": {
      color: theme.palette.primary.main,
      opacity: 1,
    },
  },
  disabled: {
    userSelect: "none",
    cursor: "auto",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export { useStyles };
