// Core
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

// Components
import { ChatLike } from "components";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Link,
  Box,
} from "@material-ui/core";

// Icons
import { ReactComponent as PositiveAlertIcon } from "theme/icons/hello.svg";
import { ReactComponent as AlertGuestServiceIcon } from "theme/icons/alert-communication.svg";
import { ReactComponent as ManagerIcon } from "theme/icons/user.svg";

// Styles
import { useStyles } from "./chat-communication-alert.styles";

// Tools
import { getAmpmFromTimestamp } from "utils/helpers";

const ChatCommunicationAlert = ({ message, socket }) => {
  const classes = useStyles();
  const storeName = message?.communication?.channel?.store?.store_name;

  return (
    <ListItem className={classes.wrapper} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <PositiveAlertIcon className={classes.avatarIcon} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className={classes.primary}>
            <div className={clsx(classes.row, classes.titleWrapper)}>
              <Typography variant="h5" className={classes.title}>
                Outloud {storeName && `to #${storeName}`}
              </Typography>
              <span className={classes.time}>
                {getAmpmFromTimestamp(message?.timestamp)}
              </span>
            </div>

            <div className={classes.alert}>
              <div className={classes.line}></div>
              <section className={clsx(classes.row, classes.alertTitleWrapper)}>
                <div className={classes.alertIconWrapper}>
                  <AlertGuestServiceIcon className="alert-good-icon" />
                </div>
                <div>
                  <Typography variant="h5" className={classes.alertTitle}>
                    {message?.communication?.subject}
                  </Typography>
                  {message?.communication?.manager_name && (
                    <Box className={classes.manager}>
                      <ManagerIcon className={classes.managerIcon} />
                      <Typography
                        variant="body1"
                        className={classes.managerName}
                      >
                        {message?.communication?.manager_name}
                      </Typography>
                    </Box>
                  )}
                </div>
              </section>

              <Typography variant="body1" className={classes.alertText}>
                {message?.communication?.text}
              </Typography>

              <Link
                className={classes.alertLink}
                component={RouterLink}
                to={`/communication/${message.communication?.id}`}
                color="inherit"
                underline="none"
              >
                Alert details
              </Link>
            </div>
          </div>
        }
        secondary={
          <span className={classes.row}>
            <ChatLike message={message} socket={socket} />
          </span>
        }
      />
    </ListItem>
  );
};

export default ChatCommunicationAlert;
