// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    flexGrow: 1,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  root: {
    padding: 0,
  },
  search: {
    width: "100%",
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
  },
  mealIcon: {
    width: 17,
    height: 18,
    marginRight: theme.spacing(1.5),
    fill: theme.palette.text.main,
  },
  fullHeight: {
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
  },
}));

export { useStyles };
