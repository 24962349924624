// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "0 1 50%",
  },
  datePicker: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  calendarIcon: {
    width: 15,
    height: 15,
  },
}));

export { useStyles };
