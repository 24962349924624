// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    paddingTop: 0,
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  loader: {
    position: "relative",
    height: "100px",
  },
  title: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.grey,
  },
  filters: {
    position: "fixed",
    zIndex: 99,
    width: "552px",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#fafafa",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      left: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  filtersGroup: {
    width: "100%",
    height: 45,
    marginBottom: theme.spacing(2),
    "& .MuiToggleButton-root": {
      fontFamily: theme.typography.medium.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      backgroundColor: theme.palette.grey.light,
      borderColor: theme.palette.grey.border,
      color: theme.palette.text.grey,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        fontFamily: theme.typography.regular.fontFamily,
        fontSize: theme.typography.pxToRem(14),
      },
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.background.blue,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      margin: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)": {
      borderRight: "1px solid transparent",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  filtersButton: {
    width: "100%",
    padding: 12,
    transition: "all 0.2s ease-in-out",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
    "&.MuiToggleButton-root": {
      borderRadius: 8,
      "&.Mui-selected": {
        borderRightColor: theme.palette.primary.main,
      },
    },
  },
  search: {
    width: "100%",
  },
  alerts: {
    marginTop: 0,
    paddingTop: "160px",
    overflowY: "scroll",
    height: "calc(100vh - 160px)",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
}));

export { useStyles };
