// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: 0,
  },
  root: {
    width: 34,
    height: 20,
    padding: 0,
    marginRight: theme.spacing(1),
  },
  switchBase: {
    padding: "2px 2px 2px 3px",
    color: "#BCC1CD",
    "& + $track": {
      backgroundColor: theme.palette.grey.main,
      borderColor: theme.palette.grey.secondary,
    },
    "&$checked": {
      transform: "translateX(13px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: theme.palette.green.secondary,
        opacity: 1,
        border: `1px solid ${theme.palette.green.secondary}`,
      },
    },
    "&$focusVisible $thumb": {
      color: theme.palette.green.secondary,
      border: `6px solid ${theme.palette.grey.secondary}`,
    },

    "&.Mui-disabled": {
      color: "#BCC1CD",
      "& + $track": {
        backgroundColor: theme.palette.grey.main,
        borderColor: theme.palette.grey.secondary,
        opacity: 1,
      },
      "&$checked": {
        transform: "translateX(13px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: theme.palette.green.secondary,
          opacity: 1,
          border: `1px solid ${theme.palette.green.secondary}`,
        },
      },
      "&$focusVisible $thumb": {
        color: theme.palette.green.secondary,
        border: `6px solid ${theme.palette.grey.secondary}`,
      },
    },
  },
  thumb: {
    width: 15,
    height: 15,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey.secondary}`,
    backgroundColor: theme.palette.grey.main,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
}));

export { useStyles };
