// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { setCurrentPage } from "redux/actions/filters-actions";

// Components
import { Typography, Drawer, Button } from "@material-ui/core";
import { MuiFilterTags, MuiFilterStores } from "components/common";

// Icons
import { Tune } from "@material-ui/icons";

// Styles
import { useStyles } from "./orders-stores.styles";

const OrdersMobileStores = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const handleSubmit = () => {
    dispatch(setCurrentPage(1));
    setIsOpen(false);
  };

  const handleCancel = () => setIsOpen(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  return (
    <>
      <Button
        className={clsx(classes.button, classes.filtersButton)}
        variant="outlined"
        color="primary"
        size="medium"
        startIcon={<Tune />}
        onClick={toggleDrawer(true)}
      >
        Filters
      </Button>

      <Drawer
        className={classes.drawer}
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.drawerTitle}
        >
          Filters
        </Typography>

        <div className={classes.filtersRow}>
          <MuiFilterStores />
          <MuiFilterTags />
        </div>

        <div className={classes.buttonGroup}>
          <Button
            className={clsx(classes.button, classes.submitButton)}
            variant="contained"
            color="inherit"
            size="medium"
            onClick={handleSubmit}
          >
            Apply
          </Button>
          <Button
            className={clsx(classes.button, classes.cancelButton)}
            variant="outlined"
            color="inherit"
            size="medium"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default OrdersMobileStores;
