// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Components
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { MuiTabPanel } from "components/common";

// Styles
import { useStyles } from "./tabs.styles";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const MuiTabs = ({
  children,
  labels,
  disabled = false,
  activeTab = 0,
  onChange,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabsValue, setTabsValue] = useState(activeTab);

  const handleTabsChange = (event, newValue) => {
    setTabsValue(newValue);
    if (onChange) {
      dispatch(onChange(newValue));
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={tabsValue}
          onChange={handleTabsChange}
          aria-label="tabs"
          variant="scrollable"
        >
          {labels.map((label, index) => (
            <Tab
              key={`${index}-${label}`}
              className={classes.label}
              label={label}
              disableRipple
              disableFocusRipple
              disabled={disabled}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {React.Children.map(children, (child, index) => (
        <MuiTabPanel value={tabsValue} index={index}>
          {child}
        </MuiTabPanel>
      ))}
    </div>
  );
};

MuiTabs.propTypes = {
  children: PropTypes.node,
  labels: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default MuiTabs;
