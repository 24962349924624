// Core
import React from "react";
import { Formik, Form } from "formik";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import * as Yup from "yup";

// Actions
import {
  loginWithEmailOrPhone,
  setAuth,
  setUser,
} from "redux/actions/auth-actions";

// Components
import { Avatar, Typography, Grid, Link } from "@material-ui/core";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiCopyright,
  MuiLoader,
} from "components/common";
import { Alert } from "@material-ui/lab";

// Icons
import { LockOutlined } from "@material-ui/icons";

// Styles
import { useStyles } from "./login-with-email.styles";

const LoginWithEmailForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const initialValues = {
    emailOrPhone: "",
    password: "",
  };
  const validationSchema = Yup.object({
    emailOrPhone: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password should be 8 characters or more")
      .required("Password is required"),
  });

  const { error, isLoading, isError, mutate } = useMutation(
    ({ emailOrPhone, password }) =>
      dispatch(loginWithEmailOrPhone({ emailOrPhone, password })),
    {
      onSuccess: (data) => {
        dispatch(setAuth(true));
        dispatch(setUser(data.user));
        history.push("/feed/unres");
      },
    }
  );

  const onSubmit = (values) => {
    const { emailOrPhone, password } = values;
    const normalizedEmail = emailOrPhone.trim().toLowerCase();
    mutate({ emailOrPhone: normalizedEmail, password });
  };

  return (
    <MuiForm centered>
      <Avatar className={classes.avatar}>
        <LockOutlined className={classes.icon} />
      </Avatar>
      <Typography component="h1" variant="h5">
        Sign In
      </Typography>

      {isError && <Alert severity="error">{error.message}</Alert>}

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className={classes.form} noValidate autoComplete="off">
            <MuiInput
              type="email"
              name="emailOrPhone"
              label="Email Address"
              required
            />
            <MuiInput
              type="password"
              name="password"
              label="Password"
              required
            />
            <Grid container item xs justifyContent="flex-end">
              <Link component={RouterLink} to="/forgot-password">
                Forgot password?
              </Link>
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.divider}
              >
                |
              </Typography>
              <Link component={RouterLink} to="/login">
                Sign in with phone
              </Link>
            </Grid>
            <MuiButton disabled={isLoading}>
              {isLoading ? <MuiLoader /> : "Sign In"}
            </MuiButton>
          </Form>
        )}
      </Formik>
      <MuiCopyright />
    </MuiForm>
  );
};

export default LoginWithEmailForm;
