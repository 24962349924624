// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";

// Actions
import { getUserDetails } from "redux/actions/settings-actions";
import { setUser } from "redux/actions/auth-actions";

// Components
import { Catcher } from "components";
import { MuiWrapper, MuiBarSpacer } from "components/common";
import { Sidebar } from "components/settings";
import { getRoutes } from "./routes";

// Hooks
import { useBreakpoints } from "hooks";

// Styles
import { useStyles } from "./settings.styles";

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();
  const isAdmin = useSelector((state) => state.auth.user?.is_admin);

  useQuery(["user_info"], () => dispatch(getUserDetails()), {
    onSuccess: (data) => {
      dispatch(setUser(data));
    },
    refetchOnWindowFocus: false,
  });

  return (
    <Catcher>
      <Sidebar />

      <div className={classes.content}>
        <MuiBarSpacer />
        <MuiWrapper
          maxWidth="xl"
          isMobile={isMobile}
          className={classes.fullHeight}
        >
          {getRoutes(isAdmin)}
        </MuiWrapper>
      </div>
    </Catcher>
  );
};

export default Settings;
