// Core
import React from "react";
import clsx from "clsx";

// Components
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  ButtonGroup,
  Button,
} from "@material-ui/core";

// Hooks
import { useBreakpoints } from "hooks";

// Tools
import { convertTimeTo12Hours, getAmPmFromHours } from "utils/helpers";

// Icons
import { ExpandMore } from "@material-ui/icons";

// Styles
import { useStyles } from "./time-notifications.styles";

const TIME = [24, ...Array.from({ length: 23 }, (_, i) => i + 1)];
const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

function calculateWeekDays(previousDays, day) {
  const index = WEEK_DAYS.indexOf(day);
  if (previousDays.includes(index)) {
    return previousDays.filter((day) => day !== index);
  }
  return [...previousDays, index].sort((a, b) => a - b);
}

const TimeNotifications = ({
  query,
  notifications: { alert_types, is_sms, time_from, time_to, week_days },
}) => {
  const classes = useStyles();
  const { isMobile } = useBreakpoints();

  const handleChange = (event) => {
    query.mutate({
      alert_types,
      is_sms,
      time_from:
        event.target.name === "time_from" ? event.target.value : time_from,
      time_to: event.target.name === "time_to" ? event.target.value : time_to,
      week_days,
    });
  };

  const handleDaysClick = (day) => {
    query.mutate({
      alert_types,
      is_sms,
      time_from,
      time_to,
      week_days: calculateWeekDays(week_days, day),
    });
  };

  return (
    <>
      <div className={classes.timeWrapper}>
        <FormControl className={clsx(classes.formControl, classes.timeFrom)}>
          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.formTitle}
          >
            From:
          </Typography>
          <Select
            name="time_from"
            value={time_from}
            onChange={handleChange}
            className={classes.select}
            IconComponent={ExpandMore}
          >
            {TIME.map((item) => (
              <MenuItem key={item} value={item}>
                {`${convertTimeTo12Hours(item)}:00 ${getAmPmFromHours(item)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Typography
            variant="caption"
            color="textPrimary"
            className={classes.formTitle}
          >
            To:
          </Typography>
          <Select
            name="time_to"
            value={time_to}
            onChange={handleChange}
            className={classes.select}
            IconComponent={ExpandMore}
          >
            {TIME.map((item) => (
              <MenuItem key={item} value={item}>
                {`${convertTimeTo12Hours(item)}:00 ${getAmPmFromHours(item)}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className={classes.days}>
        <Typography
          variant="caption"
          color="textPrimary"
          className={classes.formTitle}
        >
          Days:
        </Typography>
        <ButtonGroup
          fullWidth={isMobile}
          disableElevation
          variant="outlined"
          color="primary"
          size="small"
        >
          {WEEK_DAYS.map((day, index) => (
            <Button
              fullWidth
              key={day}
              onClick={() => handleDaysClick(day)}
              className={clsx(
                classes.button,
                week_days.includes(index) ? classes.activeButton : ""
              )}
            >
              {day}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </>
  );
};

export default TimeNotifications;
