// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumbItemRoot: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  breadcrumbItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  title: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.main,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    position: "relative",
    height: 35,
    minWidth: 150,
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    textTransform: "none",
  },
  buttonRemove: {
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    backgroundColor: "#FDF0F0",

    "&:hover": {
      backgroundColor: "#FDF0F0",
    },
  },
  buttonRemoveIcon: {
    color: theme.palette.red.main,
  },
  buttonPrimary: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: "#E4ECFC",
    border: "1px solid #4B7FEE",
  },
  buttonSuccess: {
    "&.Mui-disabled": {
      color: theme.palette.green.alert,
      borderColor: theme.palette.green.alert,
    },
  },
  buttonSuccessIcon: {
    color: theme.palette.green.alert,
  },
  dialogActions: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
}));

export { useStyles };
