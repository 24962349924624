// Core
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Components
import { MuiTableWrapper } from "components/common";

// Tools
import { searchBy } from "utils/helpers";

const MuiTableContainer = ({
  tableColumns,
  tableData,
  initialState,
  searchValue = "",
}) => {
  const [stores, setStores] = useState(null);

  useEffect(() => {
    if (tableData) {
      const filteredStores = tableData?.filter(
        ({
          store_name,
          city,
          state,
          manager_district_name,
          address_line,
          manager_store_name,
          isTotal,
        }) => {
          if (isTotal) return true;
          return searchBy(
            {
              store_name,
              city,
              state,
              manager_district_name,
              manager_store_name,
              address_line,
            },
            searchValue
          );
        }
      );
      return searchValue.length > 0
        ? setStores(filteredStores)
        : setStores(tableData);
    }
  }, [searchValue, tableData]);

  return (
    <>
      {stores && (
        <MuiTableWrapper
          tableData={stores}
          tableColumns={tableColumns}
          initialState={initialState}
        />
      )}
    </>
  );
};

MuiTableContainer.propTypes = {
  tableColumns: PropTypes.array.isRequired,
  initialState: PropTypes.object,
  searchValue: PropTypes.string,
};

export default MuiTableContainer;
