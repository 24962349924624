// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    minHeight: 160,
    border: `1px dashed ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.main,
    transition: theme.transitions.create(["background-color"]),
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },

    "&:hover": {
      backgroundColor: theme.palette.blue.light,
      cursor: "pointer",
    },
  },
  icon: {
    marginBottom: theme.spacing(1),
    width: 32,
    height: 32,
    "& svg": {
      width: "100%",
      height: "100%",
    },
  },
  title: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    textAlign: "center",
  },
}));

export { useStyles };
