// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  empty: {
    display: "block",
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
  },
}));

export { useStyles };
