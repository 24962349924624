// Types
import * as types from "redux/actiontypes/filter-types";

// Tools
import { lagDay } from "utils/helpers";

const workingDay = lagDay("today");

const initialFilterState = {
  orders: {
    period: {
      value: "today",
      date_from: workingDay.date_from,
      date_to: workingDay.date_to,
      per_page: 10,
      cur_page: 1,
      tags: [],
      stores: [],
      sortBy: "newest",
    },
    searchByStore: "",
    pageCount: 1,
  },
};

const filtersReducer = (state = initialFilterState, action) => {
  switch (action.type) {
    case types.SEARCH_BY_STORE:
      return {
        ...state,
        orders: {
          ...state.orders,
          searchByStore: action.payload,
        },
      };
    case types.ADD_FILTER_TAG:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: {
            ...state.orders.period,
            tags: [...new Set([...state.orders.period.tags, action.payload])],
          },
        },
      };
    case types.REMOVE_FILTER_TAG:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: {
            ...state.orders.period,
            tags: [
              ...state.orders.period.tags.filter(
                (tag) => tag !== action.payload
              ),
            ],
          },
        },
      };
    case types.ADD_FILTER_STORE:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: {
            ...state.orders.period,
            stores: [
              ...new Set([...state.orders.period.stores, action.payload]),
            ],
          },
        },
      };
    case types.REMOVE_FILTER_STORE:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: {
            ...state.orders.period,
            stores: [
              ...state.orders.period.stores.filter(
                (store) => store !== action.payload
              ),
            ],
          },
        },
      };
    case types.FILTER_ORDERS_BY_PERIOD:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: {
            ...state.orders.period,
            value: action.payload.value,
            date_from: action.payload.date_from,
            date_to: action.payload.date_to,
          },
        },
      };
    case types.SET_CURRENT_PAGE:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: {
            ...state.orders.period,
            cur_page: parseInt(action.payload),
          },
        },
      };
    case types.SET_PAGE_COUNT:
      return {
        ...state,
        orders: {
          ...state.orders,
          pageCount: action.payload,
        },
      };
    case types.SET_SORT_BY:
      return {
        ...state,
        orders: {
          ...state.orders,
          period: { ...state.orders.period, sortBy: action.payload },
        },
      };
    default:
      return state;
  }
};

export { filtersReducer };
