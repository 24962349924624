// Core
import React from "react";
import clsx from "clsx";

// Components
import { ChatLike, ChatReply } from "components";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Chip,
  Button,
} from "@material-ui/core";

// Icons
import { ReactComponent as SystemIsDownIcon } from "theme/icons/system-is-down.svg";
import { ReactComponent as StatusIcon } from "theme/icons/check.svg";

// Styles
import { useStyles } from "./chat-alert-upsell.styles";

// Tools
import { getAmpmFromTimestamp, formatUpsell } from "utils/helpers";

const ChatAlertUpsell = ({
  message,
  alert,
  socket,
  onStatusChange,
  onDetailsClick,
  isFeed,
}) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.wrapper} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <SystemIsDownIcon className={classes.avatarIcon} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className={classes.primary}>
            <div className={clsx(classes.row, classes.titleWrapper)}>
              <Typography variant="h5" className={classes.title}>
                Alert #{message?.alert?.store?.store_name}
              </Typography>
              <span className={classes.time}>
                {getAmpmFromTimestamp(message.timestamp)}
              </span>
            </div>

            <div className={classes.alert}>
              <div className={classes.border}></div>
              <Typography variant="h5" className={classes.alertTitle}>
                {alert.description}
              </Typography>
              {alert?.metric >= 0 && (
                <Typography variant="body1" className={classes.alertMetric}>
                  Store has around
                  <span className={classes.metricValue}>
                    {formatUpsell(alert.metric)}
                  </span>{" "}
                  upsells per order
                </Typography>
              )}
              {alert.status === "resolved" && (
                <Typography variant="body1" className={classes.status}>
                  <StatusIcon className={classes.statusIcon} />
                  Reviewed with team
                </Typography>
              )}

              <div className={classes.scripts}>
                <Typography variant="h6" className={classes.scriptsTitle}>
                  Script to use more:
                </Typography>
                {alert.lines && (
                  <div className={classes.lines}>
                    {alert.lines.map((text, index) => (
                      <Chip
                        key={index}
                        className={classes.line}
                        variant="outlined"
                        size="small"
                        label={text}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className={classes.row}>
                <Button
                  variant="outlined"
                  className={clsx(classes.button, classes.detailsButton)}
                  onClick={() => onDetailsClick(alert.id)}
                >
                  View audio
                </Button>
                {alert.status === "resolved" ? (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.resolveButton)}
                  >
                    <StatusIcon className={classes.resolveIcon} />
                    Reviewed
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.statusButton)}
                    onClick={() => onStatusChange(alert.id)}
                  >
                    Review w team
                  </Button>
                )}
              </div>
            </div>
          </div>
        }
        secondary={
          <span className={classes.row}>
            {!isFeed && <ChatLike message={message} socket={socket} />}
            <ChatReply message={message} isFeed={isFeed} />
          </span>
        }
      />
    </ListItem>
  );
};

export default ChatAlertUpsell;
