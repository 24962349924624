// Core
import React, { useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { Formik, Form } from "formik";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import clsx from "clsx";

// Actions
import { createUser } from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiLoader,
  MuiBackButton,
} from "components/common";
import { Paper, UserTitle } from "components/settings";
import { Typography, Link, Breadcrumbs } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { NavigateNext } from "@material-ui/icons";

// Tools
import { validatePhoneNumber } from "utils/helpers";

// Styles
import { useStyles } from "./create-user.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminUserCreateUser = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, isDesktop } = useBreakpoints();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "First name should be at least 2 characters")
      .max(120, "First name should be less than 120 characters")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last name should be at least 2 characters")
      .max(120, "Last name should be less than 120 characters"),
    email: Yup.string().email("Invalid email format"),
    phoneNumber: Yup.string()
      .test("validatePhone", "Please fill all the empty fields", (value) => {
        return value ? validatePhoneNumber(value).isValid : true;
      })
      .required("Phone number is required"),
  });

  const { isLoading, isError, isSuccess, error, mutate } = useMutation((user) =>
    dispatch(createUser(user))
  );

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        history.push("/settings/admin/users");
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, history]);

  const onSubmit = (values) => {
    const { firstName, lastName, email, phoneNumber } = values;
    mutate({
      firstName,
      lastName,
      email,
      phoneNumber: validatePhoneNumber(phoneNumber).value,
    });
  };

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton to="/settings/admin/users" />
          <UserTitle title="Create new user" />
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && (
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumb}
              separator={<NavigateNext fontSize="small" />}
            >
              <Link
                className={classes.breadcrumbItemRoot}
                component={RouterLink}
                to="/settings/admin/users"
              >
                Users
              </Link>
              <Typography className={classes.breadcrumbItem}>
                Create user
              </Typography>
            </Breadcrumbs>
          )}

          {isDesktop && <UserTitle title="Create new user" />}

          <MuiForm
            noContainer={isDesktop}
            centered={isMobile}
            className={classes.formWrapper}
          >
            {isError && <Alert severity="error">{error.message}</Alert>}
            {isSuccess && <Alert severity="success">User is created</Alert>}

            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form className={classes.form} noValidate autoComplete="off">
                  <MuiInput
                    type="text"
                    name="firstName"
                    label="First name"
                    required
                  />
                  <MuiInput type="text" name="lastName" label="Last name" />
                  <MuiInput type="email" name="email" label="Email Address" />
                  <InputMask
                    mask="+1\ (999) 999 9999"
                    value={props.values.phoneNumber}
                    onChange={props.handleChange}
                  >
                    {() => (
                      <MuiInput
                        type="tel"
                        name="phoneNumber"
                        label="Phone number"
                        required
                      />
                    )}
                  </InputMask>
                  <MuiButton
                    className={classes.button}
                    disabled={isLoading || !props.dirty || isSuccess}
                  >
                    {isLoading ? <MuiLoader /> : "Send invite via SMS"}
                  </MuiButton>
                </Form>
              )}
            </Formik>
          </MuiForm>
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminUserCreateUser;
