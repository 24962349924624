// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    padding: 0,
  },
  form: {
    width: "100%",
  },
  subtitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
  },
  button: {
    maxWidth: 180,
    whiteSpace: "nowrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export { useStyles };
