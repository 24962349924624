// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chatForm: {
    position: "fixed",
    zIndex: 999,
    width: "calc(100% - 280px)",
    bottom: 0,
    left: "280px",
    backgroundColor: theme.palette.background.grey,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      bottom: 0,
      left: 0,
    },
  },
  reply: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderTop: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.background.grey,
  },
  replyDescription: {
    maxHeight: 50,
    lineHeight: 1.2,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    height: 50,
    overflowY: "hidden",
  },
  avatar: {
    width: 28,
    height: 28,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(10),
  },
  replyTitle: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    lineHeight: 1.2,
    color: theme.palette.primary.main,
  },
  replyTo: {
    fontFamily: theme.typography.bold.fontFamily,
  },
  replyText: {
    fontSize: theme.typography.pxToRem(13),
  },
  clearIcon: {
    flex: "0 0 20px",
    cursor: "pointer",
    fill: theme.palette.primary.main,
  },
  participantsMenu: {
    "& .MuiPaper-root": {
      width: "auto",
    },
  },
  input: {
    borderRadius: 0,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(2),
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: theme.spacing(2),
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px 0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: 0,
        borderTop: `1px solid ${theme.palette.grey.border}`,
        borderRadius: 0,
        borderColor: theme.palette.grey.border,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.grey.border,
        borderWidth: 1,
      },
    },
  },
  attach: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1.5),
  },
  attachIcon: {
    width: 20,
    height: 20,
    cursor: "pointer",
  },
  send: {
    width: 20,
    height: 20,
    marginLeft: theme.spacing(1.5),
  },
  sendIcon: {
    width: 20,
    height: 20,
    fill: theme.palette.primary.main,
    cursor: "pointer",
  },
  disabled: {
    fill: theme.palette.text.grey,
  },
}));

export { useStyles };
