// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import clsx from "clsx";

// Actions
import {
  getUserNotifications,
  updateUserNotifications,
  showSettingsSidebar,
} from "redux/actions/settings-actions";

// Components
import { Catcher } from "components";
import { MuiLoader, MuiSwitch, MuiBackButton } from "components/common";
import { Paper, TimeNotifications } from "components/settings";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Styles
import { useStyles } from "./user-notifications.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const UserInfo = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile } = useBreakpoints();
  const [notifications, setNotifications] = useState(null);

  const getNotificationsQuery = useQuery(
    ["user_notifications"],
    () => dispatch(getUserNotifications()),
    {
      onSuccess: (data) => {
        setNotifications(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateNotificationsQuery = useMutation(
    (notifications) => dispatch(updateUserNotifications(notifications)),
    {
      onSuccess: (data) => {
        setNotifications(data);
      },
    }
  );

  const handleNotificationChange = (event, alert) => {
    const newAlert = {
      ...alert,
      enabled: event.target.checked,
    };
    const updatedAlertTypes = notifications.alert_types.map((item) =>
      item.name === alert.name ? newAlert : item
    );
    updateNotificationsQuery.mutate({
      alert_types: updatedAlertTypes,
      is_sms: notifications.is_sms,
      time_from: notifications.time_from,
      time_to: notifications.time_to,
      week_days: notifications.week_days,
    });
  };

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(true));

  if (getNotificationsQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getNotificationsQuery.isError) {
    return (
      <Alert severity="error">{getNotificationsQuery.error.message}</Alert>
    );
  }

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/notifications"
            onClick={handleBackButtonClick}
          />
          <Typography
            className={classes.mobileTitle}
            component="h1"
            variant="h5"
          >
            Notifications
          </Typography>
        </div>
      )}

      {notifications?.alert_types && (
        <div className={clsx(isMobile && globalClasses.container)}>
          <Paper>
            <Typography className={classes.title} component="h1" variant="h5">
              Awards
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Select award notifications you want to receive
            </Typography>

            <Grid container spacing={1} className={classes.notifications}>
              {notifications.alert_types
                .filter(
                  (alert) =>
                    alert.name.startsWith("good") || alert.name.startsWith("info") || alert.name === "message"
                )
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((alert) => (
                  <Grid
                    className={classes.item}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    key={alert.name}
                  >
                    <MuiSwitch
                      alert={alert}
                      disabled={updateNotificationsQuery.isLoading}
                      onChange={handleNotificationChange}
                    />
                  </Grid>
                ))}
            </Grid>

            <Typography className={classes.title} component="h1" variant="h5">
              Operational
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Select operational notifications you want to receive
            </Typography>

            <Grid container spacing={1} className={classes.notifications}>
              {notifications.alert_types
                .filter((alert) => alert.name.startsWith("ops"))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((alert) => (
                  <Grid
                    className={classes.item}
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    key={alert.name}
                  >
                    <MuiSwitch
                      alert={alert}
                      disabled={updateNotificationsQuery.isLoading}
                      onChange={handleNotificationChange}
                    />
                  </Grid>
                ))}
            </Grid>

            <Typography
              className={clsx(classes.subtitle, classes.timeSubtitle)}
              variant="subtitle1"
            >
              Set a time when you want to receive notifications
            </Typography>
            <TimeNotifications
              query={updateNotificationsQuery}
              notifications={notifications}
            />
          </Paper>
        </div>
      )}
    </Catcher>
  );
};

export default UserInfo;
