// Core
import { $http } from "api";

class StoresService {
  static async getUserStores() {
    return $http.get("/stores");
  }

  static async getCompanyStats() {
    return $http.get("/stats/company");
  }

  static async getStoreStatsById(storeId) {
    return $http.get(`/stats/stores/${storeId}`);
  }

  static async getDailyStores() {
    return $http.get("/stats/stores");
  }

  static async getReports() {
    return $http.get("/stats/reports");
  }
}

export default StoresService;
