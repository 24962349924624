// Core
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import moment from "moment";

// Actions
import { setCurrentPage } from "redux/actions/filters-actions";
import { filterOrdersByPeriod } from "redux/actions/filters-actions";

// Components
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// Icons
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";

// Styles
import { useStyles } from "./orders-date.styles";

const OrdersMobileDate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dateFrom = useSelector(
    (state) =>
      state?.filters?.orders?.period?.date_from?.split(" ")[0] ||
      moment().format("YYYY-MM-DD")
  );
  const [selectedDate, setDate] = useState(moment());
  const [inputValue, setInputValue] = useState(dateFrom);

  const onDateChange = (date, value) => {
    if (value) {
      dispatch(
        filterOrdersByPeriod({
          value: "today",
          date_from: `${value} 00:00:00`,
          date_to: `${value} 23:59:59`,
        })
      );
      setDate(date);
      setInputValue(value);
      dispatch(setCurrentPage(1));
    }
  };

  return (
    <div className={classes.root}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <KeyboardDatePicker
          className={classes.datePicker}
          margin="dense"
          id="date-picker"
          label="Pick Date"
          inputVariant="outlined"
          format="YYYY-MM-DD"
          value={selectedDate}
          inputValue={inputValue}
          onChange={onDateChange}
          keyboardIcon={<CalendarIcon className={classes.calendarIcon} />}
          KeyboardButtonProps={{
            "aria-label": "Start date",
          }}
          autoOk
          showTodayButton
          disableToolbar
          disableFuture
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default OrdersMobileDate;
