// Core
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";

// Actions
import {
  getUserChannels,
  searchByUserChannels,
  removeUserFromChannel,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiLoader, MuiSearch, MuiEmptySearch } from "components/common";
import {
  ChannelCard,
  ChannelCardTitle,
  ChannelCardAddress,
  ChannelCardButton,
  CreateCard,
} from "components/settings";
import { Typography, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Icons
import { ReactComponent as AssignChannelIcon } from "theme/icons/new-channel.svg";

// Tools
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./channels.styles";

const AdminUserChannels = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const searchUserChannelsValue = useSelector(
    (state) => state.admin.searchUserChannelsValue
  );
  const [userChannels, setUserChannels] = useState(null);
  const [channelId, setChannelId] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(
    ["admin_user_channels", id],
    () => dispatch(getUserChannels(id)),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const removeUserFromChannelQuery = useMutation(
    (channelId) => dispatch(removeUserFromChannel(id, channelId)),
    {
      onSuccess: (data) => {
        setUserChannels(data);
        queryClient.invalidateQueries(["admin_user_channels", id]);
      },
    }
  );

  useEffect(() => {
    if (apiResponse) {
      if (searchUserChannelsValue.length > 0) {
        const filteredChannels = apiResponse.filter(
          ({ name, store }) =>
            searchBy(
              {
                name,
                address_line: store?.address_line,
                state: store?.state,
                city: store?.city,
                manager_district_name: store?.manager_district_name,
                store_name: store?.store_name
              },
              searchUserChannelsValue
            )
        );

        setUserChannels(filteredChannels);
      } else {
        setUserChannels(apiResponse);
      }
    }
  }, [searchUserChannelsValue, apiResponse]);

  const handleRemoveUser = (channel) => {
    setChannelId(channel.id);
    removeUserFromChannelQuery.mutate({ channelId: channel.id });
  };

  const getCount = (count) => {
    if (count === 0) return "No channels";
    return count === 1 ? `${count} channel` : `${count} channels`;
  };

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <div className={classes.wrapper}>
        <div className={classes.searchRow}>
          <Typography className={classes.channelsCount} variant="caption">
            {userChannels && getCount(userChannels.length)}
          </Typography>

          <MuiSearch
            className={classes.search}
            placeholder="Search channel"
            value={searchUserChannelsValue}
            onChange={(channel) => dispatch(searchByUserChannels(channel))}
          />
        </div>

        {removeUserFromChannelQuery.isError && (
          <Alert severity="error">
            {removeUserFromChannelQuery.error.message}
          </Alert>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <CreateCard
              icon={<AssignChannelIcon />}
              title="Assign user to new channel(s)"
              linkTo={`/settings/admin/users/${id}/channels`}
            />
          </Grid>

          {userChannels &&
            userChannels.map((channel) => (
              <Grid key={channel.id} item xs={12} sm={4} md={3}>
                <ChannelCard>
                  <div>
                    <ChannelCardTitle title={channel.name} />
                    <ChannelCardAddress
                      addressLine={channel.store?.address_line}
                      state={channel.store?.state}
                      city={channel.store?.city}
                      description={channel.description}
                    />
                  </div>
                  <ChannelCardButton
                    onClick={() => handleRemoveUser(channel)}
                    disabled={
                      channelId === channel.id &&
                      (removeUserFromChannelQuery.isLoading ||
                        removeUserFromChannelQuery.isSuccess)
                    }
                  >
                    {removeUserFromChannelQuery.isLoading &&
                    channelId === channel.id ? (
                      <MuiLoader fullpage size="20px" />
                    ) : (
                      "Remove user from channel"
                    )}
                  </ChannelCardButton>
                </ChannelCard>
              </Grid>
            ))}
        </Grid>

        {userChannels?.length === 0 && (
          <MuiEmptySearch text="No channels found" />
        )}
      </div>
    </Catcher>
  );
};

export default AdminUserChannels;
