// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";
import {
  setActiveUserDetailsTab,
  disableUser,
  getUserDetails,
  sendAuthSMS,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiBackButton, MuiTabs } from "components/common";
import {
  Paper,
  AdminUserInfo,
  AdminUserChannels,
  AdminUserNotifications,
} from "components/settings";
import {
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@material-ui/core";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { NavigateNext, Done } from "@material-ui/icons";

// Styles
import { useStyles } from "./user-details.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminUserDetails = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { isMobile, isDesktop } = useBreakpoints();
  const activeTab = useSelector((state) => state.admin.activeUserDetailsTab);
  const user = useSelector((state) => state.auth.user);
  const [userDetails, setUserDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  useQuery(["admin_user_info", id], () => dispatch(getUserDetails(id)), {
    onSuccess: (data) => {
      setUserDetails(data);
    },
    refetchOnWindowFocus: false,
  });

  const disableUserQuery = useMutation(() => dispatch(disableUser(id)), {
    onSuccess: () => {
      handleDialogClose();
    },
  });

  const sendMessageQuery = useMutation(() => dispatch(sendAuthSMS(id)));

  useEffect(() => {
    if (disableUserQuery.isSuccess) {
      const timer = setTimeout(() => {
        history.push("/settings/admin/users");
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [disableUserQuery.isSuccess, history]);

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(false));

  const handleDisableUser = () => {
    disableUserQuery.mutate();
  };

  const handleSendMessage = () => {
    sendMessageQuery.mutate();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Catcher>
      {isMobile && (
        <>
          <div className={globalClasses.container}>
            <MuiBackButton
              to="/settings/admin/users"
              onClick={handleBackButtonClick}
            />
            <div className={classes.titleRow}>
              <Typography className={classes.title} component="h1" variant="h5">
                {userDetails && (
                  <>
                    {userDetails.first_name} {userDetails.last_name}
                  </>
                )}
              </Typography>
              <Box className={classes.buttonWrapper}>
                {sendMessageQuery.isSuccess ? (
                  <Button
                    className={clsx(classes.button, classes.buttonSuccess)}
                    size="small"
                    variant="outlined"
                    startIcon={
                      <Done
                        className={classes.buttonSuccessIcon}
                        fontSize="small"
                      />
                    }
                    fullWidth
                    disabled
                  >
                    Welcome Message Sent
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={handleSendMessage}
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={sendMessageQuery.isLoading}
                  >
                    {sendMessageQuery.isLoading ? "Sending..." : "Reset Password"}
                  </Button>
                )}

                {disableUserQuery.isSuccess ? (
                  <Button
                    className={clsx(classes.button, classes.buttonSuccess)}
                    size="small"
                    variant="outlined"
                    startIcon={
                      <Done
                        className={classes.buttonSuccessIcon}
                        fontSize="small"
                      />
                    }
                    fullWidth
                    disabled
                  >
                    Deactivated
                  </Button>
                ) : (
                  <>
                    {user.id !== id && (
                      <Button
                        className={clsx(classes.button, classes.buttonRemove)}
                        onClick={handleDialogOpen}
                        size="small"
                        variant="outlined"
                        fullWidth
                        disabled={disableUserQuery.isLoading}
                      >
                        Deactivate account
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </div>
          </div>
        </>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && (
            <div className={classes.titleRow}>
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
                separator={<NavigateNext fontSize="small" />}
              >
                <Link
                  className={classes.breadcrumbItemRoot}
                  component={RouterLink}
                  to="/settings/admin/users"
                >
                  Users
                </Link>
                <Typography className={classes.breadcrumbItem}>
                  {userDetails && (
                    <>
                      {userDetails.first_name} {userDetails.last_name}
                    </>
                  )}
                </Typography>
              </Breadcrumbs>

              <Box className={classes.buttonWrapper}>
                {sendMessageQuery.isSuccess ? (
                  <Button
                    className={clsx(classes.button, classes.buttonSuccess)}
                    size="small"
                    variant="outlined"
                    startIcon={
                      <Done
                        className={classes.buttonSuccessIcon}
                        fontSize="small"
                      />
                    }
                    fullWidth
                    disabled
                  >
                    Welcome Message Sent
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.button, classes.buttonPrimary)}
                    onClick={handleSendMessage}
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={sendMessageQuery.isLoading}
                  >
                    {sendMessageQuery.isLoading ? "Sending..." : "Reset Password"}
                  </Button>
                )}

                {disableUserQuery.isSuccess ? (
                  <Button
                    className={clsx(classes.button, classes.buttonSuccess)}
                    size="small"
                    variant="outlined"
                    startIcon={
                      <Done
                        className={classes.buttonSuccessIcon}
                        fontSize="small"
                      />
                    }
                    fullWidth
                    disabled
                  >
                    Deactivated
                  </Button>
                ) : (
                  <>
                    {user.id !== id && (
                      <Button
                        className={clsx(classes.button, classes.buttonRemove)}
                        onClick={handleDialogOpen}
                        size="small"
                        variant="outlined"
                        fullWidth
                        disabled={disableUserQuery.isLoading}
                      >
                        Deactivate account
                      </Button>
                    )}
                  </>
                )}
              </Box>
            </div>
          )}

          <MuiTabs
            labels={["User information", "Assigned channels", "Notifications"]}
            onChange={setActiveUserDetailsTab}
            activeTab={activeTab}
          >
            <AdminUserInfo />
            <AdminUserChannels />
            <AdminUserNotifications />
          </MuiTabs>
        </Paper>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Deactivate account</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to deactivate {userDetails?.first_name}{" "}
              {userDetails?.last_name}?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.button}
              onClick={handleDialogClose}
              size="small"
              variant="outlined"
              fullWidth
            >
              Cancel
            </Button>

            <Button
              className={clsx(classes.button, classes.buttonRemove)}
              onClick={handleDisableUser}
              size="small"
              variant="outlined"
              fullWidth
              disabled={disableUserQuery.isLoading}
              autoFocus
            >
              {disableUserQuery.isLoading ? "Deactivating..." : "Deactivate"}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Catcher>
  );
};

export default AdminUserDetails;
