// Core
import React, { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { logout } from "redux/actions/auth-actions";

// Components
import {
  MenuItem,
  Typography,
  ClickAwayListener,
  Paper,
  Popper,
  MenuList,
  Fade,
  Link,
} from "@material-ui/core";

// Icons
import { ExpandMore, Settings, ExitToApp } from "@material-ui/icons";
import { ReactComponent as UserIcon } from "theme/icons/user.svg";

// Styles
import { useStyles } from "./user-menu.styles";

const MuiUserMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState();
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleOpen = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.row}>
      <Typography
        ref={anchorRef}
        aria-haspopup="true"
        className={clsx(classes.menu, open ? classes.active : "")}
        variant="caption"
        color="textPrimary"
        onClick={handleOpen("bottom")}
      >
        <UserIcon className={classes.icon} />
        {user && `${user.first_name} ${user.last_name}`}
        <ExpandMore className={classes.expandIcon} fontSize="small" />
      </Typography>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        role={undefined}
        disablePortal
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper className={classes.paper} square>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.list}
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem className={classes.item} onClick={handleClose}>
                    <Link
                      className={classes.row}
                      component={RouterLink}
                      to="/settings/user-info"
                      color="inherit"
                      underline="none"
                    >
                      <Settings className={classes.itemIcon} fontSize="small" />
                      Settings
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.item} onClick={handleLogout}>
                    <ExitToApp className={classes.itemIcon} fontSize="small" />
                    Logout
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default MuiUserMenu;
