// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
  breadcrumbItemRoot: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  breadcrumbItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  store: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginBottom: theme.spacing(2),
    },
  },
  storeInfo: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  storeSelect: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  storeTitles: {
    marginBottom: 4,
  },
  storeNameTitle: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.main,
  },
  storeText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  storeDate: {
    marginRight: theme.spacing(2),
  },
  storeDateIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5),
  },
  storeUserIcon: {
    width: 16,
    height: 16,
    marginRight: 2,
    color: theme.palette.text.grey,
  },
  charts: {},
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    padding: 20,
    marginBottom: theme.spacing(3),
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  tableWrapper: {},
  barChart: {
    marginBottom: 0,
  },
  cardInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  cardTitle: {
    marginRight: theme.spacing(2),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    letterSpacing: "0.15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  cardTooltip: {
    flex: "0 0 12px",
    marginLeft: 6,
  },
  rankItem: {
    marginRight: theme.spacing(3),
    "&:last-child": {
      marginRight: 0,
    },
  },
  rankTitle: {
    lineHeight: 1.2,
    marginRight: 6,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  tableSubtitle: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.main,
  },
  chartTitle: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
    letterSpacing: "0.15px",
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

export { useStyles };
