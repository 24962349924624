// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    minWidth: 120,
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
    "& .MuiInputBase-root": {
      borderRadius: 6,
      borderColor: theme.palette.grey.border,
      backgroundColor: theme.palette.common.white,
      "& fieldset": {
        border: `1px solid ${theme.palette.grey.border}`,
      },
      "&:hover fieldset": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    "& .MuiFormLabel-root": {
      fontFamily: theme.typography.regular.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.grey,
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 180,
    },
  },
  title: {
    marginRight: theme.spacing(1.5),
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
    whiteSpace: "nowrap",
  },
  expandIcon: {
    color: theme.palette.text.main,
  },
  option: {
    padding: "8px 12px",
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  noOptions: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
}));

export { useStyles };
