// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  timeWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
  },
  timeFrom: {
    marginRight: theme.spacing(4),
  },
  days: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  button: {
    minHeight: 37,
    minWidth: 50,
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    textTransform: "none",
    color: theme.palette.primary.main,
    backgroundColor: "#E4ECFC",
    border: `1px solid ${theme.palette.background.main}`,
    "&:hover, &:active, &:focus": {
      border: `1px solid ${theme.palette.background.main}`,
      color: theme.palette.primary.main,
      backgroundColor: "#E4ECFC",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "auto",
      padding: "3px 6px",
    },
  },
  activeButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create([
      "background-color",
      "color",
      "border",
    ]),
    "&:hover, &:active, &:focus": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  formControl: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    minWidth: 120,
  },
  formTitle: {
    marginRight: theme.spacing(1),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  select: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
    "&:before, &:after": {
      display: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-icon": {
      width: 20,
      height: 20,
      top: "calc(50% - 10px)",
      color: theme.palette.text.main,
    },
  },
}));

export { useStyles };
