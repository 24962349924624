// Core
import React from "react";

// Components
import { Typography, Button } from "@material-ui/core";

// Tools
import { getFirstLettersFromUserName, formatPhoneNumber } from "utils/helpers";

// Styles
import { useStyles } from "./user-card.styles";

function UserCard({ children }) {
  const classes = useStyles();
  return <section className={classes.card}>{children}</section>;
}

function UserCardCircle({ user }) {
  const classes = useStyles();
  return (
    <div className={classes.circle}>{getFirstLettersFromUserName(user)}</div>
  );
}

function UserCardTitle({ title }) {
  const classes = useStyles();
  return (
    <Typography className={classes.title} variant="subtitle1">
      {title}
    </Typography>
  );
}

function UserCardEmail({ email }) {
  const classes = useStyles();
  return <Typography className={classes.email}>{email}</Typography>;
}

function UserPhoneNumber({ phoneNumber }) {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.phoneTitle} variant="body1">
        Phone number
      </Typography>
      <Typography className={classes.phoneNumber} variant="body1">
        {formatPhoneNumber(phoneNumber)}
      </Typography>
    </>
  );
}

function UserCardButton({ children, disabled = false }) {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      size="small"
      variant="outlined"
      color="primary"
      fullWidth
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export {
  UserCard,
  UserCardCircle,
  UserCardTitle,
  UserCardEmail,
  UserPhoneNumber,
  UserCardButton,
};
