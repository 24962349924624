// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    marginBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatar: {
    backgroundColor: theme.palette.background.blue,
  },
  avatarIcon: {
    width: 20,
    height: 20,
    fill: theme.palette.primary.main,
  },
  primary: {
    maxWidth: 385,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  titleWrapper: {
    marginBottom: 6,
  },
  title: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
  },
  time: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.lightGrey,
  },
  alert: {
    position: "relative",
    marginBottom: 10,
    padding: "22px 18px",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.border}`,
    boxShadow: "0px 1px 11px rgba(23, 23, 23, 0.0441707)",
  },
  line: {
    position: "absolute",
    top: -1,
    left: 0,
    width: "100%",
    height: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.palette.primary.main,
  },
  alertTitleWrapper: {
    marginBottom: 10,
  },
  alertTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
  },
  alertText: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 3,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  alertIconWrapper: {
    marginRight: theme.spacing(1.5),
  },
  alertLink: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.primary.main,
  },
  manager: {
    display: "flex",
    alignItems: "center",
  },
  managerIcon: {
    width: 18,
    marginRight: theme.spacing(0.5),
  },
  managerName: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
}));

export { useStyles };
