// Core
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

// Components
import { Typography, Button } from "@material-ui/core";
import { OrdersSortby } from "components";

// Actions
import { filterOrdersByPeriod } from "redux/actions/filters-actions";
import { showSidebar } from "redux/actions/ui-actions";

// Icons
import { Tune } from "@material-ui/icons";
import { ReactComponent as AudioIcon } from "theme/icons/audio.svg";
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";

// Styles
import { useStyles } from "./orders-filters.styles";

const OrdersFilters = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ui.sidebarIsOpen);
  const dateFrom = useSelector(
    (state) =>
      state?.filters?.orders?.period?.date_from?.split(" ")[0] ||
      moment().format("YYYY-MM-DD")
  );
  const [selectedDate, setDate] = useState(moment());
  const [inputValue, setInputValue] = useState(dateFrom);

  const onDateChange = (date, value) => {
    if (value) {
      dispatch(
        filterOrdersByPeriod({
          value: "today",
          date_from: `${value} 00:00:00`,
          date_to: `${value} 23:59:59`,
        })
      );
      setDate(date);
      setInputValue(value);
    }
  };

  return (
    <>
      <div className={classes.topFilters}>
        <div className={clsx(classes.row, classes.titleRow)}>
          <Typography
            variant="h2"
            color="textPrimary"
            className={classes.title}
          >
            <AudioIcon className={classes.audioIcon} />
            Audios
          </Typography>
          {!isOpen && (
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="medium"
              startIcon={<Tune />}
              onClick={() => dispatch(showSidebar(!isOpen))}
            >
              Filters
            </Button>
          )}
        </div>
        <div className={classes.dateFilters}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <KeyboardDatePicker
              className={classes.datePicker}
              margin="dense"
              id="date-picker"
              label="Pick Date"
              variant="inline"
              inputVariant="outlined"
              format="YYYY-MM-DD"
              value={selectedDate}
              inputValue={inputValue}
              onChange={onDateChange}
              keyboardIcon={<CalendarIcon className={classes.calendarIcon} />}
              KeyboardButtonProps={{
                "aria-label": "Start date",
              }}
              autoOk
              disableToolbar
              disableFuture
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div className={classes.bottomFilters}>
        <OrdersSortby />
      </div>
    </>
  );
};

export default OrdersFilters;
