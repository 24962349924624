// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  menu: {
    display: "flex",
    alignItems: "center",
    padding: "5px 0",
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: "transparent",
    color: theme.palette.text.main,
    cursor: "pointer",
    userSelect: "none",
    transition: "all 0.2s ease-in-out",
  },
  icon: {
    width: "26px",
    height: "26px",
    marginRight: theme.spacing(1),
  },
  expandIcon: {
    marginLeft: theme.spacing(1),
    transition: "transform 0.2s ease-in-out",
  },
  itemIcon: {
    fill: theme.palette.text.grey,
    marginRight: theme.spacing(0.5),
  },
  paper: {
    borderRadius: 8,
  },
  list: {
    padding: 0,
    minWidth: 120,
  },
  item: {
    padding: "10px 15px",
  },
  active: {
    "& .MuiSvgIcon-root": {
      transform: "rotate(180deg)",
    },
  },
}));

export { useStyles };
