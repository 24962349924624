// Core
import { $http } from "api";

class FeedService {
  static async getFeed(feed) {
    return $http.post(`/chat/feed`, { ...feed });
  }
}

export default FeedService;
