// Core
import React from "react";
import { Switch, Route } from "react-router-dom";

// Components
import { ChangePassword } from "components/forms";
import {
  UserInfo,
  UserNotifications,
  AdminUsers,
  AdminAlerts,
  AdminAlertCreateAlert,
  AdminAlertDetails,
  AdminChannels,
  AdminChannelDetails,
  AdminUserDetails,
  AdminUserAddChannel,
  AdminUserCreateUser,
} from "components/settings";

const getRoutes = (isAdmin) => {
  if (isAdmin) {
    return (
      <Switch>
        <Route exact path="/settings/user-info" component={UserInfo} />
        <Route exact path="/settings/security" component={ChangePassword} />
        <Route
          exact
          path="/settings/notifications"
          component={UserNotifications}
        />
        <Route exact path="/settings/admin/users" component={AdminUsers} />
        <Route
          exact
          path="/settings/admin/channels"
          component={AdminChannels}
        />
        <Route
          exact
          path="/settings/admin/channels/:id"
          component={AdminChannelDetails}
        />
        <Route
          exact
          path="/settings/admin/users/create-user"
          component={AdminUserCreateUser}
        />
        <Route
          exact
          path="/settings/admin/users/:id"
          component={AdminUserDetails}
        />
        <Route
          exact
          path="/settings/admin/users/:id/channels"
          component={AdminUserAddChannel}
        />
        <Route exact path="/settings/admin/alerts" component={AdminAlerts} />
        <Route
          exact
          path="/settings/admin/alerts/create-alert"
          component={AdminAlertCreateAlert}
        />
        <Route
          exact
          path="/settings/admin/alerts/:id"
          component={AdminAlertDetails}
        />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/settings/user-info" component={UserInfo} />
      <Route exact path="/settings/security" component={ChangePassword} />
      <Route
        exact
        path="/settings/notifications"
        component={UserNotifications}
      />
    </Switch>
  );
};

export { getRoutes };
