// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "70%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  empty: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(14),
  },
}));

export { useStyles };
