// Core
import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";
import clsx from "clsx";

// Actions
import { getAlert, updateAlertStatus } from "redux/actions/alert-actions";

// Components
import {
  useMediaQuery,
  Typography,
  Chip,
  Avatar,
  Button,
  Link,
} from "@material-ui/core";
import { MuiWrapper, MuiLoader, MuiBackButton } from "components/common";
import { Catcher, ChatReply } from "components";
import { Alert as AlertMessage } from "@material-ui/lab";

// Icons
import { ReactComponent as SystemIsDownIcon } from "theme/icons/system-is-down.svg";
import { ReactComponent as StatusIcon } from "theme/icons/check.svg";
import { ReactComponent as TimeIcon } from "theme/icons/duration.svg";
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";
import { ReactComponent as StartIcon } from "theme/icons/start.svg";
import { ReactComponent as SpeakerIcon } from "theme/icons/speaker.svg";

// Styles
import { useStyles } from "./alert.styles";

// Tools
import {
  capitalizeString,
  secToMinutes,
  getAmpmWithTimezone,
  formatEntities,
  getTimeFromTimestamp,
  calculateDuration,
  formatUpsell,
} from "utils/helpers";

const Alert = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [alert, setAlert] = useState(null);
  const isLowUpsell = alert?.alert?.type === "ops_low_upsell";

  const { mutate } = useMutation(
    ({ id, status }) => dispatch(updateAlertStatus(id, status)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["alert", id]);
      },
    }
  );

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["alert", id], () => dispatch(getAlert(id)), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (apiResponse) {
      setAlert(apiResponse);
    }
  }, [apiResponse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleStatusChange = () =>
    mutate({ id: alert.alert_id, status: "resolved" });

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <AlertMessage severity="error">{error.message}</AlertMessage>;
  }

  return (
    <>
      {alert && (
        <Catcher>
          <MuiWrapper maxWidth="xl" isMobile={isMobile}>
            <div className={isMobile ? classes.container : ""}>
              {history.location?.state?.from === "feed" ? (
                <MuiBackButton previousRoute={true} />
              ) : (
                <MuiBackButton to={`/chat/${alert.channel_id}`} />
              )}

              <div className={classes.alert}>
                <div
                  className={clsx(
                    classes.border,
                    isLowUpsell && classes.upsellBorder
                  )}
                ></div>
                <div className={clsx(classes.row, classes.header)}>
                  <Avatar className={classes.avatar}>
                    <SystemIsDownIcon className={classes.avatarIcon} />
                  </Avatar>
                  <div>
                    <div className={clsx(classes.row, classes.wrap)}>
                      <Typography variant="h5" className={classes.title}>
                        Alert #{alert?.alert?.store?.store_name}
                      </Typography>
                      <Typography
                        variant="body1"
                        className={classes.description}
                      >
                        {alert?.alert?.description}
                      </Typography>
                      {isLowUpsell && (alert?.alert?.metric >= 0) && (
                        <Typography
                          variant="body1"
                          className={classes.alertMetric}
                        >
                          Store has around
                          <span className={classes.metricValue}>
                            {formatUpsell(alert?.alert?.metric)}
                          </span>{" "}
                          upsells per order
                        </Typography>
                      )}
                    </div>

                    {alert?.alert?.status === "resolved" && (
                      <>
                        {alert?.alert?.modifiedBy && (
                          <span className={classes.status}>
                            <StatusIcon className={classes.statusIcon} />
                            {isLowUpsell ? (
                              "Reviewed"
                            ) : (
                              <>
                                {capitalizeString(alert?.alert?.status)} by{" "}
                                {alert?.alert?.modifiedBy?.first_name}{" "}
                                {alert?.alert?.modifiedBy?.last_name}
                              </>
                            )}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {isLowUpsell && (
                  <div className={classes.scripts}>
                    <Typography variant="h6" className={classes.scriptsTitle}>
                      Script to use more:
                    </Typography>
                    {alert?.alert?.lines && (
                      <div className={classes.lines}>
                        {alert?.alert?.lines.map((text, index) => (
                          <Chip
                            key={index}
                            className={classes.line}
                            variant="outlined"
                            size="small"
                            label={text}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {!isLowUpsell && (
                  <div className={classes.info}>
                    <div className={clsx(classes.row, classes.infoItem)}>
                      <CalendarIcon className={classes.calendarIcon} />
                      <span className={classes.infoText}>
                        {alert?.alert?.total}{" "}
                        {alert?.alert?.total > 1 ? "orders" : "order"} in the
                        past{" "}
                        {calculateDuration(
                          alert?.alert?.timestamp,
                          alert?.alert?.date_start
                        )}
                      </span>
                    </div>
                    <div className={classes.row}>
                      <StartIcon className={classes.startIcon} />
                      <span className={classes.infoText}>
                        Starting at{" "}
                        {getAmpmWithTimezone(
                          alert?.alert?.date_start,
                          alert?.alert?.store.timezone
                        )}
                      </span>
                    </div>
                  </div>
                )}

                {alert?.alert?.status === "resolved" ? (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.resolveButton)}
                  >
                    <StatusIcon className={classes.resolveIcon} />
                    {isLowUpsell ? "Reviewed" : "Resolved"}
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.statusButton)}
                    onClick={handleStatusChange}
                  >
                    {isLowUpsell ? "Review w team" : "Resolve alert"}
                  </Button>
                )}
              </div>
              <div className={classes.row}>
                <ChatReply message={alert} isFeed={true} />
              </div>
            </div>

            <div className={clsx(classes.tags)}>
              {alert?.alert?.orders?.length > 0 && (
                <div className={clsx(classes.tagsCount, classes.container)}>
                  Transcriptions
                </div>
              )}
              {alert?.alert?.orders &&
                alert?.alert?.orders.map((order, index) => (
                  <Link
                    key={index}
                    className={clsx(classes.tagWrapper, classes.container)}
                    component={RouterLink}
                    to={{
                      pathname: `/audios/${order?.id}`,
                      state: {
                        duration: order?.duration,
                        alert_offset: order?.alert_offset,
                      },
                    }}
                    color="inherit"
                    underline="none"
                  >
                    <div>
                      <Chip
                        variant="outlined"
                        className={classes.tag}
                        label={
                          <div className={classes.stretch}>
                            <span className={classes.tagText}>
                              {formatEntities({
                                text: order?.alert_utterance,
                                entities:
                                  order?.alert_entities.length > 0
                                    ? [order?.alert_entities[0]]
                                    : [],
                              })}
                            </span>
                          </div>
                        }
                      />
                      <span className={classes.timeRow}>
                        <TimeIcon className={classes.timeIcon} />
                        <span className={classes.time}>
                          {secToMinutes(order?.duration)} at{" "}
                          {getTimeFromTimestamp(order?.timestamp_local)}
                        </span>
                      </span>
                    </div>

                    <SpeakerIcon className={classes.tagIcon} />
                  </Link>
                ))}
            </div>
          </MuiWrapper>
        </Catcher>
      )}
    </>
  );
};

export default Alert;
