// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";
import {
  getCompanyChannels,
  searchByChannels,
  setActiveChannelDetailsTab,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import {
  MuiBackButton,
  MuiLoader,
  MuiSearch,
  MuiEmptySearch,
} from "components/common";
import {
  Paper,
  ChannelCard,
  ChannelCardAddress,
  ChannelCardButton,
  ChannelCardTitle,
  ChannelUsersCount,
  ChannelBlacklistedCount,
  AdminTitle,
} from "components/settings";
import { Link, Grid, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Tools
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./channels.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminUsers = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useBreakpoints();
  const searchChannelsValue = useSelector(
    (state) => state.admin.searchChannelsValue
  );
  const [channels, setChannels] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["admin_channels"], () => dispatch(getCompanyChannels()), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (apiResponse) {
      if (searchChannelsValue.length > 0) {
        const filteredChannels = apiResponse.filter(({ name, store }) =>
          searchBy(
            {
              name,
              address_line: store?.address_line,
              state: store?.state,
              city: store?.city,
              manager_district_name: store?.manager_district_name,
              store_name: store?.store_name,
            },
            searchChannelsValue
          )
        );

        setChannels(filteredChannels);
      } else {
        setChannels(apiResponse);
      }
    }
  }, [searchChannelsValue, apiResponse]);

  useEffect(() => {
    dispatch(setActiveChannelDetailsTab(0));
  }, [dispatch]);

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(true));

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/admin/channels"
            onClick={handleBackButtonClick}
          />
          <AdminTitle title="Channels" />
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          <div className={classes.titleRow}>
            {isDesktop && <AdminTitle title="Channels" />}

            {channels && (
              <MuiSearch
                className={classes.search}
                placeholder="Search channel"
                value={searchChannelsValue}
                onChange={(channel) => dispatch(searchByChannels(channel))}
              />
            )}
          </div>

          {isLoading && <MuiLoader fullpage />}
          {isError && <Alert severity="error">{error.message}</Alert>}

          {channels?.length === 0 && (
            <MuiEmptySearch text="No channels found" />
          )}

          {channels && (
            <Grid container spacing={2}>
              {channels.map((channel) => (
                <Grid key={channel.id} item xs={12} sm={4} md={3}>
                  <ChannelCard>
                    <div>
                      <ChannelCardTitle title={channel.name} />
                      <ChannelCardAddress
                        addressLine={channel.store?.address_line}
                        state={channel.store?.state}
                        city={channel.store?.city}
                        description={channel.description}
                      />
                    </div>

                    <div>
                      <Box className={classes.countWrapper}>
                        <ChannelUsersCount count={channel.user_count} />
                        <ChannelBlacklistedCount count={channel.blacklisted} />
                      </Box>

                      <Link
                        className={classes.cardLink}
                        component={RouterLink}
                        to={`/settings/admin/channels/${channel.id}`}
                        color="inherit"
                        underline="none"
                      >
                        <ChannelCardButton>Details</ChannelCardButton>
                      </Link>
                    </div>
                  </ChannelCard>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminUsers;
