// Core
import React from "react";

// Components
import { AppBar, Toolbar } from "@material-ui/core";
import { MuiUserMenu, MuiNavigation } from "components/common";

// Icons
import { ReactComponent as LogoIcon } from "theme/icons/logo.svg";

// Styles
import { useStyles } from "./header.styles";

const MuiHeader = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.row}>
        <LogoIcon className={classes.logo} />
        <MuiNavigation />
        <MuiUserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default MuiHeader;
