// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    marginBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatar: {
    backgroundColor: theme.palette.brown.light,
  },
  avatarIcon: {
    width: 25,
    height: 29,
  },
  primary: {
    maxWidth: 385,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  titleWrapper: {
    marginBottom: 6,
  },
  title: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
  },
  time: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.lightGrey,
  },
  alert: {
    position: "relative",
    marginBottom: 10,
    padding: "22px 18px",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.border}`,
    boxShadow: "0px 1px 11px rgba(23, 23, 23, 0.0441707)",

    "& button": {
      bottom: "auto",
    },
  },
  border: {
    position: "absolute",
    top: -1,
    left: 0,
    width: "100%",
    height: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.palette.brown.secondary,
  },
  alertTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
  },
  alertMetric: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  metricValue: {
    marginLeft: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: "4px",
    fontFamily: theme.typography.bold.fontFamily,
    backgroundColor: theme.palette.yellow.main,
  },
  alertText: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  status: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.green.light,
  },
  statusIcon: {
    width: 12,
    height: 9,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.green.light,
  },
  scripts: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  scriptsTitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  lines: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  line: {
    minHeight: 28,
    padding: "3px 0",
    border: "none",
    borderRadius: 6,
    marginBottom: 6,
    textTransform: "lowercase",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.blue.main,
    fontSize: theme.typography.pxToRem(15),
    fontFamily: theme.typography.regular.fontFamily,

    "& .MuiChip-label": {
      whiteSpace: "normal",
      lineHeight: 1.2,
    },
    "&.MuiChip-sizeSmall": {
      height: "auto",
    },
  },
  button: {
    width: "100%",
    height: 38,
    borderRadius: 8,
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.bold.fontFamily,
    textTransform: "none",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
  },
  detailsButton: {
    marginRight: 10,
  },
  statusButton: {
    borderColor: theme.palette.text.main,
    color: theme.palette.text.main,
  },
  resolveButton: {
    borderColor: theme.palette.green.light,
    color: theme.palette.green.light,
  },
  resolveIcon: {
    width: 15,
    height: 15,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.green.light,
  },
}));

export { useStyles };
