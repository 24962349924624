// Core
import { $http } from "api";

class FiltersService {
  static async getTags() {
    return $http.get("/filters/tags");
  }
}

export default FiltersService;
