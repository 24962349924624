// Core
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";

// Actions
import {
  getTags,
  addFilterTag,
  removeFilterTag,
  setCurrentPage,
} from "redux/actions/filters-actions";

// Components
import { MuiAccordion, MuiLoader, MuiCheckbox } from "components/common";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  tags: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 75,
    },
  },
}));

const MuiFilterTags = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);
  const [tags, setTags] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["tags"], () => dispatch(getTags()), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (apiResponse) {
      setTags(apiResponse);
    }
  }, [apiResponse, dispatch]);

  const getLabels = (tag) => {
    if (tag === "ops") return "Operations";
    if (tag === "upsell") return "Upsell";
    if (tag === "product") return "Specify product";
  };

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div className={classes.tags}>
      {tags &&
        Object.keys(tags).map((tag) => (
          <MuiAccordion
            key={tag}
            direction="column"
            label={getLabels(tag)}
            defaultExpanded
            square
          >
            {tags[tag].map(({ name, pretty_name }) => (
              <MuiCheckbox
                key={name}
                label={pretty_name}
                name={name}
                handleChecked={(tag) => {
                  dispatch(setCurrentPage(1));
                  dispatch(addFilterTag(tag));
                }}
                handleUnChecked={(tag) => {
                  dispatch(setCurrentPage(1));
                  dispatch(removeFilterTag(tag));
                }}
                checkedArray={filters.orders.period.tags}
              />
            ))}
          </MuiAccordion>
        ))}
    </div>
  );
};

export default MuiFilterTags;
