// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumb: {
    marginBottom: theme.spacing(4),
  },
  breadcrumbItemRoot: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  breadcrumbItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  userTitle: {
    marginBottom: 0,
    marginRight: theme.spacing(2),
  },
  selectButton: {
    width: 95,
    minHeight: 30,
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    textTransform: "none",
    color: theme.palette.primary.main,
    backgroundColor: "#E4ECFC",
    border: "1px solid #4B7FEE",
  },
  title: {
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.main,
  },
  subtitle: {
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  submitButton: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export { useStyles };
