// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
  },
  topFilters: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(4),
    fontSize: theme.typography.pxToRem(24),
  },
  dateFilters: {
    display: "flex",
    alignItems: "center",
  },
  datePicker: {
    width: 246,
    height: 44,
    fontSize: theme.typography.pxToRem(14),
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  calendarIcon: {
    width: 15,
    height: 15,
  },
  bottomFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(4),
  },
  button: {
    height: 45,
    padding: 0,
    width: 100,
    borderRadius: 6,
    backgroundColor: theme.palette.background.blue,
    borderColor: theme.palette.primary.main,
    "& .MuiButton-label": {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      textTransform: "none",
    },
  },
  audioIcon: {
    width: "24px",
    height: "24px",
    marginRight: theme.spacing(1.5),
  },
  date: {
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",
  },
}));

export { useStyles };
