// Core
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import clsx from "clsx";

// Actions
import { createLike } from "redux/actions/chat-actions";

// Icons
import { ThumbUpAlt } from "@material-ui/icons";

// Styles
import { useStyles } from "./chat-like.styles";

const ChatLike = ({ message, socket }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const userLiked = message.likes.find(
    (like) => like.user.email === user.email
  );

  const { mutate } = useMutation((data) => dispatch(createLike(data)), {
    onSuccess: (newLike) => {
      socket.emit("like", newLike);
    },
  });

  const handleClickLike = () => {
    mutate({ messageId: message.id, type: message.type });
  };

  return (
    <span
      className={clsx(
        classes.row,
        classes.like,
        userLiked ? classes.activeLike : ""
      )}
      onClick={handleClickLike}
    >
      <ThumbUpAlt className={classes.icon} fontSize="small" />
      {message.likes.length > 0 && (
        <span className={classes.count}>{message.likes.length}</span>
      )}
    </span>
  );
};

export default ChatLike;
