// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumbItemRoot: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  breadcrumbItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.main,
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  button: {
    position: "relative",
    height: 35,
    maxWidth: 150,
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    textTransform: "none",
  },
  buttonAdd: {
    color: theme.palette.primary.main,
    backgroundColor: "rgba(228, 236, 252, 1)",
    border: "1px solid #4B7FEE",

    "&:hover": {
      backgroundColor: "rgba(75, 127, 238, 0.3)",
    },
  },
  buttonRemove: {
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    backgroundColor: "#FDF0F0",

    "&:hover": {
      backgroundColor: "#FDF0F0",
    },
  },
  buttonRemoveIcon: {
    color: theme.palette.red.main,
  },
  buttonSuccess: {
    "&.Mui-disabled": {
      color: theme.palette.green.alert,
      borderColor: theme.palette.green.alert,
    },
  },
  buttonSuccessIcon: {
    color: theme.palette.green.alert,
  },
  dialogActions: {
    justifyContent: "center",
    padding: theme.spacing(2),
  },
  input: {
    maxWidth: 345,
    position: "relative",
    marginBottom: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      backgroundColor: theme.palette.grey.light,
      "& fieldset": {
        borderColor: theme.palette.grey.border,
        borderWidth: 1,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey.border,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
      },
      "&.Mui-error fieldset": {
        borderColor: "#f44336",
      },
    },
    "& .MuiInputBase-input": {
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.main,
    },
    "& .MuiInputLabel-root": {
      fontSize: theme.typography.pxToRem(14),
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: 0,
      left: 0,
    },
  },
  ignoreInput: {
    maxWidth: "100%",
  },
  lineInput: {
    maxWidth: "100%",
  },
  chipWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(1),
  },
  chip: {
    height: 30,
    border: "none",
    borderRadius: 6,
    marginRight: theme.spacing(1),
    color: theme.palette.text.main,
    fontSize: theme.typography.pxToRem(14),
    "& .MuiChip-deleteIcon": {
      width: 19,
      height: 19,
    },
  },
  ignoreChip: {
    backgroundColor: theme.palette.yellow.main,
  },
  lineChip: {
    backgroundColor: theme.palette.blue.main,
  },
  addIcon: {
    marginBottom: theme.spacing(0.5),
  },
  subtitle: {
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.main,
  },
  submitButton: {
    display: "block",
    maxWidth: 200,
    whiteSpace: "nowrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export { useStyles };
