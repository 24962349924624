// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  reply: {
    height: 26,
    padding: "0 10px 0 8px",
    borderRadius: 100,
    backgroundColor: theme.palette.grey.border,
    cursor: "pointer",
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.darkGrey,
  },
}));

export { useStyles };
