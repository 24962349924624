// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    marginBottom: theme.spacing(1),

    "& .alert-good-icon": {
      width: 175,
      height: 175,
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.main,
  },
  metric: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  metricValue: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: "4px",
    fontFamily: theme.typography.bold.fontFamily,
    backgroundColor: theme.palette.yellow.main,
  },
  text: {
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  manager: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  managerIcon: {
    width: 20,
    marginRight: theme.spacing(0.5),
  },
  managerName: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
}));

export { useStyles };
