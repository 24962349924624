// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  wrapper: {
    marginBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  avatar: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.bold.fontFamily,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  myAvatar: {
    backgroundColor: theme.palette.green.main,
  },
  title: {
    marginBottom: 6,
  },
  messageTitle: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
  },
  messageTime: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.lightGrey,
  },
  messageText: {
    marginBottom: 10,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  reply: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderRadius: "1px",
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    lineHeight: 1.2,
  },
  replyTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.primary.main,
  },
  replyTo: {
    fontFamily: theme.typography.bold.fontFamily,
  },
  replyText: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.main,
  },
}));

export { useStyles };
