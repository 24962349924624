// Core
import React from "react";

// Components
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";

// Icons
import { ExpandMore } from "@material-ui/icons";

// Styles
import { useStyles } from "./accordion.styles";

const MuiAccordion = ({ children, label, direction = "row", ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion {...rest}>
        <AccordionSummary
          className={classes.wrapper}
          expandIcon={<ExpandMore />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography variant="h5" color="inherit" className={classes.label}>
            {label}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={direction === "column" ? classes.column : ""}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MuiAccordion;
