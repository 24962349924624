// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  search: {
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minHeight: 160,
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  cardRow: {
    display: "flex",
    marginBottom: 10,
  },
  cardAvatar: {
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: 32,
    height: 32,
    marginRight: 10,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    backgroundColor: "rgba(29, 69, 149, 0.25)",
    color: theme.palette.text.grey,
  },
  cardTitle: {
    marginBottom: theme.spacing(0.5),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.1,
    color: theme.palette.text.main,
  },
  cardSubtitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(13),
    lineHeight: 1.1,
    color: theme.palette.text.grey,
  },
  cardThreshold: {
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  ignoreCount: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  cardLink: {
    width: "100%",
  },
  cardButton: {
    position: "relative",
    minHeight: 30,
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    textTransform: "none",
    color: theme.palette.primary.main,
    backgroundColor: "#E4ECFC",
    border: "1px solid #4B7FEE",
  },
}));

export { useStyles };
