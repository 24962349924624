// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    padding: 0,
    maxWidth: 444,
  },
  name: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  address: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  button: {
    maxWidth: 180,
    whiteSpace: "nowrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export { useStyles };
