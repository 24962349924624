// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  line: {
    fill: "none",
    strokeWidth: "0.5px",
    strokeDasharray: "0.2em",
  },
  plotTitle: {
    fontSize: theme.typography.pxToRem(13),
    fill: theme.palette.text.darkGrey,
    textAnchor: "middle",
  },
  plotXLabel: {
    fontSize: theme.typography.pxToRem(13),
    fill: theme.palette.text.darkGrey,
    textAnchor: "middle",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  plotBarValue: {
    fontSize: theme.typography.pxToRem(13),
    fill: theme.palette.text.darkGrey,
    textAnchor: "middle",
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
}));

export { useStyles };
