// Core
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Components
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";

// Icons
import { CheckBoxOutlineBlank, CheckBox } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: "7px 7px 7px 9px",
  },
  label: {
    "& .MuiFormControlLabel-label": {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.grey,
      [theme.breakpoints.down("sm")]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
  },
}));

const MuiCheckbox = ({
  label,
  name,
  handleChecked,
  handleUnChecked,
  checkedArray,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = () => {
    if (!checkedArray.includes(name)) {
      handleChecked(name);
    }
    if (checkedArray.includes(name)) {
      handleUnChecked(name);
    }
  };

  return (
    <FormGroup>
      <FormControlLabel
        className={classes.label}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checkedArray.includes(name) ? true : false}
            onChange={handleChange}
            icon={
              <CheckBoxOutlineBlank fontSize={isMobile ? "medium" : "small"} />
            }
            checkedIcon={<CheckBox fontSize={isMobile ? "medium" : "small"} />}
            name={name}
            value={name}
            color="primary"
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

MuiCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleChecked: PropTypes.func.isRequired,
  handleUnChecked: PropTypes.func.isRequired,
  checkedArray: PropTypes.array,
};

export default MuiCheckbox;
