// Core
import React from "react";
import clsx from "clsx";

// Components
import { ChatLike, ChatReply } from "components";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Chip,
  Button,
} from "@material-ui/core";

// Icons
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";
import { ReactComponent as SystemIsDownIcon } from "theme/icons/system-is-down.svg";
import { ReactComponent as StatusIcon } from "theme/icons/check.svg";

// Styles
import { useStyles } from "./chat-alert-ops.styles";

// Tools
import {
  getAmpmFromTimestamp,
  capitalizeString,
  calculateDuration,
  formatEntities,
} from "utils/helpers";

const ChatAlertOps = ({
  message,
  alert,
  socket,
  onStatusChange,
  onDetailsClick,
  isFeed,
}) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.wrapper} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <SystemIsDownIcon className={classes.avatarIcon} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className={classes.primary}>
            <div className={clsx(classes.row, classes.titleWrapper)}>
              <Typography variant="h5" className={classes.title}>
                Alert #{message?.alert?.store?.store_name}
              </Typography>
              <span className={classes.time}>
                {getAmpmFromTimestamp(message.timestamp)}
              </span>
            </div>

            <div className={classes.alert}>
              <div className={classes.line}></div>
              <Typography variant="h5" className={classes.alertTitle}>
                {alert.description}
              </Typography>
              {alert.status === "resolved" && (
                <Typography variant="body1" className={classes.status}>
                  <StatusIcon className={classes.statusIcon} />
                  {capitalizeString(alert.status)}
                </Typography>
              )}

              <div className={classes.transcription}>
                <Typography variant="h6" className={classes.transcriptionTitle}>
                  Top transcriptions:
                </Typography>
                {alert.utterances && (
                  <div className={classes.tags}>
                    {alert.utterances.map((tag, index) => (
                      <Chip
                        key={index}
                        className={classes.tag}
                        variant="outlined"
                        size="small"
                        label={formatEntities(tag)}
                      />
                    ))}
                  </div>
                )}
              </div>

              <div className={classes.store}>
                <div className={classes.row}>
                  <CalendarIcon className={classes.storeIcon} />
                  <span className={classes.storeText}>
                    {alert.total} {alert.total > 1 ? "orders" : "order"} in the
                    past {calculateDuration(alert.timestamp, alert.date_start)}
                  </span>
                </div>
              </div>

              <div className={classes.row}>
                <Button
                  variant="outlined"
                  className={clsx(classes.button, classes.detailsButton)}
                  onClick={() => onDetailsClick(alert.id)}
                >
                  View detail
                </Button>
                {alert.status === "resolved" ? (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.resolveButton)}
                  >
                    <StatusIcon className={classes.resolveIcon} />
                    Resolved
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    className={clsx(classes.button, classes.statusButton)}
                    onClick={() => onStatusChange(alert.id)}
                  >
                    Resolve alert
                  </Button>
                )}
              </div>
            </div>
          </div>
        }
        secondary={
          <span className={classes.row}>
            {!isFeed && <ChatLike message={message} socket={socket} />}
            <ChatReply message={message} isFeed={isFeed} />
          </span>
        }
      />
    </ListItem>
  );
};

export default ChatAlertOps;
