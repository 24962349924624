// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  medalIcon: {
    width: 35,
    height: "auto",
    cursor: "pointer",
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  date: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
    letterSpacing: "0.25px",
  },
  score: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.pxToRem(17),
    color: theme.palette.text.darkGrey,
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  badgeIcon: {
    marginRight: theme.spacing(2),
  },
  badgeDescription: {
    color: theme.palette.text.main,
  },
  badgeTotal: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.bold.fontFamily,
  },
}));

export { useStyles };
