// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  searchRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  channelsCount: {
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  search: {
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
}));

export { useStyles };
