// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";
import clsx from "clsx";

// Actions
import {
  getCompanyNewAlerts,
  addAdminAlert,
  removeAdminAlert,
  addNewAlerts,
  addAllAdminAlerts,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import {
  MuiLoader,
  MuiBackButton,
  MuiCheckbox,
  MuiButton,
} from "components/common";
import { Paper, UserTitle } from "components/settings";
import { Typography, Link, Breadcrumbs, Button, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { NavigateNext } from "@material-ui/icons";

// Styles
import { useStyles } from "./create-alert.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminAlertCreateAlert = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const { newAlerts } = useSelector((state) => state.admin);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const { isMobile, isDesktop } = useBreakpoints();

  const getNewAlertsQuery = useQuery(
    ["admin_new_alerts"],
    () => dispatch(getCompanyNewAlerts()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const postNewAlertsQuery = useMutation(
    (alerts) => dispatch(addNewAlerts(alerts)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["admin_active_alerts"]);
      },
    }
  );

  useEffect(() => {
    if (postNewAlertsQuery.isSuccess) {
      const timer = setTimeout(() => {
        history.push("/settings/admin/alerts");
        dispatch(addAllAdminAlerts([]));
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [postNewAlertsQuery.isSuccess, history, dispatch]);

  const selectAllAlerts = () => {
    dispatch(addAllAdminAlerts(getNewAlertsQuery.data));
    setIsSelectedAll(true);
  };

  const deselectAllAlerts = () => {
    dispatch(addAllAdminAlerts([]));
    setIsSelectedAll(false);
  };

  const onSubmit = () => postNewAlertsQuery.mutate(newAlerts);

  if (getNewAlertsQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getNewAlertsQuery.isError) {
    return <Alert severity="error">{getNewAlertsQuery.error.message}</Alert>;
  }

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton to="/settings/admin/alerts" />

          <Box className={classes.topRow}>
            <UserTitle title="Add new alert" className={classes.userTitle} />
            {isSelectedAll ? (
              <Button
                className={classes.selectButton}
                size="small"
                variant="outlined"
                color="primary"
                onClick={deselectAllAlerts}
                disabled={getNewAlertsQuery?.data.length === 0}
              >
                Deselect all
              </Button>
            ) : (
              <Button
                className={classes.selectButton}
                size="small"
                variant="outlined"
                color="primary"
                onClick={selectAllAlerts}
                disabled={getNewAlertsQuery?.data.length === 0}
              >
                Select all
              </Button>
            )}
          </Box>
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && (
            <>
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
                separator={<NavigateNext fontSize="small" />}
              >
                <Link
                  className={classes.breadcrumbItemRoot}
                  component={RouterLink}
                  to="/settings/admin/alerts"
                >
                  Alerts
                </Link>
                <Typography className={classes.breadcrumbItem}>
                  Add alert
                </Typography>
              </Breadcrumbs>

              <Box className={classes.topRow}>
                <UserTitle
                  title="Add new alert"
                  className={classes.userTitle}
                />
                {isSelectedAll ? (
                  <Button
                    className={classes.selectButton}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={deselectAllAlerts}
                    disabled={getNewAlertsQuery?.data.length === 0}
                  >
                    Deselect all
                  </Button>
                ) : (
                  <Button
                    className={classes.selectButton}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={selectAllAlerts}
                    disabled={getNewAlertsQuery?.data.length === 0}
                  >
                    Select all
                  </Button>
                )}
              </Box>
            </>
          )}

          {postNewAlertsQuery.isError && (
            <Alert severity="error">{postNewAlertsQuery.error.message}</Alert>
          )}
          {postNewAlertsQuery.isSuccess && (
            <Alert severity="success">Alerts are added</Alert>
          )}

          <Box mb={2}>
            <Typography className={classes.title} component="h2" variant="h5">
              Awards
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Select award alerts you want to add
            </Typography>
            {getNewAlertsQuery?.data.filter(
              (alert) =>
                alert.type.startsWith("good") ||
                alert.type.startsWith("info") ||
                alert.type === "message"
            ).length === 0 && (
              <Alert severity="info">No available award alerts</Alert>
            )}

            {getNewAlertsQuery?.data
              .filter(
                (alert) =>
                  alert.type.startsWith("good") ||
                  alert.type.startsWith("info") ||
                  alert.type === "message"
              )
              .sort((a, b) => a.pretty_name.localeCompare(b.pretty_name))
              .map((alert) => (
                <MuiCheckbox
                  key={alert.type}
                  label={alert.pretty_name}
                  name={alert.type}
                  handleChecked={() => {
                    dispatch(addAdminAlert(alert));
                  }}
                  handleUnChecked={() => {
                    dispatch(removeAdminAlert(alert.type));
                  }}
                  checkedArray={newAlerts.map((alert) => alert.type)}
                />
              ))}
          </Box>

          <Box>
            <Typography className={classes.title} component="h2" variant="h5">
              Operational
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              Select operational alerts you want to add
            </Typography>
            {getNewAlertsQuery?.data.filter(
              (alert) =>
                alert.type.startsWith("ops") || alert.type.startsWith("cust")
            ).length === 0 && (
              <Alert severity="info">No available operational alerts</Alert>
            )}

            {getNewAlertsQuery?.data
              .filter(
                (alert) =>
                  alert.type.startsWith("ops") || alert.type.startsWith("cust")
              )
              .sort((a, b) => a.pretty_name.localeCompare(b.pretty_name))
              .map((alert) => (
                <MuiCheckbox
                  key={alert.type}
                  label={alert.pretty_name}
                  name={alert.type}
                  handleChecked={() => {
                    dispatch(addAdminAlert(alert));
                  }}
                  handleUnChecked={() => {
                    dispatch(removeAdminAlert(alert.type));
                  }}
                  checkedArray={newAlerts.map((alert) => alert.type)}
                />
              ))}
          </Box>

          <MuiButton
            className={classes.submitButton}
            disabled={
              postNewAlertsQuery.isLoading ||
              getNewAlertsQuery?.data.length === 0
            }
            onClick={onSubmit}
          >
            {postNewAlertsQuery.isLoading ? "Adding..." : "Add alerts"}
          </MuiButton>
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminAlertCreateAlert;
