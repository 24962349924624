// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minHeight: 160,
    padding: theme.spacing(2),
    borderRadius: 8,
    backgroundColor: theme.palette.background.main,
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
    },
  },
  title: {
    marginBottom: 5,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: 1.2,
    color: theme.palette.text.main,
  },
  address: {
    marginBottom: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    color: theme.palette.text.grey,
  },
  addressLine: {
    marginRight: theme.spacing(0.5),
  },
  description: {
    marginRight: theme.spacing(0.5),
  },
  button: {
    position: "relative",
    minHeight: 30,
    borderRadius: 6,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.1,
    textTransform: "none",
    color: theme.palette.primary.main,
    backgroundColor: "#E4ECFC",
    border: "1px solid #4B7FEE",
  },
  buttonSubscribed: {
    "&.Mui-disabled": {
      color: theme.palette.green.alert,
      borderColor: theme.palette.green.alert,
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonSubscribedIcon: {
    color: theme.palette.green.alert,
  },
  userCount: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  blacklistedCount: {
    color: theme.palette.red.alert,
  },
}));

export { useStyles };
