// Types
import * as types from "redux/actiontypes/ui-types";

const showSidebar = (bool) => ({ type: types.TOGGLE_SIDEBAR, payload: bool });
const showSnackbar = (bool) => ({ type: types.SHOW_SNACKBAR, payload: bool });
const setAudioPlaying = (bool) => ({
  type: types.AUDIO_IS_PLAYING,
  payload: bool,
});
const setAudioLoop = (bool) => ({ type: types.AUDIO_LOOP, payload: bool });

export { showSidebar, showSnackbar, setAudioPlaying, setAudioLoop };
