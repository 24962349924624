// Core
import { combineReducers } from "redux";

// Reducers
import { authReducer as auth } from "./auth-reducer";
import { feedReducer as feed } from "./feed-reducer";
import { chatReducer as chat } from "./chat-reducer";
import { filtersReducer as filters } from "./filters-reducer";
import { dailyReducer as daily } from "./daily-reducer";
import { storesReducer as stores } from "./stores-reducer";
import { settignsReducer as settings } from "./settings-reducer";
import { adminReducer as admin } from "./admin-reducer";
import { uiReducer as ui } from "./ui-reducer";

const rootReducer = combineReducers({
  auth,
  feed,
  chat,
  filters,
  daily,
  stores,
  settings,
  admin,
  ui,
});

export { rootReducer };
