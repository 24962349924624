export const SEARCH_BY_STORE = "ORDERS/SEARCH_BY_STORE";

export const ADD_FILTER_TAG = "ADD_FILTER_TAG";
export const REMOVE_FILTER_TAG = "REMOVE_FILTER_TAG";

export const ADD_FILTER_STORE = "ADD_FILTER_STORE";
export const REMOVE_FILTER_STORE = "REMOVE_FILTER_STORE";

export const FILTER_ORDERS_BY_PERIOD = "FILTER_ORDERS_BY_PERIOD";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_PAGE_COUNT = "SET_PAGE_COUNT";
export const SET_SORT_BY = "ORDERS/SET_SORT_BY";
