// Core
import React from "react";
import PropTypes from "prop-types";

// Styles
import { useTabPanelStyles } from "./daily-tabs.styles";

const DailyTabPanel = ({ children, value, index, ...other }) => {
  const classes = useTabPanelStyles();

  return (
    <div
      className={classes.panel}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

DailyTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default DailyTabPanel;
