// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  stretch: {
    display: "flex",
    alignItems: "stretch",
  },
  wrap: {
    flexWrap: "wrap",
  },
  alert: {
    position: "relative",
    maxWidth: "350px",
    marginBottom: theme.spacing(2),
    padding: "22px 20px",
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey.border}`,
    boxShadow: "0px 1px 11px rgba(23, 23, 23, 0.0441707)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  border: {
    position: "absolute",
    top: -1,
    left: 0,
    width: "100%",
    height: 8,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.palette.brown.secondary,
  },
  upsellBorder: {
    backgroundColor: theme.palette.brown.secondary,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  avatar: {
    width: 58,
    height: 58,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.brown.light,
  },
  avatarIcon: {
    width: 36,
    height: 40,
  },
  title: {
    marginRight: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
    whiteSpace: "nowrap",
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
    whiteSpace: "nowrap",
  },
  alertMetric: {
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  metricValue: {
    marginLeft: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    borderRadius: "4px",
    fontFamily: theme.typography.bold.fontFamily,
    backgroundColor: theme.palette.yellow.main,
  },
  storeNumber: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  status: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.green.light,
  },
  statusIcon: {
    width: 12,
    height: 9,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.green.light,
  },
  info: {
    marginBottom: theme.spacing(3.5),
  },
  infoItem: {
    marginBottom: theme.spacing(1),
  },
  calendarIcon: {
    width: 12,
    height: 12,
    marginRight: 6,
    fill: theme.palette.text.grey,
  },
  startIcon: {
    width: 12,
    height: 12,
    marginRight: 6,
    fill: theme.palette.text.darkGrey,
  },
  infoText: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.grey,
  },
  button: {
    width: "100%",
    height: 38,
    borderRadius: 8,
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.bold.fontFamily,
    textTransform: "none",
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    whiteSpace: "nowrap",
  },
  statusButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.MuiToggleButton-root": {
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  resolveButton: {
    borderColor: theme.palette.green.light,
    backgroundColor: "transparent",
    color: theme.palette.green.light,
  },
  resolveIcon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.green.light,
  },
  audio: {
    paddingBottom: 80,
  },
  audioCount: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("sm")]: {
      borderTop: `1px solid ${theme.palette.grey.border}`,
    },
  },
  tags: {
    display: "flex",
    flexDirection: "column",
    maxWidth: 600,
    width: "100%",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "auto",
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  tagsCount: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
  },
  tagWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    "&:hover": {
      backgroundColor: theme.palette.grey.light,
    },
  },
  tag: {
    minHeight: 28,
    height: "auto",
    padding: "5px 0",
    marginBottom: theme.spacing(0.5),
    border: "none",
    borderRadius: 6,
    fontSize: theme.typography.pxToRem(16),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
    backgroundColor: theme.palette.yellow.main,
    cursor: "pointer",
    "& .MuiChip-label": {
      whiteSpace: "normal",
      lineHeight: 1.2,
    },
    "&.MuiChip-sizeSmall": {
      height: "auto",
    },
  },
  tagIcon: {
    flex: "0 0 38px",
    width: 38,
    height: 38,
    marginLeft: theme.spacing(1),
  },
  tagTotal: {
    display: "flex",
    alignItems: "center",
  },
  divider: {
    width: 1,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.text.main,
    color: theme.palette.text.main,
  },
  scripts: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  scriptsTitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.main,
  },
  lines: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  line: {
    minHeight: 28,
    padding: "3px 0",
    border: "none",
    borderRadius: 6,
    marginBottom: 6,
    textTransform: "lowercase",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.blue.main,
    fontSize: theme.typography.pxToRem(15),
    fontFamily: theme.typography.regular.fontFamily,

    "& .MuiChip-label": {
      whiteSpace: "normal",
      lineHeight: 1.2,
    },
    "&.MuiChip-sizeSmall": {
      height: "auto",
    },
  },
  timeRow: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(0.5),
  },
  time: {
    fontSize: theme.typography.pxToRem(13),
    fontFamily: theme.typography.regular.fontFamily,
    color: theme.palette.text.grey,
  },
  timeIcon: {
    width: 10,
    height: 10,
    marginRight: theme.spacing(0.5),
    color: theme.palette.text.grey,
  },
}));

export { useStyles };
