// Core
import React, { useEffect, useRef } from "react";
import clsx from "clsx";

// Components
import {
  useMediaQuery,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

// Icons
import { UnfoldMore, ArrowDownward, ArrowUpward } from "@material-ui/icons";

// Styles
import { useStyles } from "./table.styles";

const MuiTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  data,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const tableRef = useRef();

  useEffect(() => {
    if (tableRef && isMobile) {
      tableRef.current.scrollLeft = tableRef.current?.scrollWidth;
    }
  }, [isMobile]);

  return (
    <TableContainer className={classes.tableContainer} ref={tableRef}>
      <Table {...getTableProps()} className={classes.table}>
        <TableHead className={classes.head}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell
                  className={classes.headCell}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined })
                  )}
                >
                  <div
                    className={clsx(
                      classes.headCellRow,
                      column.hideHeadCell ? classes.hiddenHeadCell : ""
                    )}
                  >
                    {!column.hideHeadCell && (
                      <>
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowDownward className={classes.sortIcon} />
                          ) : (
                            <ArrowUpward className={classes.sortIcon} />
                          )
                        ) : (
                          <UnfoldMore
                            className={clsx(
                              classes.sortIcon,
                              classes.defaultIcon
                            )}
                          />
                        )}
                      </>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()} className={classes.tableRow}>
                {row.cells.map((cell) => {
                  return (
                    <TableCell
                      className={classes.bodyCell}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {!data.length ? (
        <Typography
          className={classes.emptySearch}
          align="center"
          color="textSecondary"
          variant="body1"
          component="div"
        >
          No stores found
        </Typography>
      ) : null}
    </TableContainer>
  );
};

export default MuiTable;
