// Core
import React, { useState, useEffect } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { makeStyles } from "@material-ui/core/styles";

// Actions
import { getOrders } from "redux/actions/orders-actions";
import { setPageCount } from "redux/actions/filters-actions";

// Components
import { useMediaQuery } from "@material-ui/core";
import { MuiWrapper, MuiLoader, MuiPagination } from "components/common";
import {
  Catcher,
  OrdersList,
  OrdersFilters,
  OrdersMobileFilters,
} from "components";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  row: {
    minHeight: "70px",
    position: "relative",
  },
}));

const Orders = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { period } = useSelector((state) => state.filters.orders);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [ordersData, setOrdersData] = useState(null);

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (data) => dispatch(getOrders(data)),
    {
      onSuccess: (data) => {
        setOrdersData(data);
        dispatch(setPageCount(Math.ceil(data.total / period.per_page)));
      },
    }
  );

  useDeepCompareEffect(() => {
    mutate({ ...period });
  }, [period, mutate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Catcher>
      <MuiWrapper maxWidth="xl" isMobile={isMobile}>
        {isMobile ? <OrdersMobileFilters /> : <OrdersFilters />}
        <div className={classes.row}>
          {isLoading && <MuiLoader fullpage />}
          {isError && <Alert severity="error">{error.message}</Alert>}
          {isSuccess && <OrdersList orders={ordersData.orders} />}
        </div>
        <MuiPagination />
      </MuiWrapper>
    </Catcher>
  );
};

export default Orders;
