// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  like: {
    height: 26,
    marginRight: theme.spacing(1),
    padding: "0 8px",
    borderRadius: 100,
    backgroundColor: theme.palette.grey.border,
    cursor: "pointer",
  },
  count: {
    fontSize: theme.typography.pxToRem(16),
    marginLeft: theme.spacing(0.5),
  },
  activeLike: {
    backgroundColor: theme.palette.background.blue,
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
}));

export { useStyles };
