// Core
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";

// Components
import { MuiTable } from "components/common";

const MuiTableWrapper = ({ tableData, tableColumns, initialState }) => {
  const columns = useMemo(() => tableColumns, [tableColumns]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable({ columns, data, initialState }, useSortBy);

  return <MuiTable {...tableInstance} />;
};

MuiTableWrapper.propTypes = {
  tableData: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
  initialState: PropTypes.object,
};

export default MuiTableWrapper;
