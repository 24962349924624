// Core
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import io from "socket.io-client";

// Actions
import {
  addNewMessage,
  updateMessage,
  updateLastTimestamp,
  updateUnreadTotalCount,
  updateUnreadAlertCount,
  searchByChannel,
  clearChannel,
} from "redux/actions/chat-actions";

// Components
import { useMediaQuery, Typography } from "@material-ui/core";
import {
  MuiWrapper,
  MuiStaticSidebar,
  MuiBarSpacer,
  MuiSearch,
} from "components/common";
import { Catcher, Chat, Channels } from "components";

// Icons
import { ReactComponent as MealIcon } from "theme/icons/meal.svg";

// Tools
import { addClassToBody, removeClassFromBody } from "utils/helpers";

// Styles
import { useStyles } from "./chat.styles";

const Alerts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [newLike, setNewLike] = useState(null);
  const { channelId } = useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.auth);
  const searchByChannelValue = useSelector(
    (state) => state.chat.searchByChannel
  );

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { mutate: updateTimestampQuery } = useMutation((id) =>
    dispatch(updateLastTimestamp(id))
  );

  useEffect(() => {
    addClassToBody("no-scroll");
    return () => {
      removeClassFromBody("no-scroll");
    };
  }, []);

  useEffect(() => {
    if (newMessage) {
      if (newMessage.channel_id === channelId) {
        dispatch(addNewMessage(newMessage));
        setNewMessage(null);
        updateTimestampQuery(channelId);
      }

      if (newMessage.channel_id !== channelId) {
        if (newMessage.type === "message") {
          dispatch(updateUnreadTotalCount(newMessage.channel_id));
        }
        if (newMessage.type === "alert") {
          dispatch(updateUnreadAlertCount(newMessage.channel_id));
        }
        setNewMessage(null);
      }
    }
  }, [newMessage, channelId, dispatch, updateTimestampQuery]);

  useEffect(() => {
    if (newLike) {
      dispatch(updateMessage(newLike));
      setNewLike(null);
    }
  }, [newLike, dispatch]);

  useEffect(() => {
    if (channelId) {
      updateTimestampQuery(channelId);
    }
  }, [channelId, dispatch, updateTimestampQuery]);

  useEffect(() => {
    if (user && user.id) {
      const socket = io(process.env.RACT_APP_ROOT_API, {
        withCredentials: true,
        query: `user_id=${user.id}`,
      });
      socket.on("new-message", (message) => {
        setNewMessage(message);
      });
      socket.on("new-like", (message) => {
        setNewLike(message);
      });
      setSocket(socket);

      return () => {
        dispatch(clearChannel());
      };
    }
  }, [dispatch, user]);

  return (
    <Catcher>
      <MuiStaticSidebar hideOnMobile>
        <MuiSearch
          className={clsx(classes.container, classes.search)}
          placeholder="Search"
          value={searchByChannelValue}
          onChange={(channel) => dispatch(searchByChannel(channel))}
        />

        <Typography
          variant="h5"
          className={clsx(classes.container, classes.title)}
        >
          <MealIcon className={classes.mealIcon} /> Channels
        </Typography>

        <Channels />
      </MuiStaticSidebar>

      <div className={classes.content}>
        <MuiBarSpacer />
        <MuiWrapper
          className={clsx(classes.root, classes.fullHeight)}
          maxWidth="xl"
          isMobile={isMobile}
        >
          <Chat socket={socket} />
        </MuiWrapper>
      </div>
    </Catcher>
  );
};

export default Alerts;
