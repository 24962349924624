// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cardList: {
    width: "100%",
    paddingTop: 250,
  },
  card: {
    width: "100%",
    marginBottom: theme.spacing(1),
    borderRadius: 8,
  },
  cardContent: {
    padding: 12,
    "&:last-child": {
      paddingBottom: 18,
    },
  },
  storeRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.5),
  },
  storeIcon: {
    width: 15,
    height: 15,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.primary.main,
  },
  storeName: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
  },
  managerWrapper: {
    marginBottom: theme.spacing(1.5),
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  managerRow: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1.5),
  },
  managerIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.text.darkGrey,
  },
  managerName: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.darkGrey,
    whiteSpace: "nowrap",
  },
  addressRow: {
    display: "flex",
    alignItems: "center",
  },
  addressIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.text.darkGrey,
  },
  addressText: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.darkGrey,
    whiteSpace: "nowrap",
  },
  metricRow: {
    display: "flex",
    alignItems: "center",
  },
  metric: {
    minWidth: 150,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  metricValue: {
    marginLeft: theme.spacing(1),
    fontFamily: theme.typography.medium.fontFamily,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    "& .MuiFormGroup-root": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  drawerHeader: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
  },
  drawerRow: {
    marginBottom: 0,
    marginLeft: 0,
  },
  drawerStoreIcon: {
    fill: theme.palette.text.main,
  },
  drawerStoreName: {
    color: theme.palette.text.main,
  },
  drawerAddressIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.text.darkGrey,
  },
  drawerAddressText: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.darkGrey,
  },
  drawerContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  statRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
  },
  statDate: {
    minWidth: 150,
  },
  statDateTotal: {
    fontFamily: theme.typography.bold.fontFamily,
  },
  statValueTotal: {
    fontFamily: theme.typography.bold.fontFamily,
  },
  filters: {
    position: "fixed",
    top: 199,
    zIndex: 99,
    left: 15,
    right: 15,
    width: "auto",
    paddingTop: 16,
    paddingBottom: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.main,
  },
  sort: {
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
  sortTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
    whiteSpace: "nowrap",
  },
  sortValue: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    marginLeft: theme.spacing(1),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  sortIcon: {
    marginLeft: theme.spacing(1),
  },
  totalWeeklyRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    width: "100%",
    padding: "12px 20px",
    borderRadius: 8,
    backgroundColor: "#E4ECFC",
  },
  totalWeeklyTitle: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  totalWeeklyLink: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },
  totalRow: {
    display: "flex",
    aligntItems: "center",
  },
  totalTitle: {
    minWidth: 150,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  totalValue: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
}));

export { useStyles };
