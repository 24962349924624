// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

// Actions
import { setUser } from "redux/actions/auth-actions";
import {
  getUserDetails,
  updateUserDetails,
  showSettingsSidebar,
} from "redux/actions/settings-actions";

// Components
import { Catcher } from "components";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiLoader,
  MuiBackButton,
} from "components/common";
import { Paper, UserTitle } from "components/settings";
import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Tools
import { getFirstLettersFromUserName, formatPhoneNumber } from "utils/helpers";

// Styles
import { useStyles } from "./user-info.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const UserInfo = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useBreakpoints();
  const [userDetails, setUserDetails] = useState(null);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "First name should be at least 2 characters")
      .max(120, "First name should be less than 120 characters")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last name should be at least 2 characters")
      .max(120, "Last name should be less than 120 characters"),
  });

  const getUserQuery = useQuery(
    ["user_details"],
    () => dispatch(getUserDetails()),
    {
      onSuccess: (data) => {
        setUserDetails(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateUserQuery = useMutation(
    (user) => dispatch(updateUserDetails(user)),
    {
      onSuccess: (data) => {
        dispatch(setUser(data));
        setUserDetails(data);
      },
    }
  );

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(true));

  const onSubmit = (values) => {
    const { firstName, lastName } = values;
    updateUserQuery.mutate({
      firstName,
      lastName,
    });
  };

  if (getUserQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getUserQuery.isError) {
    return <Alert severity="error">{getUserQuery.error.message}</Alert>;
  }

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/user-info"
            onClick={handleBackButtonClick}
          />
          <UserTitle title="My profile" />
        </div>
      )}

      {userDetails && (
        <div className={clsx(isMobile && globalClasses.container)}>
          <Paper>
            {isDesktop && <UserTitle title="My profile" />}

            <div className={classes.userCircle}>
              {getFirstLettersFromUserName(userDetails)}
            </div>
            <div className={classes.userInfo}>
              {userDetails.email && (
                <Typography className={classes.email} variant="body1">
                  {userDetails.email}
                </Typography>
              )}
              {userDetails.phone_number && (
                <Typography className={classes.phone} variant="body1">
                  {formatPhoneNumber(userDetails.phone_number)}
                </Typography>
              )}
            </div>

            <MuiForm
              noContainer={isDesktop}
              centered={isMobile}
              className={classes.formWrapper}
            >
              {updateUserQuery.isError && (
                <Alert severity="error">{updateUserQuery.error.message}</Alert>
              )}
              {updateUserQuery.isSuccess && (
                <Alert severity="success">Successfully changed</Alert>
              )}

              <Formik
                enableReinitialize
                initialValues={{
                  firstName: userDetails.first_name || "",
                  lastName: userDetails.last_name || "",
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <Form className={classes.form} noValidate autoComplete="off">
                    <MuiInput
                      type="text"
                      name="firstName"
                      label="First name"
                      required
                    />
                    <MuiInput type="text" name="lastName" label="Last name" />
                    <MuiButton
                      disabled={updateUserQuery.isLoading || !props.dirty}
                      className={classes.button}
                    >
                      {updateUserQuery.isLoading ? (
                        <MuiLoader />
                      ) : (
                        "Save changes"
                      )}
                    </MuiButton>
                  </Form>
                )}
              </Formik>
            </MuiForm>
          </Paper>
        </div>
      )}
    </Catcher>
  );
};

export default UserInfo;
