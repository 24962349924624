// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  table: {
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    letterSpacing: "0.15px",
  },
  headTitle: {
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  headTitleRank: {
    marginRight: 6,
  },
  storesAverageTitle: {
    minWidth: 60,
  },
  headRow: {},
  headCell: {
    minWidth: 80,
    paddingBottom: theme.spacing(2),
    "&:first-child": {
      paddingLeft: 0,
      position: "sticky",
      left: 0,
      zIndex: 99,
      borderBottom: `1px solid ${theme.palette.grey.border}`,
      backgroundColor: theme.palette.common.white,
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  bodyRow: {
    "&:first-child": {
      "& .MuiTableCell-body": {
        paddingTop: theme.spacing(2),
      },
    },
    "&:last-child": {
      "& .MuiTableCell-body": {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  bodyCell: {
    borderBottom: 0,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.main,
    "&:first-child": {
      paddingLeft: 0,
      position: "sticky",
      left: 0,
      zIndex: 99,
      backgroundColor: theme.palette.common.white,
    },
    "&:last-child": {
      paddingRight: 0,
    },
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

export { useStyles };
