// Core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";

// Actions
import {
  setChannelId,
  clearUnreadCount,
  showChannelsSidebar,
  setReplyTo,
  clearChannel,
} from "redux/actions/chat-actions";

// Components
import { ListItem, ListItemText, Typography } from "@material-ui/core";

// Icons
import { ReactComponent as AddressIcon } from "theme/icons/address.svg";
import { ReactComponent as GroupIcon } from "theme/icons/group.svg";
import { ReactComponent as ManagerIcon } from "theme/icons/user.svg";

// Styles
import { useStyles } from "./channel.styles";

const Channel = ({ channel: { id, name, store, description } }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { channelId, unreadMessageCount } = useSelector((state) => state.chat);

  const alertsCounter = unreadMessageCount[id].alerts;
  const totalCounter = unreadMessageCount[id].total;

  useEffect(() => {
    if (channelId === id) {
      dispatch(clearUnreadCount(id));
    }
  }, [dispatch, channelId, id]);

  const handleChannelClick = (id) => {
    if (channelId === id) {
      return;
    }
    dispatch(clearChannel());
    dispatch(setChannelId(id));
    dispatch(setReplyTo(null));
    dispatch(showChannelsSidebar(false));
    localStorage.setItem("channelId", id);
    history.push(`/chat`);
  };

  return (
    <ListItem
      className={clsx(
        classes.channel,
        channelId === id ? classes.activeChannel : ""
      )}
      onClick={() => handleChannelClick(id)}
    >
      <ListItemText
        primary={
          <div className={classes.row}>
            <Typography variant="subtitle2" className={classes.title}>
              {name}
            </Typography>

            <div className={classes.counterWrapper}>
              {alertsCounter > 0 && (
                <span className={clsx(classes.counter, classes.alerts)}>
                  {alertsCounter > 99 ? "99 +" : alertsCounter}
                </span>
              )}
              {totalCounter > 0 && (
                <span className={clsx(classes.counter, classes.total)}>
                  {totalCounter > 99 ? "99 +" : totalCounter}
                </span>
              )}
            </div>
          </div>
        }
        secondary={
          <>
            {store && (
              <span className={classes.secondary}>
                <AddressIcon className={classes.secondaryIcon} />
                {store.city}, {store.state}
              </span>
            )}
            {store && store.manager_district_name && (
              <span className={classes.secondary}>
                <ManagerIcon className={classes.personIcon} />
                {store.manager_district_name}
              </span>
            )}
            {!store && (
              <span className={classes.secondary}>
                <GroupIcon className={classes.secondaryIcon} />
                {description}
              </span>
            )}
          </>
        }
      />
    </ListItem>
  );
};

export default Channel;
