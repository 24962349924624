// Core
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { setCurrentPage, setSortBy } from "redux/actions/filters-actions";

// Components
import {
  Typography,
  Drawer,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@material-ui/core";

// Icons
import { ExpandMore } from "@material-ui/icons";

// Tools
import { capitalizeString } from "utils/helpers";

// Styles
import { useStyles } from "./orders-sortby.styles";

const OrdersMobileSortBy = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("newest");
  const { sortBy } = useSelector((state) => state.filters.orders.period);

  const handleChange = (event) => setValue(event.target.value);

  const handleSubmit = () => {
    dispatch(setCurrentPage(1));
    dispatch(setSortBy(value));
    setIsOpen(false);
  };

  const handleCancel = () => setIsOpen(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  return (
    <>
      <Typography
        variant="caption"
        color="textPrimary"
        className={classes.title}
        onClick={toggleDrawer(true)}
      >
        Sort by: {capitalizeString(sortBy)}
        <ExpandMore
          className={clsx(classes.expandIcon, isOpen ? classes.active : "")}
        />
      </Typography>

      <Drawer
        className={classes.drawer}
        anchor="bottom"
        open={isOpen}
        onClose={toggleDrawer(false)}
      >
        <Typography
          variant="h4"
          color="textPrimary"
          className={classes.drawerTitle}
        >
          Sort By
        </Typography>

        <RadioGroup
          className={classes.radioGroup}
          aria-label="sortby"
          name="sortby"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="newest"
            control={<Radio color="primary" />}
            label="Newest"
          />
          <FormControlLabel
            value="oldest"
            control={<Radio color="primary" />}
            label="Oldest"
          />
          <FormControlLabel
            value="longest"
            control={<Radio color="primary" />}
            label="Longest"
          />
          <FormControlLabel
            value="shortest"
            control={<Radio color="primary" />}
            label="Shortest"
          />
        </RadioGroup>

        <div className={classes.buttonGroup}>
          <Button
            className={clsx(classes.button, classes.submitButton)}
            variant="contained"
            color="inherit"
            size="medium"
            onClick={handleSubmit}
          >
            Apply
          </Button>
          <Button
            className={clsx(classes.button, classes.cancelButton)}
            variant="outlined"
            color="inherit"
            size="medium"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default OrdersMobileSortBy;
