// Core
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import WaveSurfer from "wavesurfer.js";
import clsx from "clsx";
import copy from "copy-to-clipboard";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";

// Actions
import { shareOrder } from "redux/actions/orders-actions";
import { setAudioPlaying, showSnackbar } from "redux/actions/ui-actions";

// Components
import {
  Slider,
  Typography,
  IconButton,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { MuiSnackbar } from "components/common";

// Icons
import { ReactComponent as PrevIcon } from "theme/icons/prev.svg";
import { ReactComponent as NextIcon } from "theme/icons/next.svg";
import { ReactComponent as PlayIcon } from "theme/icons/play.svg";
import { ReactComponent as PauseIcon } from "theme/icons/pause.svg";
import { ReactComponent as VolumeMiniIcon } from "theme/icons/volume-mini.svg";
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";
import { ReactComponent as ShareIcon } from "theme/icons/share.svg";

// Tools
import { secToMinutes, getDateFromTimestamp } from "utils/helpers";

// Styles
import { useStyles } from "./waveplayer-mini.styles";

const waveSurferOptions = (ref) => ({
  container: ref,
  mediaControls: false,
  waveColor: "#d1d1d1",
  progressColor: "#4677df",
  cursorColor: "#4677df",
  barWidth: 3,
  barMinHeight: 8,
  barRadius: 3,
  responsive: true,
  height: 30,
  normalize: true,
  audioRate: 1,
});

const WavePlayerMini = ({ url, nextAudio, prevAudio, order }) => {
  const classes = useStyles();
  const wavePlayerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const dispatch = useDispatch();
  const playing = useSelector((state) => state.ui.audioIsPlaying);
  const [volume, setVolume] = useState(0.5);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const shareOrderQuery = useMutation((id) => dispatch(shareOrder(id)), {
    onSuccess: (data) => {
      copy(`${window.location.origin}/audios/shared/${data?.key}`, {
        message: "Press ⌘+C to copy",
      });
      dispatch(showSnackbar(true));
    },
  });

  const updateTime = (ref) => {
    const totalTime = ref.getDuration();
    const currentTime = ref.getCurrentTime();
    setTotalTime(secToMinutes(totalTime));
    setCurrentTime(secToMinutes(currentTime));
  };

  useEffect(() => {
    const options = waveSurferOptions(wavePlayerRef.current);

    waveSurferRef.current = WaveSurfer.create({
      ...options,
      url,
    });

    const wsRegions = waveSurferRef.current.registerPlugin(
      RegionsPlugin.create()
    );
    waveSurferRef.current.on("decode", () => {
      order.pretty_tags
        .filter((tag) => tag.type === "ops")
        .map((tag) => {
          return wsRegions.addRegion({
            start: tag.offset[0],
            end: tag.offset[1],
            color: "rgba(194, 255, 241, 0.6)",
            content: tag.pretty_name,
            drag: false,
            resize: false,
          });
        });
    });
    wsRegions.on("region-clicked", (region, e) => {
      e.stopPropagation();
      region.play();
      dispatch(setAudioPlaying(true));
    });

    waveSurferRef.current.on("ready", function () {
      updateTime(waveSurferRef.current);
      if (order.audio_offset) {
        waveSurferRef.current.play(order.audio_offset);
      }
      waveSurferRef.current.play();
    });

    waveSurferRef.current.on("finish", function () {
      dispatch(setAudioPlaying(false));
      nextAudio();
    });

    waveSurferRef.current.on("seek", function () {
      updateTime(waveSurferRef.current);
    });

    waveSurferRef.current.on("audioprocess", function () {
      updateTime(waveSurferRef.current);
    });

    return () => {
      if (waveSurferRef?.current) {
        waveSurferRef.current.destroy();
      }
      dispatch(setAudioPlaying(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, dispatch]);

  useEffect(() => {
    setVolume(volume);
    waveSurferRef.current.setVolume(volume);
  }, [volume]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (playing) {
        waveSurferRef?.current.play();
      } else {
        waveSurferRef?.current.pause();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [playing]);

  const handlePlay = () => {
    if (!playing) {
      dispatch(setAudioPlaying(true));
      waveSurferRef.current.play();
    }
  };

  const handlePause = () => {
    if (playing) {
      dispatch(setAudioPlaying(false));
      waveSurferRef.current.pause();
    }
  };

  const handleSliderChange = (event, newValue) => {
    const newVolume = Number(newValue);
    if (newVolume) {
      setVolume(newVolume);
      waveSurferRef.current.setVolume(newVolume || 1);
    }
  };

  const handleCopyText = () => {
    shareOrderQuery.mutate(order.id);
  };

  return (
    <section className={classes.root}>
      <div className={classes.controls}>
        <div className={classes.row}>
          {!isMobile && (
            <IconButton
              className={classes.iconButton}
              onClick={prevAudio}
              aria-label="previous"
              disableFocusRipple
              disableRipple
            >
              <PrevIcon className={classes.prevIcon} />
            </IconButton>
          )}
          {!playing ? (
            <IconButton
              className={classes.iconButton}
              onClick={handlePlay}
              aria-label="play"
              disableFocusRipple
              disableRipple
            >
              <PlayIcon className={classes.playIcon} />
            </IconButton>
          ) : (
            <IconButton
              className={classes.iconButton}
              onClick={handlePause}
              aria-label="pause"
              disableFocusRipple
              disableRipple
            >
              <PauseIcon className={classes.pauseIcon} />
            </IconButton>
          )}
          {!isMobile && (
            <IconButton
              className={classes.iconButton}
              onClick={nextAudio}
              aria-label="next"
              disableFocusRipple
              disableRipple
            >
              <NextIcon className={classes.nextIcon} />
            </IconButton>
          )}
        </div>
      </div>

      {!isMobile && (
        <div className={clsx(classes.row, classes.wavesWrapper)}>
          <Typography
            className={classes.currentTime}
            variant="caption"
            color="textPrimary"
          >
            {currentTime}
          </Typography>
          <div ref={wavePlayerRef} className={classes.waves} />
          <Typography
            className={classes.totalTime}
            variant="caption"
            color="textPrimary"
          >
            {totalTime}
          </Typography>
        </div>
      )}

      {!isMobile && (
        <div className={clsx(classes.row, classes.volumeWrapper)}>
          <IconButton
            className={classes.iconButton}
            aria-label="volume"
            disableFocusRipple
            disableRipple
          >
            <VolumeMiniIcon className={classes.volumeIcon} />
          </IconButton>
          <Slider
            className={classes.volumeSlider}
            min={0.01}
            max={1}
            step={0.025}
            defaultValue={volume}
            value={typeof volume === "number" ? volume : 0}
            onChange={handleSliderChange}
            aria-labelledby="volume-slider"
          />
        </div>
      )}

      <div className={clsx(classes.row, classes.metaRow)}>
        <div className={clsx(classes.column, classes.meta)}>
          <Typography className={classes.title} variant="h5" color="inherit">
            {order.store_name}
          </Typography>
          <div className={classes.row}>
            <CalendarIcon className={classes.calendarIcon} />
            <span className={classes.date}>
              {getDateFromTimestamp(order.timestamp_local)}
            </span>
          </div>
        </div>
        <Box onClick={handleCopyText}>
          <div className={classes.row}>
            <ShareIcon className={classes.shareIcon} />
            <Typography
              className={classes.shareText}
              variant="caption"
              color="inherit"
            >
              Share
            </Typography>
          </div>
        </Box>
        <MuiSnackbar
          message={`Link copied for "Restaurant ${order.store_name}"`}
        />
      </div>
    </section>
  );
};

export default WavePlayerMini;
