// Types
import * as types from "redux/actiontypes/settings-types";

const initialState = {
  showSettingsSidebar: true,
};

const settignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_SETTINGS_SIDEBAR:
      return {
        ...state,
        showSettingsSidebar: action.payload,
      };
    default:
      return state;
  }
};

export { settignsReducer };
