// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  topFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(2.5),
    padding: "0 16px",
  },
  bottomFilters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    borderTop: `1px solid ${theme.palette.grey.border}`,
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.common.white,
    paddingRight: theme.spacing(1.3),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export { useStyles };
