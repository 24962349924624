// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    padding: 0,
  },
  userTitle: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  userInfo: {
    marginBottom: theme.spacing(2),
  },
  email: {
    marginBottom: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(16),
    },
  },
  phone: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: theme.typography.pxToRem(14),
    },
  },
  userCircle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: 140,
    height: 140,
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(50),
    backgroundColor: "rgba(29, 69, 149, 0.25)",
    color: theme.palette.text.grey,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  button: {
    maxWidth: 180,
    whiteSpace: "nowrap",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  form: {
    width: "100%",
  },
}));

export { useStyles };
