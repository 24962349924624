// Core
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Actions
import {
  getChannelDetails,
  updateChannelDetails,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiInput, MuiButton, MuiLoader } from "components/common";
import { ChannelCardTitle, ChannelCardAddress } from "components/settings";
import { Alert } from "@material-ui/lab";

// Styles
import { useStyles } from "./channel-info.styles";

const AdminChannelInfo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [channelDetails, setChannelDetails] = useState(null);

  const validationSchema = Yup.object({
    districtManagerName: Yup.string()
      .min(2, "Name should be at least 2 characters")
      .max(120, "Name should be less than 120 characters"),
    storeManagerName: Yup.string()
      .min(2, "Name should be at least 2 characters")
      .max(120, "Name should be less than 120 characters"),
  });

  const getChannelQuery = useQuery(
    ["admin_channel_info", id],
    () => dispatch(getChannelDetails(id)),
    {
      onSuccess: (data) => {
        setChannelDetails(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateChannelQuery = useMutation(
    (channel) => dispatch(updateChannelDetails(id, channel)),
    {
      onSuccess: (data) => {
        setChannelDetails(data);
      },
    }
  );

  const onSubmit = (values) => {
    const { districtManagerName, storeManagerName } = values;
    updateChannelQuery.mutate({
      districtManagerName,
      storeManagerName,
    });
  };

  if (getChannelQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getChannelQuery.isError) {
    return <Alert severity="error">{getChannelQuery.error.message}</Alert>;
  }

  return (
    <Catcher>
      {channelDetails && (
        <div className={classes.wrapper}>
          <ChannelCardTitle title={channelDetails.name} />
          <ChannelCardAddress
            addressLine={channelDetails.store?.address_line}
            state={channelDetails.store?.state}
            city={channelDetails.store?.city}
            description={channelDetails.description}
          />
          {channelDetails.store && (
            <div className={classes.formWrapper}>
              {updateChannelQuery.isError && (
                <Alert severity="error">
                  {updateChannelQuery.error.message}
                </Alert>
              )}
              {updateChannelQuery.isSuccess && (
                <Alert severity="success">Successfully changed</Alert>
              )}

              <Formik
                enableReinitialize
                initialValues={{
                  districtManagerName:
                    channelDetails.store.manager_district_name || "",
                  storeManagerName:
                    channelDetails.store.manager_store_name || "",
                }}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(props) => (
                  <Form className={classes.form} noValidate autoComplete="off">
                    <MuiInput
                      type="text"
                      name="districtManagerName"
                      label="District manager"
                    />
                    <MuiInput
                      type="text"
                      name="storeManagerName"
                      label="General manager"
                    />
                    <MuiButton
                      disabled={updateChannelQuery.isLoading || !props.dirty}
                      className={classes.button}
                    >
                      {updateChannelQuery.isLoading ? (
                        <MuiLoader />
                      ) : (
                        "Save changes"
                      )}
                    </MuiButton>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    </Catcher>
  );
};

export default AdminChannelInfo;
