export const SEARCH_BY_COMPANY_USERS = "SEARCH_BY_COMPANY_USERS";
export const SEARCH_BY_USER_CHANNELS = "SEARCH_BY_USER_CHANNELS";
export const SET_ACTIVE_USER_DETAILS_TAB = "SET_ACTIVE_USER_DETAILS_TAB";
export const SET_ACTIVE_CHANNEL_DETAILS_TAB = "SET_ACTIVE_CHANNEL_DETAILS_TAB";
export const SEARCH_BY_CHANNELS = "SEARCH_BY_CHANNELS";
export const SEARCH_USER_IN_CHANNEL = "SEARCH_USER_IN_CHANNEL";
export const SEARCH_BY_ALERTS = "SEARCH_BY_ALERTS";
export const ADD_ADMIN_ALERT = "ADD_ADMIN_ALERT";
export const ADD_ALL_ADMIN_ALERTS = "ADD_ALL_ADMIN_ALERTS";
export const REMOVE_ADMIN_ALERT = "REMOVE_ADMIN_ALERT";
