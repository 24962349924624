// Core
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import { Link as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import copy from "copy-to-clipboard";

// Actions
import { getOrderAudio, shareOrder } from "redux/actions/orders-actions";
import { showSnackbar, setAudioPlaying } from "redux/actions/ui-actions";

// Components
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  Typography,
  Link,
  Paper,
  Hidden,
  Box,
} from "@material-ui/core";
import { MuiLoader, MuiSnackbar } from "components/common";
import { WavePlayerMini } from "components";

// Icons
import { PlayCircleFilled, Pause } from "@material-ui/icons";
import { ReactComponent as ShareIcon } from "theme/icons/share.svg";
import { ReactComponent as DurationIcon } from "theme/icons/duration.svg";
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";

// Utils
import { secToMinutes, getDateFromTimestamp } from "utils/helpers";

// Styles
import { useStyles } from "./orders-list.styles";

const OrdersList = ({ orders }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const playing = useSelector((state) => state.ui.audioIsPlaying);
  const { user } = useSelector((state) => state.auth);
  const [state, setState] = useState({
    orderID: null,
    url: null,
  });
  const [copyState, setCopyState] = useState({
    orderID: null,
  });

  const { isLoading, mutate } = useMutation(
    (id) => dispatch(getOrderAudio(id)),
    {
      onSuccess: (data) => {
        setState({
          ...state,
          orderID: data.id,
          url: `/api/orders/wav/${data.stream_id}/${data.id}`,
        });
        dispatch(setAudioPlaying(true));
      },
    }
  );

  const shareOrderQuery = useMutation((id) => dispatch(shareOrder(id)), {
    onSuccess: (data) => {
      copy(`${window.location.origin}/audios/shared/${data?.key}`, {
        message: "Press ⌘+C to copy",
      });
      dispatch(showSnackbar(true));
    },
  });

  const getTagClassName = (type) => {
    if (type === "ops") return classes.tagOps;
    if (type === "upsell") return classes.tagUpsell;
    if (type === "product") return classes.tagProduct;
    return "";
  };

  const handleCopyText = (orderID) => {
    setCopyState({ ...state, orderID });
    shareOrderQuery.mutate(orderID);
  };

  const handlePlay = (orderID) => {
    if (playing) {
      dispatch(setAudioPlaying(false));
    }
    if (orderID) {
      if (orderID !== state.orderID) {
        setState({ ...state, orderID, url: null });
        mutate(orderID);
      } else {
        setState({ ...state, orderID });
        if (!playing) {
          dispatch(setAudioPlaying(true));
        }
      }
    }
  };

  const handlePause = () => dispatch(setAudioPlaying(false));

  const handleNext = () => {
    const currentAudioIndex = orders.findIndex(
      (order) => order.id === state.orderID
    );
    const nextAudioID = orders[currentAudioIndex + 1]?.id;

    if (nextAudioID) {
      handlePlay(nextAudioID);
    }
  };

  const handlePrev = () => {
    const currentAudioIndex = orders.findIndex(
      (order) => order.id === state.orderID
    );
    const prevAudioID = orders[currentAudioIndex - 1]?.id;

    if (prevAudioID) {
      handlePlay(prevAudioID);
    }
  };

  if (!orders.length) {
    return (
      <Paper className={classes.emptySearch}>
        <Typography
          align="center"
          color="textSecondary"
          variant="body1"
          component="div"
        >
          No stores found
        </Typography>
      </Paper>
    );
  }

  return (
    <>
      <List className={classes.list}>
        {orders &&
          orders.map((order) => (
            <div className={classes.itemWrapper} key={order.id}>
              <ListItem
                className={classes.item}
                selected={state.orderID === order.id}
              >
                <ListItemIcon className={classes.icon}>
                  {state.orderID === order.id && playing ? (
                    <>
                      {isLoading && !state.url ? (
                        <MuiLoader fullpage />
                      ) : (
                        <Pause
                          fontSize="large"
                          color="primary"
                          onClick={handlePause}
                        />
                      )}
                    </>
                  ) : (
                    <PlayCircleFilled
                      fontSize="large"
                      color="primary"
                      onClick={() => handlePlay(order.id)}
                    />
                  )}
                </ListItemIcon>
                <Link
                  className={classes.link}
                  component={RouterLink}
                  to={{
                    pathname: `/audios/${order.id}`,
                    state: { from: location.pathname },
                  }}
                  color="inherit"
                  underline="none"
                >
                  <ListItemText
                    className={classes.itemText}
                    primary={`Restaurant #${order.store_name}`}
                    secondary={
                      <>
                        <span className={classes.row}>
                          <CalendarIcon className={classes.calendarIcon} />
                          <span className={classes.date}>
                            {getDateFromTimestamp(order.timestamp_local)}
                          </span>
                          <span>
                            {user?.is_outloud && `${order.stream_id}`}
                          </span>
                        </span>
                      </>
                    }
                  />

                  <Hidden smDown>
                    <div className={classes.tags}>
                      {[
                        ...new Map(
                          order.pretty_tags.map((tag) => [tag["name"], tag])
                        ).values(),
                      ].map((tag, index) => (
                        <Chip
                          key={index}
                          className={clsx(
                            classes.tag,
                            getTagClassName(tag.type)
                          )}
                          variant="outlined"
                          size="small"
                          label={tag.pretty_name}
                        />
                      ))}
                    </div>
                  </Hidden>

                  <div className={classes.duration}>
                    <DurationIcon className={classes.durationIcon} />
                    <Typography
                      className={classes.durationText}
                      variant="caption"
                      color="inherit"
                    >
                      {secToMinutes(order.duration)} min
                    </Typography>
                  </div>
                </Link>
                <div className={classes.shareRow}>
                  <div className={classes.shareItem}>
                    <Box onClick={() => handleCopyText(order.id)}>
                      <div className={classes.row}>
                        <ShareIcon className={classes.shareIcon} />
                        <Typography variant="caption" color="inherit">
                          Share
                        </Typography>
                      </div>
                    </Box>
                    {order.id === copyState.orderID && (
                      <MuiSnackbar
                        message={`Link copied for "Restaurant ${order.store_name}"`}
                      />
                    )}
                  </div>
                </div>
              </ListItem>
            </div>
          ))}
      </List>

      <div className={clsx(classes.player, state.url ? classes.isReady : "")}>
        {state.url && (
          <WavePlayerMini
            url={state.url}
            nextAudio={handleNext}
            prevAudio={handlePrev}
            order={orders.find((order) => order.id === state.orderID)}
          />
        )}
      </div>
    </>
  );
};

export default OrdersList;
