// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  search: {
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  cardLink: {
    width: "100%",
  },
  countWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: theme.spacing(1),
  },
}));

export { useStyles };
