// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  breadcrumbItemRoot: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  breadcrumbItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

export { useStyles };
