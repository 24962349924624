// Core
import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { createMessage, setReplyTo } from "redux/actions/chat-actions";

// Components
import {
  TextField,
  Typography,
  InputAdornment,
  Avatar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ChatInputAlertReply } from "components";
import { Alert } from "@material-ui/lab";

// Icons
import { ReactComponent as ClearIcon } from "theme/icons/cross.svg";
import { ReactComponent as AttachFileIcon } from "theme/icons/attach-file.svg";
import { ReactComponent as SendIcon } from "theme/icons/send.svg";

// Styles
import { useStyles } from "./chat-input.styles";

// Tools
import { getFirstLettersFromUserName } from "utils/helpers";

const ChatInput = ({ socket }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputEl = useRef(null);
  const { channelId, reply, participants } = useSelector((state) => state.chat);
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [newParticipants, addNewParticipant] = useState([]);

  const { error, isError, mutate } = useMutation(
    (data) => dispatch(createMessage(data)),
    {
      onSuccess: (newMessage) => {
        socket.emit("message", newMessage);
      },
    }
  );

  useEffect(() => {
    if (reply) {
      inputEl.current.focus();
    }
  }, [reply]);

  const sendMessage = (e) => {
    e.preventDefault();

    if (!message.trim().length) return inputEl.current.focus();

    let mentions = [];
    if (message.includes("@here")) {
      mentions.push({ name: "@here", user_id: null });
    }
    if (message.includes("@")) {
      participants.forEach(({ user, user_id }) => {
        const fullUserName = `${user.first_name} ${user.last_name}`;
        if (message.includes(fullUserName)) {
          mentions.push({ name: fullUserName, user_id });
        }
      });
    }

    if (reply) {
      mutate({
        channelId,
        text: message,
        replyTo: reply.id,
        mentions,
      });
      dispatch(setReplyTo(null));
    } else {
      mutate({
        channelId,
        text: message,
        mentions,
      });
    }

    setMessage("");
    addNewParticipant([]);
    inputEl.current.focus();
  };

  const handleRemoveReply = () => {
    dispatch(setReplyTo(null));
  };

  const handleInputFocus = () => {};

  const handleParticipantsMenuOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleParticipantsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, index) => {
    const tagAll = e?.target?.textContent;
    if (tagAll === "Notify everyone") {
      setMessage(`${message}here`);
    } else {
      const participant = participants[index];
      setMessage(
        `${message}${participant?.user?.first_name} ${participant?.user?.last_name}`
      );

      addNewParticipant([...newParticipants, index]);
    }
    handleParticipantsMenuClose();
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
    if (e.key === "@") {
      setMessage(`${message}@`);
      handleParticipantsMenuOpen(e);
    }
  };

  return (
    <form noValidate autoComplete="off" className={classes.chatForm}>
      {isError && <Alert severity="error">{error.message}</Alert>}

      {reply && reply.type === "message" && (
        <div className={classes.reply}>
          <div className={classes.wrapper}>
            <Avatar className={classes.avatar}>
              {getFirstLettersFromUserName(reply.user)}
            </Avatar>
            <div className={classes.replyDescription}>
              <Typography variant="h6" className={classes.replyTitle}>
                Reply to{" "}
                <span className={classes.replyTo}>
                  {reply.user.first_name} {reply.user.last_name}
                </span>
              </Typography>
              <span className={classes.replyText}>{reply.text}</span>
            </div>
          </div>
          <ClearIcon
            className={classes.clearIcon}
            onClick={handleRemoveReply}
          />
        </div>
      )}

      {reply && reply.type === "alert" && (
        <ChatInputAlertReply reply={reply} onRemoveReply={handleRemoveReply} />
      )}

      {participants && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleParticipantsMenuClose}
          className={classes.participantsMenu}
        >
          <MenuItem
            disabled={message.includes("@here")}
            onClick={(event) => handleMenuItemClick(event)}
          >
            Notify everyone
          </MenuItem>
          {participants.map((item, index) => (
            <MenuItem
              key={item.user_id}
              disabled={message.includes(
                `@${item.user.first_name} ${item.user.last_name}`
              )}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {item.user.first_name} {item.user.last_name}
            </MenuItem>
          ))}
        </Menu>
      )}

      <TextField
        className={classes.input}
        inputRef={inputEl}
        placeholder="Type message here"
        variant="outlined"
        value={message}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              className={classes.send}
              onClick={(e) => sendMessage(e)}
            >
              <SendIcon
                className={clsx(
                  classes.sendIcon,
                  !message ? classes.disabled : ""
                )}
              />
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="start" className={classes.attach}>
              <AttachFileIcon className={classes.attachIcon} />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </form>
  );
};

export default ChatInput;
