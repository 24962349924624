// Core
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toBlob } from "html-to-image";
import clsx from "clsx";

// Constants
import { GOOD_ALERT_ICONS } from "utils/constants";

// Components
import { ChatLike, ChatReply } from "components";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Link,
  Box,
} from "@material-ui/core";

// Icons
import { ReactComponent as PositiveAlertIcon } from "theme/icons/positive-alert.svg";
import { ReactComponent as ManagerIcon } from "theme/icons/user.svg";
import { FileCopy } from "@material-ui/icons";

// Styles
import { useStyles } from "./chat-alert-good.styles";

// Tools
import { getAmpmFromTimestamp, formatRate } from "utils/helpers";

const ChatAlertGood = ({ message, alert, socket, isFeed }) => {
  const classes = useStyles();
  const history = useHistory();
  const copyImageRef = useRef(null);
  const [isCopied, setIsCopied] = useState(false);

  const handleDetailsClick = () => {
    if (isFeed) {
      history.push(`/alert-good/${alert.id}`, { from: "feed" });
    } else {
      history.push(`/alert-good/${alert.id}`, { from: "chat" });
    }
  };

  const getImageBlob = async () => {
    const imgBlob = await toBlob(copyImageRef.current);
    return imgBlob;
  };

  const handleCopy = async () => {
    if (copyImageRef.current === null) {
      return;
    }
    if (!navigator.clipboard) {
      console.error("Clipboard API not available");
      return;
    }

    try {
      await navigator.clipboard.write([
        new ClipboardItem({
          "image/png": getImageBlob(),
        }),
      ]);
      setIsCopied(true);
    } catch (err) {
      console.error(err);
      setIsCopied(false);
    }
  };

  return (
    <ListItem className={classes.wrapper} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar className={classes.avatar}>
          <PositiveAlertIcon className={classes.avatarIcon} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div className={classes.primary}>
            <div className={clsx(classes.row, classes.titleWrapper)}>
              <Typography variant="h5" className={classes.title}>
                Congrats #{message?.alert?.store?.store_name}
              </Typography>
              <span className={classes.time}>
                {getAmpmFromTimestamp(message.timestamp)}
              </span>
            </div>

            <div ref={copyImageRef} className={classes.alert}>
              <div className={classes.line}></div>

              <section className={clsx(classes.row, classes.alertTitleWrapper)}>
                <div className={classes.alertIconWrapper}>
                  {GOOD_ALERT_ICONS[alert.type]}
                </div>
                <div>
                  <Typography variant="h5" className={classes.alertTitle}>
                    {alert.description}
                  </Typography>
                  {alert?.metric && !alert.type.endsWith("_streak") && (
                    <Typography variant="body1" className={classes.alertMetric}>
                      <span className={classes.metricValue}>
                        {formatRate(alert.metric)}
                      </span>{" "}
                      of orders today
                    </Typography>
                  )}
                  {alert?.metric && alert.type.endsWith("_streak") && (
                    <Typography variant="body1" className={classes.alertMetric}>
                      for{" "}
                      <span className={classes.metricValue}>{`${Math.floor(
                        alert.metric
                      )}${alert.metric >= 10 ? "+" : ""}`}</span>{" "}
                      days in a row
                    </Typography>
                  )}
                  {alert?.manager_name && (
                    <Box className={classes.manager}>
                      <ManagerIcon className={classes.managerIcon} />
                      <Typography
                        variant="body1"
                        className={classes.managerName}
                      >
                        {alert.manager_name}
                      </Typography>
                    </Box>
                  )}
                </div>
              </section>

              <Typography variant="body1" className={classes.alertText}>
                {alert?.info?.blurb}
              </Typography>

              <Link
                className={classes.alertLink}
                onClick={handleDetailsClick}
                color="inherit"
                underline="none"
              >
                Alert details
              </Link>
            </div>
          </div>
        }
        secondary={
          <span className={classes.row}>
            {!isFeed && <ChatLike message={message} socket={socket} />}
            <ChatReply message={message} isFeed={isFeed} />
            <span
              onClick={handleCopy}
              className={clsx(classes.row, classes.copy)}
            >
              <FileCopy className={classes.copyIcon} fontSize="small" />
              <span className={classes.copyText}>
                {isCopied ? "Copied" : "Copy"}
              </span>
            </span>
          </span>
        }
      />
    </ListItem>
  );
};

export default ChatAlertGood;
