// Core
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { Typography, Avatar } from "@material-ui/core";

// Icons
import { ReactComponent as ClearIcon } from "theme/icons/cross.svg";
import { ReactComponent as SystemIsDownIcon } from "theme/icons/system-is-down.svg";
import { ReactComponent as PositiveAlertIcon } from "theme/icons/positive-alert.svg";

const useStyles = makeStyles((theme) => ({
  reply: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    borderTop: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: theme.palette.background.grey,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    height: 50,
    overflowY: "hidden",
  },
  replyDescription: {
    maxHeight: 50,
    lineHeight: 1.2,
  },
  avatar: {
    width: 28,
    height: 28,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.brown.light,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(10),
  },
  avatarIcon: {
    width: 15,
    height: 15,
  },
  replyTitle: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    lineHeight: 1.2,
    color: theme.palette.brown.main,
  },
  replyTo: {
    fontFamily: theme.typography.bold.fontFamily,
  },
  replyText: {
    fontSize: theme.typography.pxToRem(13),
  },
  clearIcon: {
    flex: "0 0 20px",
    cursor: "pointer",
    fill: theme.palette.brown.main,
  },
  opsAlert: {
    "& .MuiAvatar-root": {
      backgroundColor: theme.palette.brown.light,
    },
    "& .MuiTypography-h6": {
      color: theme.palette.brown.main,
    },
    fill: theme.palette.brown.main,
  },
  goodAlert: {
    "& .MuiAvatar-root": {
      backgroundColor: "rgba(31, 215, 109, 0.15)",
    },
    "& .MuiTypography-h6": {
      color: theme.palette.green.alert,
    },
    fill: theme.palette.green.alert,
  },
}));

const ChatInputAlertReply = ({ reply, onRemoveReply }) => {
  const classes = useStyles();

  const getAlertType = () => {
    const alertType = reply.alert.type;
    if (alertType.startsWith("ops") || alertType.startsWith("cust"))
      return classes.opsAlert;
    if (alertType.startsWith("good")) return classes.goodAlert;
    return "";
  };

  return (
    <div className={clsx(classes.reply, getAlertType())}>
      <div className={classes.wrapper}>
        <Avatar className={classes.avatar}>
          {reply.alert.type.startsWith("ops") && (
            <SystemIsDownIcon className={classes.avatarIcon} />
          )}
          {reply.alert.type.startsWith("good") && (
            <PositiveAlertIcon className={classes.avatarIcon} />
          )}
        </Avatar>
        <div className={classes.replyDescription}>
          <Typography variant="h6" className={classes.replyTitle}>
            Reply to{" "}
            <span className={classes.replyTo}>
              Alert #{reply.alert?.store?.store_name}
            </span>
          </Typography>
          <span className={classes.replyText}>{reply.alert.description}</span>
        </div>
      </div>
      <ClearIcon
        className={clsx(classes.clearIcon, getAlertType())}
        onClick={() => onRemoveReply()}
      />
    </div>
  );
};

export default ChatInputAlertReply;
