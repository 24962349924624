// Service
import { ApiError } from "api";
import { StoresService } from "services";

// Types
import * as types from "redux/actiontypes/stores-types";

const searchByStore = (store) => ({
  type: types.SEARCH_BY_STORE,
  payload: store,
});

const getUserStores = (date) => async () => {
  try {
    const { data } = await StoresService.getUserStores(date);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getCompanyStats = () => async () => {
  try {
    const { data } = await StoresService.getCompanyStats();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getStoreStatsById = (storeId) => async () => {
  try {
    const { data } = await StoresService.getStoreStatsById(storeId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getDailyStores = () => async () => {
  try {
    const { data } = await StoresService.getDailyStores();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getReports = () => async () => {
  try {
    const { data } = await StoresService.getReports();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

export {
  searchByStore,
  getUserStores,
  getCompanyStats,
  getStoreStatsById,
  getDailyStores,
  getReports,
};
