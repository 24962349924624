// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  empty: {
    display: "block",
    padding: theme.spacing(3),
    textAlign: "center",
    fontSize: theme.typography.pxToRem(14),
  },
  monthSection: {
    marginBottom: theme.spacing(6),
  },
  title: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.grey,
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  date: {
    minWidth: 100,
  },
  link: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(16),

    "& .ext-icon": {
      width: 22,
      height: "auto",
      marginRight: "2px",
    },
  },
}));

export { useStyles };
