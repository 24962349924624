// Core
import React from "react";
import clsx from "clsx";
import { useHistory, useLocation } from "react-router-dom";

// Components
import { List, ListItem, ListItemText } from "@material-ui/core";

// Styles
import { useStyles } from "./navigation.styles";

const menuItems = [
  { text: "feed", path: "/feed/unres" },
  { text: "chat", path: "/chat" },
  { text: "audios", path: "/audios" },
  { text: "daily", path: "/daily" },
  { text: "reports", path: "/reports" },
];

const MuiNavigation = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const handleActiveNav = ({ path, text }) => {
    return (
      location.pathname === path ||
      location.pathname.includes(text.toLowerCase().split(" ").join("-"))
    );
  };

  const handleClick = (item) => history.push(item.path);

  return (
    <nav className={classes.nav}>
      <List className={classes.list}>
        {menuItems.map((item) => (
          <ListItem
            key={item.text}
            className={clsx(
              classes.item,
              handleActiveNav(item) ? classes.active : ""
            )}
            onClick={() => handleClick(item)}
          >
            <ListItemText primary={item.text} className={classes.itemText} />
          </ListItem>
        ))}
      </List>
    </nav>
  );
};

export default MuiNavigation;
