// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
    userSelect: "none",
    cursor: "pointer",
  },
  expandIcon: {
    marginLeft: theme.spacing(0.5),
    transition: "transform 0.2s ease-in-out",
  },
  active: {
    transform: "rotate(180deg)",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
    "& .MuiFormGroup-root": {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
  },
  drawerTitle: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.main,
  },
  radioGroup: {
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  button: {
    flex: "0 1 50%",
    height: 38,
    padding: 0,
    borderRadius: 6,
    whiteSpace: "nowrap",
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
    "& .MuiButton-label": {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      textTransform: "none",
      [theme.breakpoints.down(theme.breakpoints.values.small)]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.text.main,
    color: theme.palette.text.main,
  },
}));

export { useStyles };
