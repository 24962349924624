// Core
import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import clsx from "clsx";

// Actions
import { getDailyStores } from "redux/actions/stores-actions";
import {
  searchByStore,
  setActiveDailyTab,
  setFilterBy,
} from "redux/actions/daily-actions";

// Hooks
import { useBreakpoints } from "hooks";

// Components
import { Typography, Paper, Box, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  MuiWrapper,
  MuiTableContainer,
  MuiLoader,
  MuiSearch,
} from "components/common";
import { DailyMetric, DailyTabs, DailyStoreList, Catcher } from "components";
import { getTableTitle, getTableColumns } from "./tableColumns";

// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReactComponent as DailyUpsellIcon } from "theme/icons/daily-upsell-icon.svg";
import { ReactComponent as DailyRuleIcon } from "theme/icons/daily-rule-icon.svg";
import { ReactComponent as DailyOpsIcon } from "theme/icons/daily-ops-icon.svg";
import { ReactComponent as DailyAwardsIcon } from "theme/icons/daily-awards-icon.svg";
import { ReactComponent as DailySoSIcon } from "theme/icons/stopwatch-icon.svg";

// Tools
import { DAILY_STATS_FIELDS } from "utils/constants";
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./daily.styles";

const Daily = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useBreakpoints();
  const {
    searchByStore: searchByStoreValue,
    activeDailyTab,
    sortByBest,
    filterBy,
  } = useSelector((state) => state.daily);
  const [items, setItems] = useState(null);

  const { data, error, isLoading, isError } = useQuery(
    ["daily_report"],
    () => dispatch(getDailyStores()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const sortBy = useCallback(
    (data) => {
      const dataCopy = Object.assign([], data);
      const currentTab = DAILY_STATS_FIELDS[activeDailyTab];

      if (dataCopy.length === 0) return;
      if (
        currentTab === "total_upsell_rate" ||
        currentTab === "rule_welcome_rate"
      ) {
        const sortedData = dataCopy?.sort((a, b) =>
          sortByBest
            ? Math.floor(b?.yesterday[currentTab] * 100) -
              Math.floor(a?.yesterday[currentTab] * 100)
            : Math.floor(a?.yesterday[currentTab] * 100) -
              Math.floor(b?.yesterday[currentTab] * 100)
        );
        setItems(sortedData);
      }
      if (
        currentTab === "ops_alert_count" ||
        currentTab === "ops_sos_order_count"
      ) {
        const sortedData = dataCopy?.sort((a, b) =>
          sortByBest
            ? a?.yesterday[currentTab] - b?.yesterday[currentTab]
            : b?.yesterday[currentTab] - a?.yesterday[currentTab]
        );
        setItems(sortedData);
      }
      if (currentTab === "good_alert_count") {
        const sortedData = dataCopy?.sort((a, b) =>
          sortByBest
            ? b?.yesterday[currentTab] - a?.yesterday[currentTab]
            : a?.yesterday[currentTab] - b?.yesterday[currentTab]
        );
        setItems(sortedData);
      }
    },
    [activeDailyTab, sortByBest]
  );

  useEffect(() => {
    filterBy === "store" ? sortBy(data?.stores) : sortBy(data?.managers);
  }, [sortByBest, activeDailyTab, data, sortBy, filterBy]);

  useEffect(() => {
    if (data && filterBy === "store") {
      if (searchByStoreValue.length > 0) {
        const filteredStores = data?.stores?.filter((store) =>
          searchBy(
            {
              store_name: store?.store_name,
              manager_district_name: store?.manager_district_name,
              address_line: `${store?.city} ${store?.state}`,
            },
            searchByStoreValue
          )
        );

        sortBy(filteredStores);
      } else {
        sortBy(data?.stores);
      }
    } else {
      if (searchByStoreValue.length > 0) {
        const filteredManagers = data?.managers?.filter((manager) =>
          searchBy(
            {
              name: manager?.name,
            },
            searchByStoreValue
          )
        );

        sortBy(filteredManagers);
      } else {
        sortBy(data?.managers);
      }
    }
  }, [searchByStoreValue, data, sortBy, filterBy]);

  const toggleFilterBy = () => {
    filterBy === "store"
      ? dispatch(setFilterBy("manager"))
      : dispatch(setFilterBy("store"));
  };

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <MuiWrapper maxWidth="lg" isMobile={isMobile}>
        <div className={clsx(isMobile && classes.container)}>
          {isDesktop && (
            <>
              <div className={classes.metricSetter}>
                <Typography
                  className={classes.title}
                  variant="h1"
                  color="inherit"
                >
                  {getTableTitle(activeDailyTab)}
                </Typography>
                <MuiSearch
                  className={classes.search}
                  placeholder="Search"
                  value={searchByStoreValue}
                  onChange={(store) => dispatch(searchByStore(store))}
                />
                <DailyMetric />
              </div>

              {data?.stores && (
                <Paper className={classes.tableWrapper}>
                  <MuiTableContainer
                    tableData={data?.stores}
                    tableColumns={getTableColumns(activeDailyTab)}
                    initialState={
                      isMobile
                        ? {
                            sortBy: [{ id: "store_name" }],
                            hiddenColumns: ["id"],
                          }
                        : {
                            sortBy: [{ id: "store_name" }],
                          }
                    }
                    searchValue={searchByStoreValue}
                  />
                </Paper>
              )}
            </>
          )}

          {isMobile && items && (
            <>
              <div className={classes.mobileFilters}>
                <Box className={classes.mobileSearchRow}>
                  <MuiSearch
                    className={classes.mobileSearch}
                    placeholder="Search"
                    value={searchByStoreValue}
                    onChange={(store) => dispatch(searchByStore(store))}
                  />
                  <Button
                    onClick={toggleFilterBy}
                    className={classes.filterBtn}
                    variant="contained"
                    color="primary"
                    endIcon={<ExpandMoreIcon>filter</ExpandMoreIcon>}
                  >
                    By {filterBy === "store" ? "Store" : "Manager"}
                  </Button>
                </Box>
              </div>

              <DailyTabs
                tabs={[
                  { text: "Upsell", icon: <DailyUpsellIcon /> },
                  { text: "SoS", icon: <DailySoSIcon /> },
                  { text: "Rule", icon: <DailyRuleIcon /> },
                  { text: "Ops", icon: <DailyOpsIcon /> },
                  { text: "Awards", icon: <DailyAwardsIcon /> },
                ]}
                onChange={setActiveDailyTab}
                activeTab={activeDailyTab}
              >
                <DailyStoreList
                  stores={items}
                  activeTab={activeDailyTab}
                  data={data}
                />
                <DailyStoreList
                  stores={items}
                  activeTab={activeDailyTab}
                  data={data}
                />
                <DailyStoreList
                  stores={items}
                  activeTab={activeDailyTab}
                  data={data}
                />
                <DailyStoreList
                  stores={items}
                  activeTab={activeDailyTab}
                  data={data}
                />
                <DailyStoreList
                  stores={items}
                  activeTab={activeDailyTab}
                  data={data}
                />
              </DailyTabs>
            </>
          )}
        </div>
      </MuiWrapper>
    </Catcher>
  );
};

export default Daily;
