// Service
import { ApiError } from "api";
import { FiltersService } from "services";

// Types
import * as types from "redux/actiontypes/filter-types";

const searchByStore = (store) => ({
  type: types.SEARCH_BY_STORE,
  payload: store,
});

const filterOrdersByPeriod = (date) => ({
  type: types.FILTER_ORDERS_BY_PERIOD,
  payload: date,
});

const addFilterTag = (tag) => ({ type: types.ADD_FILTER_TAG, payload: tag });

const removeFilterTag = (tag) => ({
  type: types.REMOVE_FILTER_TAG,
  payload: tag,
});

const addFilterStore = (store) => ({
  type: types.ADD_FILTER_STORE,
  payload: store,
});

const removeFilterStore = (store) => ({
  type: types.REMOVE_FILTER_STORE,
  payload: store,
});

const setCurrentPage = (page) => ({
  type: types.SET_CURRENT_PAGE,
  payload: page,
});

const setPageCount = (page) => ({
  type: types.SET_PAGE_COUNT,
  payload: page,
});

const setSortBy = (sortBy) => ({
  type: types.SET_SORT_BY,
  payload: sortBy,
});

const getTags = () => async () => {
  try {
    const { data } = await FiltersService.getTags();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

export {
  searchByStore,
  filterOrdersByPeriod,
  addFilterTag,
  removeFilterTag,
  addFilterStore,
  removeFilterStore,
  setPageCount,
  setSortBy,
  getTags,
  setCurrentPage,
};
