// Core
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Link as RouterLink } from "react-router-dom";
import { useQuery } from "react-query";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";

// Actions
import { getOrderAudio, shareOrder } from "redux/actions/orders-actions";
import { showSnackbar } from "redux/actions/ui-actions";

// Components
import { Typography, Breadcrumbs, Link, Button, Chip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  MuiWrapper,
  MuiLoader,
  MuiSnackbar,
  MuiBackButton,
} from "components/common";
import { Catcher, WavePlayer } from "components";

// Icons
import { NavigateNext } from "@material-ui/icons";
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";
import { ReactComponent as ShareIcon } from "theme/icons/share.svg";

// Tools
import { getDateFromTimestamp } from "utils/helpers";

// Styles
import { useStyles } from "./order.styles";

const Order = () => {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [order, setOrder] = useState(null);
  const [shareOrderKey, setShareOrderKey] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["audio", id], () => dispatch(getOrderAudio(id)), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  const shareOrderQuery = useQuery(
    ["audio_share", id],
    () => dispatch(shareOrder(id)),
    {
      onSuccess: (data) => {
        setShareOrderKey(data?.key);
      },
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setOrder(apiResponse);
  }, [apiResponse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCopyText = () => {
    dispatch(showSnackbar(true));
  };

  const getTagClassName = (type) => {
    if (type === "ops") return classes.tagOps;
    if (type === "upsell") return classes.tagUpsell;
    if (type === "product") return classes.tagProduct;
    return "";
  };

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error?.message}</Alert>;
  }

  return (
    <>
      {order && (
        <Catcher>
          <MuiWrapper maxWidth="xl" isMobile={isMobile}>
            {location.state ? (
              <div className={isMobile ? classes.container : ""}>
                <MuiBackButton previousRoute={true} />
              </div>
            ) : (
              <Breadcrumbs
                className={clsx(
                  classes.breadcrump,
                  isMobile ? classes.container : ""
                )}
                aria-label="breadcrumb"
                separator={<NavigateNext fontSize="small" />}
              >
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/audios"
                  className={classes.breadcrumpItemRoot}
                >
                  Audios
                </Link>
                <Typography
                  color="textPrimary"
                  className={classes.breadcrumpItem}
                >
                  Audio details
                </Typography>
              </Breadcrumbs>
            )}

            <div className={isMobile ? classes.container : ""}>
              {shareOrderQuery.isError && (
                <Alert severity="error">
                  {shareOrderQuery?.error?.message}
                </Alert>
              )}
              <div className={classes.row}>
                <div className={classes.heading}>
                  <Typography
                    className={classes.title}
                    variant="h5"
                    color="inherit"
                  >
                    {order && order.store_name}
                  </Typography>
                  <div className={classes.timestamp}>
                    <CalendarIcon className={classes.calendarIcon} />
                    <span className={classes.date}>
                      {getDateFromTimestamp(order.timestamp_local)}
                    </span>
                  </div>
                </div>
                <CopyToClipboard
                  text={`${window.location.origin}/audios/shared/${shareOrderKey}`}
                  onCopy={handleCopyText}
                  className={classes.copyWrapper}
                >
                  <Button
                    className={classes.button}
                    variant="outlined"
                    startIcon={
                      <ShareIcon
                        className={clsx(
                          classes.shareIcon,
                          shareOrderQuery.isLoading && classes.shareIconDisabled
                        )}
                      />
                    }
                    disabled={shareOrderQuery.isLoading}
                  >
                    Share
                  </Button>
                </CopyToClipboard>
                <MuiSnackbar
                  message={`Link copied for "Restaurant ${order.store_name}"`}
                />
              </div>

              <WavePlayer
                order={order}
                shareUrl={`${window.location.origin}/audios/shared/${shareOrderKey}`}
              />

              <div className={classes.tagsWrapper}>
                <Typography
                  className={classes.tagsTitle}
                  variant="h5"
                  color="inherit"
                >
                  Tags
                </Typography>

                <div className={classes.tags}>
                  {[
                    ...new Map(
                      order.pretty_tags.map((tag) => [tag["name"], tag])
                    ).values(),
                  ].map((tag, index) => (
                    <Chip
                      key={index}
                      className={clsx(classes.tag, getTagClassName(tag.type))}
                      variant="outlined"
                      size="small"
                      label={tag.pretty_name}
                    />
                  ))}
                </div>
              </div>

              {user?.is_outloud && (
                <div className={classes.transcriptionsWrapper}>
                  <Typography
                    className={classes.transcriptionsTitle}
                    variant="h5"
                    color="inherit"
                  >
                    Transcriptions
                  </Typography>
                  <Chip
                    className={classes.stream}
                    variant="outlined"
                    size="small"
                    label={order?.stream_id}
                  />

                  <div className={classes.transcriptions}>
                    {order?.transcription.map((item, index) => (
                      <div key={index}>
                        <Chip
                          className={classes.transcription}
                          variant="outlined"
                          size="small"
                          label={item}
                        />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </MuiWrapper>
        </Catcher>
      )}
    </>
  );
};

export default Order;
