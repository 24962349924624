// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  rowCentered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3.5),
  },
  titleIcon: {
    width: 24,
    height: 24,
    marginRight: theme.spacing(1.5),
  },
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontFamily: theme.typography.medium.fontFamily,
    color: theme.palette.text.main,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  charts: {
    flex: "0 1 65%",
    display: "flex",
    flexWrap: "wrap",
    marginRight: 14,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: "100%",
      flexWrap: "nowrap",
      flexDirection: "column",
    },
  },
  table: {
    flex: "0 1 35%",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  chart: {
    flex: "0 1 calc(50% - 16px)",
    marginRight: 16,
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      width: "100%",
      marginRight: 0,
    },
  },
  info: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: 22,
    borderRadius: 8,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  infoTitle: {
    marginBottom: 2,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
  },
  infoRow: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  infoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  infoDate: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  infoIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5),
  },
  infoCountIcon: {
    color: theme.palette.text.grey,
  },
  infoText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
  tableWrapper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "22px 0 22px 22px",
    borderRadius: 8,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    "& .MuiTableContainer-root": {
      borderRadius: 8,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: "16px 0 16px 16px",
      "& .MuiTableContainer-root": {
        borderRadius: 0,
      },
    },
  },
  searchRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1.5),
    paddingRight: 22,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  searchTitleWrapper: {
    marginRight: theme.spacing(2),
  },
  searchTitle: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    marginBottom: 2,
  },
  search: {
    maxWidth: "233px",
  },
  dateRow: {
    display: "flex",
    alignItems: "center",
  },
  dateIcon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5),
  },
  dateText: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
  },
}));

export { useStyles };
