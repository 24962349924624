// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";
import {
  getAlertDetails,
  updateAlertDetails,
  deleteAlert,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiBackButton, MuiLoader, MuiButton } from "components/common";
import { Paper } from "components/settings";
import {
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
  Box,
  Chip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { NavigateNext, Done, Add } from "@material-ui/icons";

// Styles
import { useStyles } from "./alert-details.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminAlertDetails = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { isMobile, isDesktop } = useBreakpoints();
  const [alertDetails, setAlertDetails] = useState(null);
  const [threshold, setThreshold] = useState(null);
  const [ignoreInput, setIgnoreInput] = useState("");
  const [lineInput, setLineInput] = useState("");
  const [ignore, setIgnore] = useState([]);
  const [lines, setLines] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogIgnoreOpen, setDialogIgnoreOpen] = useState(false);
  const [dialogLineOpen, setDialogLineOpen] = useState(false);
  const isOpsAlert =
    alertDetails?.type.startsWith("ops") ||
    alertDetails?.type.startsWith("cust");
  const isUpsellAlert = alertDetails?.type === "ops_low_upsell";

  const getAlertQuery = useQuery(
    ["admin_alert_info", id],
    () => dispatch(getAlertDetails(id)),
    {
      onSuccess: (data) => {
        setAlertDetails(data);
        setThreshold(data?.threshold);
        setIgnore(data?.ignore ?? []);
        setLines(data?.lines ?? []);
      },
      refetchOnWindowFocus: false,
    }
  );

  const deleteAlertQuery = useMutation(() => dispatch(deleteAlert(id)), {
    onSuccess: () => {
      handleDialogClose();
    },
  });

  const updateAlertQuery = useMutation((alert) =>
    dispatch(updateAlertDetails(id, alert))
  );

  useEffect(() => {
    if (deleteAlertQuery.isSuccess) {
      const timer = setTimeout(() => {
        history.push("/settings/admin/alerts");
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [deleteAlertQuery.isSuccess, history]);

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(false));

  const handleDeleteAlert = () => deleteAlertQuery.mutate();

  const handleThresholdChange = (e) => setThreshold(e.target.value);
  const handleIgnoreInputChange = (e) => setIgnoreInput(e.target.value);
  const handleLineInputChange = (e) => setLineInput(e.target.value);

  const handleAddIgnoreUtterance = () => {
    setIgnore([...ignore, ignoreInput]);
    setIgnoreInput("");
  };
  const handleDeleteIgnore = (utt) => {
    const filteredIgnore = ignore.filter((item) => item !== utt);
    setIgnore(filteredIgnore);
  };

  const handleAddUpsellLine = () => {
    setLines([...lines, lineInput]);
    setLineInput("");
  };
  const handleDeleteLine = (line) => {
    const filteredLines = lines.filter((item) => item !== line);
    setLines(filteredLines);
  };

  const handleIgnoreKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddIgnoreUtterance();
    }
  };
  const handleLineKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddUpsellLine();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAlertQuery.mutate({ threshold, ignore, lines });
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  const handleIgnoreDialogOpen = () => setDialogIgnoreOpen(true);
  const handleIgnoreDialogClose = () => setDialogIgnoreOpen(false);
  const handleLineDialogOpen = () => setDialogLineOpen(true);
  const handleLineDialogClose = () => setDialogLineOpen(false);

  if (getAlertQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getAlertQuery.isError) {
    return <Alert severity="error">{getAlertQuery.error.message}</Alert>;
  }

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/admin/alerts"
            onClick={handleBackButtonClick}
          />
          <div className={classes.titleRow}>
            <Typography className={classes.title} component="h1" variant="h5">
              {alertDetails && alertDetails?.pretty_name}
            </Typography>
            {deleteAlertQuery.isSuccess ? (
              <Button
                className={clsx(classes.button, classes.buttonSuccess)}
                size="small"
                variant="outlined"
                startIcon={
                  <Done
                    className={classes.buttonSuccessIcon}
                    fontSize="small"
                  />
                }
                fullWidth
                disabled
              >
                Deleted
              </Button>
            ) : (
              <Button
                className={clsx(classes.button, classes.buttonRemove)}
                onClick={handleDialogOpen}
                size="small"
                variant="outlined"
                fullWidth
                disabled={deleteAlertQuery.isLoading}
              >
                Delete alert
              </Button>
            )}
          </div>
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && (
            <div className={classes.titleRow}>
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
                separator={<NavigateNext fontSize="small" />}
              >
                <Link
                  className={classes.breadcrumbItemRoot}
                  component={RouterLink}
                  to="/settings/admin/alerts"
                >
                  Alerts
                </Link>
                <Typography className={classes.breadcrumbItem}>
                  {alertDetails && alertDetails?.pretty_name}
                </Typography>
              </Breadcrumbs>

              {deleteAlertQuery.isSuccess ? (
                <Button
                  className={clsx(classes.button, classes.buttonSuccess)}
                  size="small"
                  variant="outlined"
                  startIcon={
                    <Done
                      className={classes.buttonSuccessIcon}
                      fontSize="small"
                    />
                  }
                  fullWidth
                  disabled
                >
                  Deleted
                </Button>
              ) : (
                <Button
                  className={clsx(classes.button, classes.buttonRemove)}
                  onClick={handleDialogOpen}
                  size="small"
                  variant="outlined"
                  fullWidth
                  disabled={deleteAlertQuery.isLoading}
                >
                  Delete alert
                </Button>
              )}
            </div>
          )}

          {updateAlertQuery.isError && (
            <Alert severity="error">{updateAlertQuery.error.message}</Alert>
          )}
          {updateAlertQuery.isSuccess && (
            <Alert severity="success">Successfully updated</Alert>
          )}

          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {threshold !== null && (
              <TextField
                className={classes.input}
                label="Threshold"
                type="number"
                variant="outlined"
                margin="normal"
                fullWidth
                value={threshold}
                onChange={handleThresholdChange}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            )}

            {isOpsAlert && (
              <>
                <Typography
                  className={classes.subtitle}
                  component="h2"
                  variant="h5"
                >
                  Ignore utterances
                </Typography>
                <Box className={classes.chipWrapper}>
                  {ignore.map((utt) => (
                    <Chip
                      key={utt}
                      className={clsx(classes.chip, classes.ignoreChip)}
                      variant="outlined"
                      size="medium"
                      label={utt}
                      onDelete={() => handleDeleteIgnore(utt)}
                    />
                  ))}
                  <IconButton
                    className={classes.addIcon}
                    onClick={handleIgnoreDialogOpen}
                    color="primary"
                    aria-label="add to ignore utterances"
                  >
                    <Add />
                  </IconButton>
                </Box>
              </>
            )}

            {isUpsellAlert && (
              <>
                <Typography
                  className={classes.subtitle}
                  component="h2"
                  variant="h5"
                >
                  Upsell lines
                </Typography>
                <Box className={classes.chipWrapper}>
                  {lines.map((line) => (
                    <Chip
                      key={line}
                      className={clsx(classes.chip, classes.lineChip)}
                      variant="outlined"
                      size="medium"
                      label={line}
                      onDelete={() => handleDeleteLine(line)}
                    />
                  ))}
                  <IconButton
                    className={classes.addIcon}
                    onClick={handleLineDialogOpen}
                    color="primary"
                    aria-label="add to upsell lines"
                  >
                    <Add />
                  </IconButton>
                </Box>
              </>
            )}

            {(threshold !== null || isOpsAlert || isUpsellAlert) && (
              <MuiButton
                className={classes.submitButton}
                disabled={updateAlertQuery.isLoading}
              >
                {updateAlertQuery.isLoading ? "Saving..." : "Save changes"}
              </MuiButton>
            )}
          </form>
        </Paper>

        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete {alertDetails?.pretty_name} alert?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.button}
              onClick={handleDialogClose}
              size="small"
              variant="outlined"
              fullWidth
            >
              Cancel
            </Button>

            <Button
              className={clsx(classes.button, classes.buttonRemove)}
              onClick={handleDeleteAlert}
              size="small"
              variant="outlined"
              fullWidth
              disabled={deleteAlertQuery.isLoading}
              autoFocus
            >
              {deleteAlertQuery.isLoading ? "Deleting..." : "Delete"}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogIgnoreOpen}
          onClose={handleIgnoreDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            Add ignore utterance
          </DialogTitle>
          <DialogContent>
            <TextField
              className={clsx(classes.input, classes.ignoreInput)}
              label="Utterance"
              type="text"
              variant="outlined"
              margin="normal"
              onKeyDown={handleIgnoreKeyDown}
              fullWidth
              autoFocus
              value={ignoreInput}
              onChange={handleIgnoreInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.button}
              onClick={handleIgnoreDialogClose}
              size="small"
              variant="outlined"
              fullWidth
            >
              Cancel
            </Button>

            <Button
              className={clsx(classes.button, classes.buttonAdd)}
              onClick={handleAddIgnoreUtterance}
              size="small"
              variant="outlined"
              fullWidth
              disabled={ignoreInput.length === 0}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={dialogLineOpen}
          onClose={handleLineDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">Add upsell line</DialogTitle>
          <DialogContent>
            <TextField
              className={clsx(classes.input, classes.lineInput)}
              label="Upsell lines"
              type="text"
              variant="outlined"
              margin="normal"
              onKeyDown={handleLineKeyDown}
              fullWidth
              autoFocus
              value={lineInput}
              onChange={handleLineInputChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              className={classes.button}
              onClick={handleLineDialogClose}
              size="small"
              variant="outlined"
              fullWidth
            >
              Cancel
            </Button>

            <Button
              className={clsx(classes.button, classes.buttonAdd)}
              onClick={handleAddUpsellLine}
              size="small"
              variant="outlined"
              fullWidth
              disabled={lineInput.length === 0}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Catcher>
  );
};

export default AdminAlertDetails;
