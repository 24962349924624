// Core
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// Components
import {
  Login,
  ResetPassword,
  ForgotPassword,
  LoginWithEmail,
} from "components/forms";

// Icons
import { ReactComponent as ChatIcon } from "theme/icons/workspace.svg";
import { ReactComponent as ReportIcon } from "theme/icons/report.svg";
import { ReactComponent as FeedIcon } from "theme/icons/store.svg";
import {
  Settings as SettingsIcon,
  Schedule as ScheduleIcon,
} from "@material-ui/icons";

// Pages
import {
  Orders,
  Order,
  Alert,
  AlertGood,
  Chat,
  Settings,
  AlertCommunication,
  Daily,
  Reports,
  Feed,
  SharedOrder,
} from "pages";

// Custom Routes
import { MainRoute, SidebarRoute, StaticSidebarRoute } from "routes";

const getRoutes = (isAuthenticated) => {
  if (isAuthenticated) {
    return (
      <Switch>
        <SidebarRoute exact path="/audios" component={Orders} />
        <MainRoute exact path="/audios/:id" component={Order} />
        <MainRoute exact path="/audios/shared/:id" component={SharedOrder} />
        <StaticSidebarRoute
          exact
          path="/chat"
          component={Chat}
          title="Chat"
          icon={<ChatIcon />}
        />
        <StaticSidebarRoute
          exact
          path="/chat/:id"
          component={Chat}
          title="Chat"
          icon={<ChatIcon />}
        />
        <MainRoute
          exact
          path="/alert/:id"
          component={Alert}
          title="Alert Details"
        />
        <MainRoute
          exact
          path="/alert-good/:id"
          component={AlertGood}
          title="Alert Details"
        />
        <MainRoute
          exact
          path="/communication/:id"
          component={AlertCommunication}
          title="Alert Details"
        />
        <MainRoute
          exact
          path="/daily"
          component={Daily}
          title="Daily"
          icon={<ScheduleIcon />}
        />
        <MainRoute
          exact
          path="/feed/:filter"
          component={Feed}
          title="Feed"
          icon={<FeedIcon />}
        />
        <MainRoute
          exact
          path="/reports"
          component={Reports}
          title="Reports"
          icon={<ReportIcon />}
        />
        <StaticSidebarRoute
          exact
          path="/settings/:page"
          component={Settings}
          title="Settings"
          icon={<SettingsIcon />}
          fullHeight
        />
        <StaticSidebarRoute
          exact
          path="/settings/admin/:page"
          component={Settings}
          title="Settings"
          icon={<SettingsIcon />}
          fullHeight
        />
        <StaticSidebarRoute
          exact
          path="/settings/admin/users/:id"
          component={Settings}
          title="Settings"
          icon={<SettingsIcon />}
          fullHeight
        />
        <StaticSidebarRoute
          exact
          path="/settings/admin/alerts/:id"
          component={Settings}
          title="Settings"
          icon={<SettingsIcon />}
          fullHeight
        />
        <StaticSidebarRoute
          exact
          path="/settings/admin/users/:id/:page"
          component={Settings}
          title="Settings"
          icon={<SettingsIcon />}
          fullHeight
        />
        <StaticSidebarRoute
          exact
          path="/settings/admin/channels/:id"
          component={Settings}
          title="Settings"
          icon={<SettingsIcon />}
          fullHeight
        />
        <Redirect to="/feed/unres" />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/login-email" component={LoginWithEmail} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/audios/shared/:id" component={SharedOrder} />
      <Route
        exact
        path="/reset-password/:id/:token"
        component={ResetPassword}
      />
      <Redirect to="/login" />
    </Switch>
  );
};

export default getRoutes;
