// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  timestamp: {
    display: "flex",
    alignItems: "center",
  },
  breadcrump: {
    marginBottom: theme.spacing(2),
  },
  breadcrumpItemRoot: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.grey,
  },
  breadcrumpItem: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.main,
  },
  title: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.main,
    marginBottom: theme.spacing(1),
  },
  calendarIcon: {
    marginRight: theme.spacing(0.5),
  },
  date: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.grey,
  },
  copyWrapper: {
    width: 110,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    height: 45,
    minWidth: 110,
    borderRadius: 8,
    backgroundColor: "transparent",
    borderColor: theme.palette.text.grey,
    "& .MuiButton-label": {
      fontFamily: theme.typography.medium.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.grey,
      textTransform: "none",
    },
  },
  shareIcon: {
    width: 18,
    height: 16,
    fill: theme.palette.text.grey,
  },
  shareIconDisabled: {
    fill: "rgba(0, 0, 0, 0.26)",
  },
  tagsTitle: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    marginBottom: theme.spacing(1),
  },
  tagsWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  tags: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  tag: {
    height: 20,
    border: "none",
    borderRadius: 6,
    marginRight: theme.spacing(1),
    textTransform: "lowercase",
    color: theme.palette.text.main,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  tagOps: {
    backgroundColor: theme.palette.mint,
  },
  tagUpsell: {
    backgroundColor: theme.palette.yellow.main,
  },
  tagProduct: {
    backgroundColor: theme.palette.blue.main,
  },
  transcriptionsWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  transcriptionsTitle: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    marginBottom: theme.spacing(1),
  },
  transcriptions: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  transcription: {
    height: 20,
    border: "none",
    borderRadius: 6,
    marginRight: theme.spacing(1),
    textTransform: "lowercase",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.yellow.main,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  stream: {
    height: 20,
    border: "none",
    borderRadius: 6,
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: "lowercase",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.blue.main,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export { useStyles };
