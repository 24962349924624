export const SET_CHANNEL_ID = "SET_CHANNEL_ID";
export const SET_STORE = "SET_STORE";
export const SET_NEW_LIKE = "SET_NEW_LIKE";
export const SET_SOCKET = "SET_SOCKET";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const SET_CHANNEL_MESSAGES = "SET_CHANNEL_MESSAGES";
export const SET_PARTICIPANTS = "SET_PARTICIPANTS";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const UPDATE_UNREAD_TOTAL_COUNT = "UPDATE_UNREAD_TOTAL_COUNT";
export const UPDATE_UNREAD_ALERT_COUNT = "UPDATE_UNREAD_ALERT_COUNT";
export const CLEAR_UNREAD_COUNT = "CLEAR_UNREAD_COUNT";
export const SET_REPLY_TO = "SET_REPLY_TO";
export const SEARCH_BY_CHANNEl = "SEARCH_BY_CHANNEL";
export const SHOW_CHANNELS_SIDEBAR = "SHOW_CHANNELS_SIDEBAR";
export const CLEAR_CHANNEL = "CLEAR_CHANNEL";
