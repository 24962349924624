// Core
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { useMediaQuery } from "@material-ui/core";
import { MuiHeader, MuiBarSpacer } from "components/common";
import { MuiHeaderMobile } from "components";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
}));

const MainLayout = ({ children, title, icon }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className={classes.root}>
      {isMobile ? <MuiHeaderMobile title={title} icon={icon} /> : <MuiHeader />}

      <div className={classes.content}>
        <MuiBarSpacer />
        {children}
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
