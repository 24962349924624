// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  metricSetter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  title: {
    marginRight: theme.spacing(6),
    marginLeft: theme.spacing(3.5),
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.grey,
  },
  search: {
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
  mobileFilters: {
    position: "fixed",
    top: 55,
    left: 15,
    right: 15,
    zIndex: 99,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: theme.palette.background.main,
  },
  mobileSearchRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileSearch: {
    width: "100%",
  },
  filterBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: 132,
    height: 30,
    borderRadius: 14,
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
    textTransform: "capitalize",
    whiteSpace: "nowrap",
  },
  tableWrapper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "22px",
    borderRadius: 8,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    "& .MuiTableContainer-root": {
      borderRadius: 8,
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: "16px",
      "& .MuiTableContainer-root": {
        borderRadius: 0,
      },
      "& .MuiTableHead-root .MuiTableCell-root:first-child": {
        position: "sticky",
        left: 0,
        zIndex: 99,
        width: 100,
        backgroundColor: theme.palette.common.white,
        border: 0,
      },
      "& .MuiTableBody-root .MuiTableCell-root:first-child": {
        position: "sticky",
        left: 0,
        width: 100,
        padding: "6px 2px 6px 0",
        border: 0,
        backgroundColor: theme.palette.common.white,
      },
      "& .MuiTableBody-root .MuiTableCell-root": {
        fontSize: theme.typography.pxToRem(12),
        "& .MuiTypography-root": {
          fontSize: theme.typography.pxToRem(12),
        },
      },
    },
  },
}));

export { useStyles };
