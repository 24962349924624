// Core
import posthog from "posthog-js";

// Service
import { ApiError } from "api";
import { AuthService } from "services";

// Types
import * as authTypes from "redux/actiontypes/auth-types";

const loginWithEmailOrPhone =
  ({ emailOrPhone, password }) =>
  async () => {
    try {
      const { data } = await AuthService.loginWithEmailOrPhone(
        emailOrPhone,
        password
      );

      localStorage.setItem("token", data.accessToken);

      if (process.env.REACT_APP_USE_POSTHOG === "true") {
        const phoneNumber = data.user.phone_number;
        const companyId = data.user.company_id;
        const userKey = `${phoneNumber.substring(
          phoneNumber.length - 4
        )}_${companyId.substring(0, 5)}`;

        posthog.identify(data.user.id);
        posthog.people.set({ phone_number: phoneNumber, user_key: userKey });
      }

      return data;
    } catch (error) {
      const message = ApiError.getErrorMessage(error);
      throw new Error(message);
    }
  };

const logout = () => async (dispatch) => {
  try {
    await AuthService.logout();

    localStorage.removeItem("token");
    localStorage.removeItem("channelId");

    if (process.env.REACT_APP_USE_POSTHOG === "true") {
      posthog.reset();
    }

    dispatch({ type: authTypes.LOGOUT });
  } catch (e) {
    console.log(e.response?.data?.message);
  }
};

const changePassword = (passwords) => async () => {
  try {
    const { data } = await AuthService.changePassword(passwords);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const forgotPassword = (phoneNumber) => async () => {
  try {
    const { data } = await AuthService.forgotPassword(phoneNumber);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getResetPassword = (userId, token) => async () => {
  try {
    const { data } = await AuthService.getResetPassword(userId, token);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const postResetPassword = (userId, token, password) => async () => {
  try {
    const { data } = await AuthService.postResetPassword(
      userId,
      token,
      password
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const setAuth = (bool) => ({
  type: authTypes.SET_AUTH,
  payload: bool,
});

const setUser = (user) => ({
  type: authTypes.SET_USER,
  payload: user,
});

const checkAuth = () => async () => {
  try {
    const { data } = await AuthService.checkAuth();

    localStorage.setItem("token", data.accessToken);

    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

export {
  loginWithEmailOrPhone,
  logout,
  checkAuth,
  changePassword,
  forgotPassword,
  getResetPassword,
  postResetPassword,
  setAuth,
  setUser,
};
