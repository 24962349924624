// Core
import { makeStyles } from "@material-ui/core/styles";

const useTabPanelStyles = makeStyles((theme) => ({
  panel: {
    position: "relative",
    minHeight: "120px",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    backgroundColor: "transparent",
    boxShadow: theme.shadows[0],
    "& .MuiTabs-indicator": {
      height: 4,
      backgroundColor: theme.palette.primary.main,
    },
  },
  label: {
    position: "relative",
    minWidth: "100px",
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    textTransform: "none",
    color: theme.palette.text.main,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
    },
  },
  wrapper: {
    pointerEvents: "none",
  },
}));

export { useStyles, useTabPanelStyles };
