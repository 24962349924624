// Core
import { $http } from "api";

class AlertService {
  static async getAlert(alertID) {
    return $http.get(`/alert/app/details/${alertID}`);
  }

  static async getCommunicationAlert(alertID) {
    return $http.get(`/chat/communication/${alertID}`);
  }

  static async updateAlertStatus(alertID, status) {
    return $http.post(`/alert/resolve/${alertID}`, { status });
  }
}

export default AlertService;
