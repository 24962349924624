// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(4),
    fontSize: theme.typography.pxToRem(24),
    marginLeft: theme.spacing(3.5),
    color: theme.palette.text.grey,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginBottom: theme.spacing(1.5),
      fontFamily: theme.typography.regular.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.text.main,
    },
  },
  buttonGroup: {
    height: 45,
    [theme.breakpoints.down("sm")]: {
      height: 38,
    },
    "& .MuiToggleButton-root": {
      fontFamily: theme.typography.medium.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      backgroundColor: theme.palette.grey.light,
      borderColor: theme.palette.grey.border,
      color: theme.palette.text.grey,
      textTransform: "none",
      [theme.breakpoints.down("sm")]: {
        height: 38,
        fontFamily: theme.typography.regular.fontFamily,
        fontSize: theme.typography.pxToRem(16),
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey.border}`,
      },
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: theme.palette.background.blue,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      borderColor: theme.palette.primary.main,
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      margin: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 8,
        marginRight: theme.spacing(1.5),
      },
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:not(:last-child)": {
      borderRight: "1px solid transparent",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      [theme.breakpoints.down("sm")]: {
        borderRadius: 8,
        border: `1px solid ${theme.palette.grey.border}`,
      },
    },
  },
  button: {
    padding: 12,
    transition: "all 0.2s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1.5),
      width: "25%",
      padding: 0,
      "&:last-child.MuiToggleButtonGroup-groupedHorizontal": {
        marginRight: 0,
      },
    },

    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
    "&.MuiToggleButton-root": {
      borderRadius: 8,
      "&.Mui-selected": {
        borderRightColor: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
}));

export { useStyles };
