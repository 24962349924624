// Core
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  drawer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      zIndex: 101,
      transition: theme.transitions.create(["left"]),
    },
  },
  hideDrawer: {
    [theme.breakpoints.down("sm")]: {
      left: "-100%",
      transition: theme.transitions.create(["left"]),
    },
  },
  drawerPaper: {
    zIndex: 99,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  wrapper: {
    height: "100%",
    width: "inherit",
    position: "fixed",
    overflowY: "auto",
    backgroundColor: theme.palette.background.main,
    paddingBottom: 60,
  },
  title: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(24),
    color: theme.palette.text.main,
  },
  subTitle: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.grey,
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  },
  list: {
    marginBottom: theme.spacing(3),
  },
  link: {
    display: "block",
    "& .MuiListItemText-primary": {
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.grey,
      letterSpacing: "0.15px",
    },
  },
  linkItem: {
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.blue.light,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.blue.light,
      "&:hover": {
        backgroundColor: theme.palette.blue.light,
      },
      "& .MuiListItemText-primary": {
        color: theme.palette.text.main,
      },
      "& .fillIcon": {
        fill: theme.palette.primary.main,
      },
      "& .strokeIcon": {
        stroke: theme.palette.primary.main,
      },
    },
  },
  listIcon: {
    minWidth: "auto",
    marginRight: 14,

    "& .fillIcon": {
      fill: theme.palette.text.grey,
    },
    "& .strokeIcon": {
      stroke: theme.palette.text.grey,
    },
  },
}));

export { useStyles };
