// Core
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import clsx from "clsx";

// Actions
import {
  setReplyTo,
  setChannelId,
  showChannelsSidebar,
} from "redux/actions/chat-actions";

// Icons
import { Reply } from "@material-ui/icons";

// Styles
import { useStyles } from "./chat-reply.styles";

const ChatReply = ({ message, isFeed }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleReplyTo = () => {
    if (isFeed) {
      dispatch(setChannelId(message.channel_id));
      dispatch(showChannelsSidebar(false));
      localStorage.setItem("channelId", message.channel_id);
      history.push("/chat");
    }
    dispatch(setReplyTo(message));
  };

  return (
    <span className={clsx(classes.row, classes.reply)} onClick={handleReplyTo}>
      <Reply className={classes.icon} fontSize="small" />
      <span className={classes.text}>Reply</span>
    </span>
  );
};

export default ChatReply;
