// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiAccordion-root": {
      backgroundColor: theme.palette.background.main,
      boxShadow: "none",
      borderBottom: `1px solid ${theme.palette.grey.border}`,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  wrapper: {
    "& .Mui-expanded": {
      marginTop: 0,
      marginBottom: 0,
    },

    "&.Mui-expanded": {
      minHeight: 44,
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  column: {
    flexDirection: "column",
    paddingTop: 0,
  },
}));

export { useStyles };
