// Core
import { $http } from "api";

class OrdersService {
  static async getOrders(date) {
    return $http.post("/orders/search", { date });
  }

  static async shareOrder(orderID) {
    return $http.get(`/orders/share/${orderID}`);
  }

  static async getSharedOrder(orderID) {
    return $http.get(`/orders/shared/${orderID}`);
  }

  static async getOrder(orderID) {
    return $http.get(`/orders/${orderID}`);
  }

  static async getOrderAudio(orderID) {
    return $http.get(`orders/audio/${orderID}`);
  }

  static async getOrderAudioStream(orderID) {
    return $http.get(`/orders/audiostream/${orderID}`);
  }
}

export default OrdersService;
