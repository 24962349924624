// Core
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  reply: {
    borderLeft: `2px solid ${theme.palette.brown.main}`,
    borderRadius: "1px",
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    lineHeight: 1.2,
  },
  opsAlert: {
    borderColor: theme.palette.brown.main,
    color: theme.palette.brown.main,
  },
  goodAlert: {
    borderColor: theme.palette.green.alert,
    color: theme.palette.green.alert,
  },
  replyTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.medium.fontFamily,
  },
  replyTo: {
    fontFamily: theme.typography.bold.fontFamily,
  },
  replyText: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.main,
  },
}));

const ChatMessageAlertReply = ({ message }) => {
  const classes = useStyles();

  const getAlertType = () => {
    const alertType = message.replyTo.alert.type;
    if (alertType.startsWith("ops") || alertType.startsWith("cust"))
      return classes.opsAlert;
    if (alertType.startsWith("good")) return classes.goodAlert;
    return "";
  };

  return (
    <div className={clsx(classes.reply, getAlertType())}>
      <Typography
        variant="h6"
        className={clsx(classes.replyTitle, getAlertType())}
      >
        Reply to <span className={classes.replyTo}>Alert</span>
      </Typography>
      <span className={classes.replyText}>
        {message.replyTo.alert.description}
      </span>
    </div>
  );
};

export default ChatMessageAlertReply;
