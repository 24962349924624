// Types
import * as types from "redux/actiontypes/daily-types";

const searchByStore = (store) => ({
  type: types.SEARCH_BY_STORE,
  payload: store,
});

const setActiveDailyTab = (activeTab) => ({
  type: types.SET_ACTIVE_DAILY_TAB,
  payload: activeTab,
});

const setFilterBy = (value) => ({
  type: types.SET_FILTER_BY,
  payload: value,
});

const setSortByBest = (sortBy) => ({
  type: types.SET_SORT_BY_BEST,
  payload: sortBy,
});

export { searchByStore, setActiveDailyTab, setSortByBest, setFilterBy };
