// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  channel: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.background.blue,
    },
  },
  activeChannel: {
    backgroundColor: theme.palette.background.blue,
  },
  title: {
    marginRight: theme.spacing(1),
    fontSize: theme.typography.pxToRem(18),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.text.main,
    letterSpacing: "0.25px",
    whiteSpace: "normal",
  },
  counterWrapper: {
    display: "flex",
    alignItems: "center",
  },
  counter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 21,
    height: 21,
    padding: "0 5px",
    borderRadius: "100px",
    fontSize: theme.typography.pxToRem(14),
    fontFamily: theme.typography.bold.fontFamily,
    color: theme.palette.common.white,
  },
  total: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  alerts: {
    backgroundColor: theme.palette.red.alert,
    marginRight: theme.spacing(0.5),
  },
  secondary: {
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.darkGrey,
  },
  secondaryIcon: {
    width: 14,
    height: "auto",
    marginRight: theme.spacing(0.5),
  },
  personIcon: {
    width: 14,
    height: "auto",
    marginRight: theme.spacing(0.5),
  },
}));

export { useStyles };
