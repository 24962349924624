// Core
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions.esm.js";
import TimelinePlugin from "wavesurfer.js/dist/plugins/timeline.esm.js";

// Actions
import { showSnackbar } from "redux/actions/ui-actions";

// Components
import {
  Slider,
  Typography,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Link,
} from "@material-ui/core";

// Icons
import { PlayCircleFilled, Pause, GetApp } from "@material-ui/icons";
import { ReactComponent as RewindIcon } from "theme/icons/rewind.svg";
import { ReactComponent as ForwardIcon } from "theme/icons/forward.svg";
import { ReactComponent as VolumeIcon } from "theme/icons/volume.svg";
import { ReactComponent as SpeedIcon } from "theme/icons/speed.svg";
import { ReactComponent as ShareIcon } from "theme/icons/share.svg";

// Tools
import { secToMinutes } from "utils/helpers";

// Styles
import { useStyles } from "./waveplayer.styles";

const waveSurferOptions = (ref) => ({
  container: ref,
  mediaControls: false,
  waveColor: "#d1d1d1",
  progressColor: "#4677df",
  cursorColor: "#4677df",
  barWidth: 3,
  barHeight: 5,
  barRadius: 3,
  responsive: true,
  height: 100,
  audioRate: 1,
  normalize: true,
});

const WavePlayer = ({
  order: {
    id,
    stream_id: streamId,
    pretty_tags: tags,
    store_name: storeName,
    timestamp_local: timestamp,
  },
  shareUrl,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const wavePlayerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const downloadLink = useRef(null);
  const isAdmin = useSelector((state) => state.auth.user?.is_admin);
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [speedRate, setSpeedRate] = useState(1);
  const [speedOpen, setSpeedOpen] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const formattedTimestamp = timestamp?.split(".")[0];

  const updateTime = (ref) => {
    const totalTime = ref.getDuration();
    const currentTime = ref.getCurrentTime();
    setTotalTime(secToMinutes(totalTime));
    setCurrentTime(secToMinutes(currentTime));
  };

  useEffect(() => {
    const downloadLinkNode = downloadLink?.current;
    const options = waveSurferOptions(wavePlayerRef.current);

    setPlaying(false);

    waveSurferRef.current = WaveSurfer.create({
      ...options,
      url: `/api/orders/wav/${streamId}/${id}`,
      plugins: [
        TimelinePlugin.create({
          primaryFontColor: "#444851",
          secondaryFontColor: "#444851",
        }),
      ],
    });

    const wsRegions = waveSurferRef.current.registerPlugin(
      RegionsPlugin.create()
    );
    waveSurferRef.current.on("decode", () => {
      tags
        .filter((tag) => tag.type === "ops")
        .map((tag) => {
          return wsRegions.addRegion({
            start: tag.offset[0],
            end: tag.offset[1],
            color: "rgba(194, 255, 241, 0.6)",
            content: tag.pretty_name,
            drag: false,
            resize: false,
          });
        });
    });
    wsRegions.on("region-clicked", (region, e) => {
      e.stopPropagation();
      region.play();
      setPlaying(true);
    });

    waveSurferRef.current.on("ready", function () {
      updateTime(waveSurferRef.current);
    });

    waveSurferRef.current.on("finish", function () {
      setPlaying(false);
      setCurrentTime(0);
      waveSurferRef.current.stop();
    });

    waveSurferRef.current.on("seeking", function () {
      updateTime(waveSurferRef.current);
    });

    waveSurferRef.current.on("audioprocess", function () {
      updateTime(waveSurferRef.current);
    });

    return () => {
      if (downloadLinkNode) {
        downloadLinkNode.href = "";
      }
      waveSurferRef.current.destroy();
    };
  }, [tags, streamId, id]);

  useEffect(() => {
    setVolume(volume);
    waveSurferRef?.current.setVolume(volume);
  }, [volume]);

  const handlePlayPause = () => {
    setPlaying(!playing);
    if (playing) {
      waveSurferRef?.current.pause();
    } else {
      waveSurferRef?.current.play();
    }
  };

  const handleRewind = () => {
    const currentTime = waveSurferRef.current.getCurrentTime();
    waveSurferRef.current.setTime(currentTime - 10);
    setCurrentTime(secToMinutes(currentTime));
  };

  const handleForward = () => {
    const currentTime = waveSurferRef.current.getCurrentTime();
    const duration = waveSurferRef.current.getDuration();
    waveSurferRef.current.setTime(currentTime + 10);
    setCurrentTime(secToMinutes(currentTime));
    if (currentTime > duration || currentTime === 0) {
      waveSurferRef.current.stop();
      setPlaying(false);
    }
  };

  const handleSpeed = (e) => {
    const value = e.target.value;
    waveSurferRef.current.skip(0);
    waveSurferRef.current.setPlaybackRate(value);
    setSpeedRate(Number(value));
    setSpeedOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    const newVolume = Number(newValue);
    if (newVolume) {
      setVolume(newVolume);
      waveSurferRef.current.setVolume(newVolume || 1);
    }
  };

  const handleSpeedClose = () => setSpeedOpen(false);

  const handleSpeedOpen = () => setSpeedOpen(true);

  const handleCopyText = () => {
    dispatch(showSnackbar(true));
  };

  const handleDownloadAudio = () => {
    downloadLink.current.href = `/api/orders/wav/${streamId}/${id}`;
    setTimeout(() => {
      downloadLink.current.href = "";
    }, 1000);
  };

  return (
    <section className={classes.root}>
      <div ref={wavePlayerRef} />

      <div className={classes.duration}>
        <Typography
          className={classes.time}
          variant="caption"
          color="textPrimary"
        >
          {currentTime} / {totalTime}
        </Typography>
      </div>

      <div className={classes.controls}>
        <Typography
          className={classes.currentTime}
          variant="caption"
          color="textPrimary"
        >
          {currentTime}
        </Typography>
        <Typography
          className={classes.totalTime}
          variant="caption"
          color="textPrimary"
        >
          {totalTime}
        </Typography>

        <div className={classes.row}>
          <IconButton
            className={clsx(classes.iconButton, classes.speedButton)}
            aria-label="speed"
            disableFocusRipple
            disableRipple
            onClick={handleSpeedOpen}
          >
            <SpeedIcon className={classes.speedIcon} />
            <Typography
              variant="caption"
              color="primary"
              className={classes.speedText}
            >
              Speed:
            </Typography>
          </IconButton>

          <FormControl className={classes.formControl}>
            <Select
              value={speedRate}
              defaultValue={speedRate}
              open={speedOpen}
              onChange={handleSpeed}
              onClose={handleSpeedClose}
              onOpen={handleSpeedOpen}
              className={classes.select}
            >
              <MenuItem value={0.75}>0.75x</MenuItem>
              <MenuItem value={1}>1x</MenuItem>
              <MenuItem value={1.25}>1.25x</MenuItem>
              <MenuItem value={1.5}>1.5x</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={clsx(classes.row, classes.playControls)}>
          <div className={classes.column}>
            <IconButton
              className={classes.iconButton}
              onClick={handleRewind}
              aria-label="rewind"
              disableFocusRipple
              disableRipple
            >
              <RewindIcon className={classes.skipIcon} />
            </IconButton>
            <Typography
              variant="caption"
              color="primary"
              className={classes.skipText}
            >
              10 sec
            </Typography>
          </div>

          <IconButton
            className={clsx(classes.iconButton, classes.playPauseIcon)}
            aria-label="play"
            onClick={handlePlayPause}
            disableFocusRipple
            disableRipple
          >
            {!playing ? (
              <PlayCircleFilled
                className={classes.playIcon}
                fontSize="large"
                color="primary"
              />
            ) : (
              <Pause
                className={classes.pauseIcon}
                fontSize="large"
                color="primary"
              />
            )}
          </IconButton>

          <div className={classes.column}>
            <Typography
              variant="caption"
              color="primary"
              className={classes.skipText}
            >
              10 sec
            </Typography>
            <IconButton
              className={classes.iconButton}
              onClick={handleForward}
              aria-label="forward"
              disableFocusRipple
              disableRipple
            >
              <ForwardIcon className={classes.skipIcon} />
            </IconButton>
          </div>
        </div>

        {isMobile && (
          <CopyToClipboard text={shareUrl} onCopy={handleCopyText}>
            <ShareIcon className={classes.shareIcon} />
          </CopyToClipboard>
        )}

        <div className={clsx(classes.row, classes.volume)}>
          <Typography
            variant="caption"
            color="primary"
            className={classes.volumeText}
          >
            Volume
          </Typography>
          <IconButton
            className={classes.iconButton}
            aria-label="volume"
            disableFocusRipple
            disableRipple
          >
            <VolumeIcon className={classes.volumeIcon} />
          </IconButton>
          <Slider
            className={classes.volumeSlider}
            min={0.01}
            max={1}
            step={0.025}
            defaultValue={volume}
            value={typeof volume === "number" ? volume : 0}
            onChange={handleSliderChange}
            aria-labelledby="volume-slider"
          />
        </div>
      </div>
      {isAdmin && (
        <div className={classes.downloadLinkWrapper}>
          <Link
            className={classes.downloadLink}
            ref={downloadLink}
            download={`${storeName}_${formattedTimestamp}`}
            onClick={handleDownloadAudio}
            underline="always"
            rel="noopener noreferrer"
            target="_blank"
          >
            <GetApp className={classes.downloadIcon} fontSize="small" />
            Download audio
          </Link>
        </div>
      )}
    </section>
  );
};

export default WavePlayer;
