// Core
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams, Link as RouterLink } from "react-router-dom";
import moment from "moment";
import clsx from "clsx";

// Actions
import { getStoreStatsById } from "redux/actions/stores-actions";

// Components
import {
  useMediaQuery,
  Typography,
  Paper,
  Grid,
  Link,
  Breadcrumbs,
} from "@material-ui/core";
import {
  MuiWrapper,
  MuiLoader,
  MuiTooltip,
  MuiBackButton,
} from "components/common";
import { Alert } from "@material-ui/lab";
import {
  Catcher,
  BarChartByWeek,
  RankChatByWeek,
  StoresSelect,
  StoresRank,
  StoreTable,
} from "components";

// Icons
import { PersonOutline, NavigateNext } from "@material-ui/icons";
import { ReactComponent as CalendarIcon } from "theme/icons/calendar.svg";

// Styles
import { useStyles } from "./store.styles";

// Tools
import {
  formatNumber,
  formatRank,
  formatCurrency,
  formatTimes,
  formatRate,
} from "utils/helpers";

function createData(name, store, company, brand) {
  return { name, store, company, brand };
}

const Store = () => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [data, setData] = useState(null);
  const week_of = moment.parseZone(data?.date_to).format("MM/DD");

  const { isLoading, isError, error, refetch } = useQuery(
    ["store", id],
    () => dispatch(getStoreStatsById(id)),
    {
      onSuccess: (data) => {
        setData(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setData(null);
    refetch();
  }, [id, refetch]);

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      {data && (
        <MuiWrapper maxWidth="xl" isMobile={isMobile}>
          <div
            className={clsx(
              classes.topNavigation,
              isMobile ? classes.container : ""
            )}
          >
            {isMobile ? (
              <MuiBackButton to="/stores" />
            ) : (
              <Breadcrumbs
                aria-label="breadcrumb"
                className={classes.breadcrumb}
                separator={<NavigateNext fontSize="small" />}
              >
                <Link
                  className={classes.breadcrumbItemRoot}
                  component={RouterLink}
                  to="/stores"
                >
                  Stores
                </Link>
                <Typography className={classes.breadcrumbItem}>
                  {data.store_name}
                </Typography>
              </Breadcrumbs>
            )}
          </div>

          <div
            className={clsx(classes.store, isMobile ? classes.container : "")}
          >
            <div className={classes.storeInfo}>
              <div className={clsx(classes.row, classes.storeTitles)}>
                <Typography variant="h3" className={classes.storeNameTitle}>
                  {data.store_name}
                </Typography>
              </div>

              <div className={classes.row}>
                {data.date_to && (
                  <div className={clsx(classes.row, classes.storeDate)}>
                    <CalendarIcon className={classes.storeDateIcon} />
                    <span className={classes.storeText}>Week of {week_of}</span>
                  </div>
                )}
                <div className={classes.row}>
                  <PersonOutline className={classes.storeUserIcon} />
                  <span className={classes.storeText}>
                    {data.manager_district_name}
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.storeSelect}>
              <StoresSelect />
            </div>
          </div>

          <div
            className={clsx(classes.charts, isMobile ? classes.container : "")}
          >
            <Paper className={classes.card}>
              <div className={classes.cardInfo}>
                <Typography
                  className={clsx(
                    classes.row,
                    classes.cardTitle,
                    classes.noWrap
                  )}
                  variant="h5"
                >
                  Overall rank
                  <MuiTooltip
                    title="A restaurant's rank among all other stores on the Outloud's platform."
                    className={classes.cardTooltip}
                  />
                </Typography>
                <div className={clsx(classes.row, classes.rankList)}>
                  {data.metrics?.outloud_rank && (
                    <div className={clsx(classes.row, classes.rankItem)}>
                      <Typography variant="body1" className={classes.rankTitle}>
                        Rank
                      </Typography>
                      <StoresRank
                        rank={formatRank(data.metrics.outloud_rank)}
                      />
                    </div>
                  )}
                  {data.metricsCompany?.outloud_rank && (
                    <div className={clsx(classes.row, classes.rankItem)}>
                      <Typography variant="body1" className={classes.rankTitle}>
                        My stores average
                      </Typography>
                      <StoresRank
                        rank={formatRank(data.metricsCompany.outloud_rank)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <RankChatByWeek
                data={data}
                metric="outloud_rank"
                format="rank"
                mainColor="#4b7fee"
                showAvgs
              />
            </Paper>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Paper className={clsx(classes.card, classes.barChart)}>
                  <div className={classes.tableWrapper}>
                    <StoreTable
                      title="Operations"
                      storeName={data.store_name}
                      ranks={{
                        store: data.metrics?.est_ops_lost_revenue_rank,
                        company: data.metricsCompany?.est_ops_lost_revenue_rank,
                      }}
                      rows={[
                        createData(
                          <div className={classes.row}>
                            <Typography
                              className={clsx(
                                classes.tableSubtitle,
                                classes.noWrap
                              )}
                              variant="subtitle1"
                            >
                              Total lost revenue ($)
                            </Typography>
                            <MuiTooltip
                              title="Estimated revenue lost due to the ops issues."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          <span className={classes.tableSubtitle}>
                            {formatCurrency(data.metrics?.est_ops_lost_revenue)}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatCurrency(
                              data.metricsCompany?.est_ops_lost_revenue
                            )}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatCurrency(
                              data.metricsBrand?.est_ops_lost_revenue
                            )}
                          </span>
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Out of product
                            </span>
                            <MuiTooltip
                              title="Ordered food item is not in stock, not on the menu, discontinued, etc."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatNumber(data.metrics?.ops_product_issue_cnt),
                          formatNumber(
                            data.metricsCompany?.ops_product_issue_cnt
                          ),
                          formatNumber(data.metricsBrand?.ops_product_issue_cnt)
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Equipment down
                            </span>
                            <MuiTooltip
                              title="Restaurant equipment is not working."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatNumber(data.metrics?.ops_broken_equip_cnt),
                          formatNumber(
                            data.metricsCompany?.ops_broken_equip_cnt
                          ),
                          formatNumber(data.metricsBrand?.ops_broken_equip_cnt)
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Payment issues
                            </span>
                            <MuiTooltip
                              title="Pin pads are down, location is not accepting cash, etc."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatNumber(data.metrics?.ops_payment_issue_cnt),
                          formatNumber(
                            data.metricsCompany?.ops_payment_issue_cnt
                          ),
                          formatNumber(data.metricsBrand?.ops_payment_issue_cnt)
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Order taking issues
                            </span>
                            <MuiTooltip
                              title="System is down, a team member can't take an order, store closed, etc."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatNumber(data.metrics?.ops_order_issue_cnt),
                          formatNumber(
                            data.metricsCompany?.ops_order_issue_cnt
                          ),
                          formatNumber(data.metricsBrand?.ops_order_issue_cnt)
                        ),
                      ]}
                    />
                  </div>
                  <Typography
                    className={classes.chartTitle}
                    variant="subtitle1"
                  >
                    Lost revenue due to ops
                  </Typography>
                  <BarChartByWeek
                    className={classes.d3chart}
                    data={data}
                    metric="est_ops_lost_revenue"
                    format="dollar"
                    mainColor="#4b7fee"
                    showAvgs
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Paper className={clsx(classes.card, classes.barChart)}>
                  <div className={classes.tableWrapper}>
                    <StoreTable
                      title="Positive experience"
                      storeName={data.store_name}
                      ranks={{
                        store: data.metrics?.exp_pos_any_rank,
                        company: data.metricsCompany?.exp_pos_any_rank,
                      }}
                      rows={[
                        createData(
                          <div className={classes.row}>
                            <Typography
                              className={clsx(
                                classes.tableSubtitle,
                                classes.noWrap
                              )}
                              variant="subtitle1"
                            >
                              Total (times per order)
                            </Typography>
                            <MuiTooltip
                              title="Number of times per order team members use any language that is associated with a positive guest service."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          <span className={classes.tableSubtitle}>
                            {formatTimes(data.metrics?.exp_pos_any_rate)}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatTimes(data.metricsCompany?.exp_pos_any_rate)}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatTimes(data.metricsBrand?.exp_pos_any_rate)}
                          </span>
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Thank you, please, welcome
                            </span>
                            <MuiTooltip
                              title="Number of times per order team members use 'Thank you...', 'Please...', 'Welcome...' or other mannerisms that are associated with a great guest service."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_pos_thank_please_rate),
                          formatTimes(
                            data.metricsCompany?.exp_pos_thank_please_rate
                          ),
                          formatTimes(
                            data.metricsBrand?.exp_pos_thank_please_rate
                          )
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Offering food
                            </span>
                            <MuiTooltip
                              title="Number of times per order team members use 'Would you like to...', 'Will that complete your order...' or other language that is associated with a positive guest service when offering food options."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_pos_food_offer_rate),
                          formatTimes(
                            data.metricsCompany?.exp_pos_food_offer_rate
                          ),
                          formatTimes(
                            data.metricsBrand?.exp_pos_food_offer_rate
                          )
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Welcoming guest
                            </span>
                            <MuiTooltip
                              title="Number of times per order team members use 'Welcome to...how may I help you', 'Thank you for choosing...', 'My name is...' or other friendly language when welcoming a guest."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_pos_welcome_rate),
                          formatTimes(
                            data.metricsCompany?.exp_pos_welcome_rate
                          ),
                          formatTimes(data.metricsBrand?.exp_pos_welcome_rate)
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>Apology</span>
                            <MuiTooltip
                              title="Number of times per order an apology is used."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_pos_apology_rate),
                          formatTimes(
                            data.metricsCompany?.exp_pos_apology_rate
                          ),
                          formatTimes(data.metricsBrand?.exp_pos_apology_rate)
                        ),
                      ]}
                    />
                  </div>
                  <Typography
                    className={classes.chartTitle}
                    variant="subtitle1"
                  >
                    Positive experience
                  </Typography>
                  <BarChartByWeek
                    className={classes.d3chart}
                    data={data}
                    metric="exp_pos_any_rate"
                    format="times"
                    mainColor="#4b7fee"
                    showAvgs
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Paper className={clsx(classes.card, classes.barChart)}>
                  <div className={classes.tableWrapper}>
                    <StoreTable
                      title="Negative experience"
                      storeName={data.store_name}
                      ranks={{
                        store: data.metrics?.exp_neg_any_rank,
                        company: data.metricsCompany?.exp_neg_any_rank,
                      }}
                      rows={[
                        createData(
                          <div className={classes.row}>
                            <Typography
                              className={clsx(
                                classes.tableSubtitle,
                                classes.noWrap
                              )}
                              variant="subtitle1"
                            >
                              Total (times per order)
                            </Typography>
                            <MuiTooltip
                              title="Number of times per order team members use any language that might come off as not friendly."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          <span className={classes.tableSubtitle}>
                            {formatTimes(data.metrics?.exp_neg_any_rate)}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatTimes(data.metricsCompany?.exp_neg_any_rate)}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatTimes(data.metricsBrand?.exp_neg_any_rate)}
                          </span>
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Inviting to order
                            </span>
                            <MuiTooltip
                              title="Number of times per order team members use 'Order when you're ready...', 'What do you want...' or other language that might not sound friendly when inviting guests to order."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_neg_order_invite_rate),
                          formatTimes(
                            data.metricsCompany?.exp_neg_order_invite_rate
                          ),
                          formatTimes(
                            data.metricsBrand?.exp_neg_order_invite_rate
                          )
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Offering food
                            </span>
                            <MuiTooltip
                              title="Number of times per order team members use 'Anything else...', 'Is that all...' or other language that is often associated with a negative guest service when offering food."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_neg_food_offer_rate),
                          formatTimes(
                            data.metricsCompany?.exp_neg_food_offer_rate
                          ),
                          formatTimes(
                            data.metricsBrand?.exp_neg_food_offer_rate
                          )
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Welcoming guest
                            </span>
                            <MuiTooltip
                              title="Number of times per order team members use 'Please hold...', 'I will be back with you...' or other language that is not the best practice of welcoming guests."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatTimes(data.metrics?.exp_neg_welcome_rate),
                          formatTimes(
                            data.metricsCompany?.exp_neg_welcome_rate
                          ),
                          formatTimes(data.metricsBrand?.exp_neg_welcome_rate)
                        ),
                      ]}
                    />
                  </div>
                  <Typography
                    className={classes.chartTitle}
                    variant="subtitle1"
                  >
                    Negative experience
                  </Typography>
                  <BarChartByWeek
                    className={classes.d3chart}
                    data={data}
                    metric="exp_neg_any_rate"
                    format="times"
                    mainColor="#4b7fee"
                    showAvgs
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Paper className={clsx(classes.card, classes.barChart)}>
                  <div className={classes.tableWrapper}>
                    <StoreTable
                      title="Upsell"
                      storeName={data.store_name}
                      ranks={{
                        store: data.metrics?.est_upsell_revenue_rank,
                        company: data.metricsCompany?.est_upsell_revenue_rank,
                      }}
                      rows={[
                        createData(
                          <div className={classes.row}>
                            <Typography
                              className={clsx(
                                classes.tableSubtitle,
                                classes.noWrap
                              )}
                              variant="subtitle1"
                            >
                              Total upsell revenue ($)
                            </Typography>
                            <MuiTooltip
                              title="Estimated upsell revenue."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          <span className={classes.tableSubtitle}>
                            {formatCurrency(data.metrics?.est_upsell_revenue)}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatCurrency(
                              data.metricsCompany?.est_upsell_revenue
                            )}
                          </span>,
                          <span className={classes.tableSubtitle}>
                            {formatCurrency(
                              data.metricsBrand?.est_upsell_revenue
                            )}
                          </span>
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Total offering rate
                            </span>
                            <MuiTooltip
                              title="Percentage of orders in which meal upsize, cross-sell or add-on was offered to a guest."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatRate(data.metrics?.total_upsell_offering_rate),
                          formatRate(
                            data.metricsCompany?.total_upsell_offering_rate
                          ),
                          formatRate(
                            data.metricsBrand?.total_upsell_offering_rate
                          )
                        ),
                        createData(
                          <div className={classes.row}>
                            <span className={classes.noWrap}>
                              Total success rate
                            </span>
                            <MuiTooltip
                              title="Percentage of guests who agreed to an upsell recommendation."
                              className={classes.cardTooltip}
                            />
                          </div>,
                          formatRate(data.metrics?.total_upsell_success_rate),
                          formatRate(
                            data.metricsCompany?.total_upsell_success_rate
                          ),
                          formatRate(
                            data.metricsBrand?.total_upsell_success_rate
                          )
                        ),
                      ]}
                    />
                  </div>
                  <Typography
                    className={classes.chartTitle}
                    variant="subtitle1"
                  >
                    Upsell revenue
                  </Typography>
                  <BarChartByWeek
                    className={classes.d3chart}
                    data={data}
                    metric="est_upsell_revenue"
                    format="dollar"
                    mainColor="#4b7fee"
                    showAvgs
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        </MuiWrapper>
      )}
    </Catcher>
  );
};

export default Store;
