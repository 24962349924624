// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";

// Actions
import {
  getChannelDetails,
  searchUserInChannel,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiLoader, MuiSearch, MuiEmptySearch } from "components/common";
import {
  UserCard,
  UserCardCircle,
  UserCardTitle,
  UserCardEmail,
  UserPhoneNumber,
  UserCardButton,
} from "components/settings";
import { Typography, Link, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Tools
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./channel-users.styles";

const AdminChannelUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const searchUsersInChannelValue = useSelector(
    (state) => state.admin.searchUsersInChannelValue
  );
  const [channelUsers, setChannelUsers] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(
    ["admin_channel_users", id],
    () => dispatch(getChannelDetails(id)),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (apiResponse) {
      if (searchUsersInChannelValue.length > 0) {
        const filteredUsers = apiResponse?.participants.filter(
          ({ first_name, last_name, email, phone_number }) =>
            searchBy(
              { first_name, last_name, email, phone_number },
              searchUsersInChannelValue
            )
        );

        setChannelUsers(filteredUsers);
      } else {
        setChannelUsers(apiResponse?.participants);
      }
    }
  }, [searchUsersInChannelValue, apiResponse]);

  const getCount = (count) => {
    if (count === 0) return "No users";
    return count === 1 ? `${count} user` : `${count} users`;
  };

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <div className={classes.wrapper}>
        <div className={classes.searchRow}>
          <Typography className={classes.usersCount} variant="caption">
            {channelUsers && getCount(channelUsers.length)}
          </Typography>

          <MuiSearch
            className={classes.search}
            placeholder="Search users"
            value={searchUsersInChannelValue}
            onChange={(user) => dispatch(searchUserInChannel(user))}
          />
        </div>

        {channelUsers && (
          <Grid container spacing={2}>
            {channelUsers.map((user) => (
              <Grid key={user.id} item xs={12} sm={4} md={3}>
                <UserCard>
                  <div className={classes.cardRow}>
                    <UserCardCircle user={user} />
                    <div className={classes.cardContent}>
                      <UserCardTitle
                        title={`${user.first_name} ${user.last_name}`}
                      />
                      {user.email && <UserCardEmail email={user.email} />}
                      {user.phone_number && (
                        <UserPhoneNumber phoneNumber={user.phone_number} />
                      )}
                    </div>
                  </div>
                  <Link
                    className={classes.cardLink}
                    component={RouterLink}
                    to={`/settings/admin/users/${user.id}`}
                    color="inherit"
                    underline="none"
                  >
                    <UserCardButton>Details</UserCardButton>
                  </Link>
                </UserCard>
              </Grid>
            ))}
          </Grid>
        )}

        {channelUsers?.length === 0 && <MuiEmptySearch text="No users found" />}
      </div>
    </Catcher>
  );
};

export default AdminChannelUsers;
