// Core
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Actions
import { getUserDetails, updateUserDetails } from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiInput, MuiButton, MuiForm, MuiLoader } from "components/common";
import { Typography, FormControlLabel, Switch } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Tools
import { getFirstLettersFromUserName, formatPhoneNumber } from "utils/helpers";

// Styles
import { useStyles } from "./user-info.styles";
import { useStyles as switchStyles } from "components/common/controls/switch/switch.styles";

const AdminUserInfo = () => {
  const classes = useStyles();
  const switchClasses = switchStyles();
  const { isMobile, isDesktop } = useBreakpoints();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [isStaff, setIsStaff] = useState(null);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(2, "First name should be at least 2 characters")
      .max(120, "First name should be less than 120 characters")
      .required("First name is required"),
    lastName: Yup.string()
      .min(2, "Last name should be at least 2 characters")
      .max(120, "Last name should be less than 120 characters"),
  });

  const getUserQuery = useQuery(
    ["admin_user_details", id],
    () => dispatch(getUserDetails(id)),
    {
      onSuccess: (data) => {
        setUserDetails(data);
        setIsStaff(data.is_staff);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateUserQuery = useMutation(
    (user) => dispatch(updateUserDetails(id, user)),
    {
      onSuccess: (data) => {
        setUserDetails(data);
      },
    }
  );

  const onSubmit = (values) => {
    const { firstName, lastName } = values;
    updateUserQuery.mutate({
      firstName,
      lastName,
      isStaff,
    });
  };

  if (getUserQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getUserQuery.isError) {
    return <Alert severity="error">{getUserQuery.error.message}</Alert>;
  }

  return (
    <Catcher>
      {userDetails && (
        <div className={classes.wrapper}>
          <div className={classes.userCircle}>
            {getFirstLettersFromUserName(userDetails)}
          </div>
          <div className={classes.userInfo}>
            <Typography
              className={classes.userTitle}
              component="h2"
              variant="h5"
            >
              {userDetails.first_name} {userDetails.last_name}
            </Typography>
            {userDetails.email && (
              <Typography className={classes.email} variant="body1">
                {userDetails.email}
              </Typography>
            )}
            {userDetails.phone_number && (
              <Typography className={classes.phone} variant="body1">
                {formatPhoneNumber(userDetails.phone_number)}
              </Typography>
            )}
          </div>

          <MuiForm
            noContainer={isDesktop}
            centered={isMobile}
            className={classes.formWrapper}
          >
            {updateUserQuery.isError && (
              <Alert severity="error">{updateUserQuery.error.message}</Alert>
            )}
            {updateUserQuery.isSuccess && (
              <Alert severity="success">Successfully changed</Alert>
            )}

            <Formik
              enableReinitialize
              initialValues={{
                firstName: userDetails.first_name || "",
                lastName: userDetails.last_name || "",
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(props) => (
                <Form className={classes.form} noValidate autoComplete="off">
                  <MuiInput
                    type="text"
                    name="firstName"
                    label="First name"
                    required
                  />
                  <MuiInput type="text" name="lastName" label="Last name" />
                  <div className={classes.switch}>
                    <FormControlLabel
                      className={switchClasses.formControl}
                      disabled={updateUserQuery.isLoading}
                      control={
                        <Switch
                          checked={isStaff}
                          onChange={(e) => setIsStaff(e.target.checked)}
                          name="isStaff"
                          focusVisibleClassName={switchClasses.focusVisible}
                          disableRipple
                          classes={{
                            root: switchClasses.root,
                            switchBase: switchClasses.switchBase,
                            thumb: switchClasses.thumb,
                            track: switchClasses.track,
                            checked: switchClasses.checked,
                          }}
                        />
                      }
                      label={
                        <Typography
                          className={switchClasses.label}
                          variant="caption"
                        >
                          is Staff
                        </Typography>
                      }
                    />
                  </div>
                  <MuiButton
                    disabled={updateUserQuery.isLoading}
                    className={classes.button}
                  >
                    {updateUserQuery.isLoading ? <MuiLoader /> : "Save changes"}
                  </MuiButton>
                </Form>
              )}
            </Formik>
          </MuiForm>
        </div>
      )}
    </Catcher>
  );
};

export default AdminUserInfo;
