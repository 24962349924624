// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "flex",
    alignItems: "center",
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.grey,
    userSelect: "none",
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      maxHeight: "100%",
      paddingTop: theme.spacing(3),
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
    },
  },
  filtersRow: {
    minHeight: 120,
    marginBottom: theme.spacing(3),
    "& .MuiAccordion-root": {
      backgroundColor: theme.palette.common.white,
    },
  },
  drawerTitle: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey.border}`,
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.main,
  },
  buttonGroup: {
    position: "fixed",
    bottom: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 16px",
    backgroundColor: theme.palette.common.white,
    boxShadow:
      "0px 0px 14px rgba(0, 0, 0, 0.1), 0px -1px 0px rgba(68, 72, 81, 0.06)",
  },
  button: {
    flex: "0 1 50%",
    height: 40,
    borderRadius: 6,
    padding: 0,
    marginTop: "4px",
    whiteSpace: "nowrap",
    "&:first-child": {
      marginRight: theme.spacing(2),
    },
    "& .MuiButton-label": {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.pxToRem(16),
      textTransform: "none",
      [theme.breakpoints.down(theme.breakpoints.values.small)]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
  filtersButton: {
    backgroundColor: theme.palette.background.blue,
    borderColor: theme.palette.primary.main,
    "& .MuiButton-label": {
      fontFamily: theme.typography.medium.fontFamily,
      color: theme.palette.primary.main,
    },
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.text.main,
    color: theme.palette.text.main,
  },
}));

export { useStyles };
