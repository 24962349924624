// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import clsx from "clsx";
import moment from "moment";

// Actions
import { getReports } from "redux/actions/stores-actions";

// Components
import {
  Typography,
  Box,
  Link,
  useMediaQuery,
  Paper,
  Divider,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { MuiWrapper, MuiLoader } from "components/common";
import { Catcher } from "components";

// Icons
import { ReactComponent as PdfIcon } from "theme/icons/pdf-icon.svg";
import { ReactComponent as CsvIcon } from "theme/icons/csv-icon.svg";
import { ReactComponent as XlsIcon } from "theme/icons/xlsx-icon.svg";

// Styles
import { useStyles } from "./reports.styles";

const EXT_ICONS = {
  pdf: <PdfIcon className="ext-icon" />,
  csv: <CsvIcon className="ext-icon" />,
  xlsx: <XlsIcon className="ext-icon" />,
};

const Reports = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const [reports, setReports] = useState({});

  const { error, isLoading, isError } = useQuery(
    ["reports"],
    () => dispatch(getReports()),
    {
      onSuccess: (data) => {
        if (data?.length === 0) return data;

        const formattedData = {};
        data.forEach((el) => {
          const monthYearFromNum = `${moment(el.month, "M").format("MMMM")} ${
            el.year
          }`;
          if (monthYearFromNum in formattedData) {
            formattedData[monthYearFromNum].push(el);
          } else {
            formattedData[monthYearFromNum] = [el];
          }
        });
        setReports(formattedData);
      },
    }
  );

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <MuiWrapper maxWidth="lg" isMobile={isMobile}>
        <div className={clsx(isMobile && classes.container)}>
          {!Object.keys(reports).length && (
            <Paper className={classes.empty}>
              <Typography variant="body2" color="textPrimary">
                No reports found
              </Typography>
            </Paper>
          )}

          {Object.keys(reports).length > 0 &&
            Object.keys(reports).map((monthKey) => (
              <Box key={monthKey} className={classes.monthSection}>
                <Typography
                  className={classes.title}
                  variant="h1"
                  color="inherit"
                >
                  {monthKey}
                </Typography>
                <Divider className={classes.divider} />

                {reports[monthKey].map((report) => (
                  <Box key={report.key} className={classes.wrapper}>
                    <Typography
                      className={classes.date}
                      variant="subtitle1"
                      color="inherit"
                    >
                      {moment.parseZone(report.date).format("MM/DD")}
                    </Typography>
                    <Link
                      className={classes.link}
                      href={`${process.env.REACT_APP_API_URL}/stats/report/${report.key}`}
                      underline="always"
                      rel="noopener noreferrer"
                      color="inherit"
                    >
                      {EXT_ICONS[report.ext]} {report.name}
                    </Link>
                  </Box>
                ))}
              </Box>
            ))}
        </div>
      </MuiWrapper>
    </Catcher>
  );
};

export default Reports;
