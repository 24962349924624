// Core
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      zIndex: 101,
    },
  },
  hideDrawer: {
    [theme.breakpoints.down("sm")]: {
      left: "-100%",
    },
  },
  drawerPaper: {
    zIndex: 99,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  wrapper: {
    height: "100%",
    width: "inherit",
    position: "fixed",
    overflowY: "auto",
    backgroundColor: theme.palette.background.main,
    borderRight: `1px solid ${theme.palette.grey.border}`,
    paddingBottom: 60,
    msOverflowStyle: "none",
    scrollbarWidth: "none",
  },
}));

export { useStyles };
