// Core
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: "100%",
    width: "inherit",
    position: "fixed",
    overflowY: "auto",
    backgroundColor: theme.palette.background.main,
    borderRight: `1px solid ${theme.palette.grey.border}`,
    paddingBottom: 60,
  },
  drawerPaper: {
    zIndex: 99,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("xs")]: {
      width: theme.spacing(0),
    },
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  button: {
    width: "100%",
    height: 45,
    padding: 0,
    borderRadius: 6,
    backgroundColor: theme.palette.background.blue,
    borderColor: theme.palette.primary.main,
    "& .MuiButton-label": {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.primary.main,
      textTransform: "none",
    },
  },
}));

export { useStyles };
