// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    padding: "30px 30px 60px 30px",
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
}));

export { useStyles };
