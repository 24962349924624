// Core
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";

// Actions
import {
  addFilterStore,
  removeFilterStore,
  setCurrentPage,
} from "redux/actions/filters-actions";
import { getUserStores } from "redux/actions/stores-actions";

// Actions
import { searchByStore } from "redux/actions/filters-actions";

// Components
import { Typography } from "@material-ui/core";
import {
  MuiAccordion,
  MuiLoader,
  MuiCheckbox,
  MuiSearch,
} from "components/common";
import { Alert } from "@material-ui/lab";

// Tools
import { searchBy } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  row: {
    maxHeight: 300,
    marginTop: theme.spacing(1),
    overflowY: "auto",
  },
  empty: {
    display: "block",
    textAlign: "center",
  },
}));

const MuiFilterStores = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filters);
  const searchByStoreValue = useSelector(
    (state) => state.filters.orders.searchByStore
  );
  const [stores, setStores] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(["stores"], () => dispatch(getUserStores()), {
    keepPreviousData: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (apiResponse) {
      if (searchByStoreValue.length > 0) {
        const filteredStores = apiResponse.filter(
          ({ store_name, manager_district_name, address_line }) =>
            searchBy(
              { store_name, manager_district_name, address_line },
              searchByStoreValue
            )
        );

        setStores(filteredStores);
      } else {
        setStores(apiResponse);
      }
    }
  }, [searchByStoreValue, apiResponse]);

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <>
      {stores && (
        <MuiAccordion
          direction="column"
          label="Restaurants"
          defaultExpanded
          square
        >
          <MuiSearch
            placeholder="Search"
            value={searchByStoreValue}
            onChange={(store) => dispatch(searchByStore(store))}
          />
          <div className={classes.row}>
            {stores.length === 0 && (
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.empty}
              >
                No stores found
              </Typography>
            )}
            {stores.map(({ id, store_name }) => (
              <MuiCheckbox
                key={id}
                label={store_name}
                name={id}
                handleChecked={(store) => {
                  dispatch(setCurrentPage(1));
                  dispatch(addFilterStore(store));
                }}
                handleUnChecked={(store) => {
                  dispatch(setCurrentPage(1));
                  dispatch(removeFilterStore(store));
                }}
                checkedArray={filters.orders.period.stores}
              />
            ))}
          </div>
        </MuiAccordion>
      )}
    </>
  );
};

export default MuiFilterStores;
