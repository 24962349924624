// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// Actions
import { setCurrentPage } from "redux/actions/filters-actions";

// Components
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  pagination: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: 80,
    },
  },
}));

const MuiPagination = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cur_page } = useSelector((state) => state.filters.orders.period);
  const pageCount = useSelector((state) => state.filters.orders.pageCount);

  const handleChange = (event, value) => {
    dispatch(setCurrentPage(value));
  };

  return (
    <Pagination
      count={pageCount}
      variant="outlined"
      color="primary"
      page={cur_page}
      onChange={handleChange}
      className={classes.pagination}
    />
  );
};

export default MuiPagination;
