const palette = {
  primary: {
    main: "#4b7fee",
  },
  secondary: {
    main: "#eff2f9",
  },
  background: {
    main: "#fafbfe",
    blue: "rgba(75, 127, 238, 0.15)",
    grey: "#fafafa",
  },
  text: {
    main: "#171717",
    grey: "#444851",
    lightGrey: "#a7a7a7",
    darkGrey: "rgba(0, 0, 0, 0.54)",
  },
  grey: {
    main: "#eff2f9",
    secondary: "#d8dce4",
    light: "#eff2f9",
    dark: "#eceff8",
    border: "#e4e7ee",
    divider: "#eef0f5",
  },
  red: {
    main: "#f50257",
    alert: "#f43c3c",
  },
  salmon: {
    main: "#fa8072",
  },
  yellow: {
    main: "#fff3c2",
    rank: "#ffcc00",
  },
  green: {
    main: "#90c64b",
    secondary: "#74d67e",
    light: "#33ceaa",
    rank: "#6ab480",
    alert: "#1FD76D",
  },
  brown: {
    main: "#c07603",
    secondary: "#e7a846",
    light: "#f6e8cf",
  },
  mint: "#c2fff1",
  blue: {
    main: "#d0dfff",
    light: "#EFF5FE",
  },
};

export { palette };
