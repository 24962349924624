// Types
import * as types from "redux/actiontypes/daily-types";

const initialFilterState = {
  searchByStore: "",
  sortByBest: true,
  activeDailyTab: 0,
  filterBy: "store",
};

const dailyReducer = (state = initialFilterState, action) => {
  switch (action.type) {
    case types.SEARCH_BY_STORE:
      return {
        ...state,
        searchByStore: action.payload,
      };
    case types.SET_ACTIVE_DAILY_TAB:
      return {
        ...state,
        activeDailyTab: action.payload,
      };
    case types.SET_SORT_BY_BEST:
      return {
        ...state,
        sortByBest: action.payload,
      };
    case types.SET_FILTER_BY:
      return {
        ...state,
        filterBy: action.payload,
      };
    default:
      return state;
  }
};

export { dailyReducer };
