// Service
import { ApiError } from "api";
import { OrdersService } from "services";

const getOrders = (date) => async () => {
  try {
    const { data } = await OrdersService.getOrders(date);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getOrder = (orderID) => async () => {
  try {
    const { data } = await OrdersService.getOrder(orderID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getOrderAudio = (orderID) => async () => {
  try {
    const { data } = await OrdersService.getOrderAudio(orderID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getOrderAudioStream = (orderID) => async () => {
  try {
    const { data } = await OrdersService.getOrderAudioStream(orderID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const shareOrder = (orderID) => async () => {
  try {
    const { data } = await OrdersService.shareOrder(orderID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getSharedOrder = (orderID) => async () => {
  try {
    const { data } = await OrdersService.getSharedOrder(orderID);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

export {
  getOrders,
  getOrder,
  getOrderAudio,
  getOrderAudioStream,
  shareOrder,
  getSharedOrder,
};
