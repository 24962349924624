// Core
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { useMutation } from "react-query";
import * as Yup from "yup";
import InputMask from "react-input-mask";

// Actions
import { forgotPassword } from "redux/actions/auth-actions";

// Components
import { Avatar, Typography, Link, Grid } from "@material-ui/core";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiCopyright,
  MuiLoader,
} from "components/common";
import { Alert } from "@material-ui/lab";

// Icons
import { VerifiedUser } from "@material-ui/icons";

// Tools
import { validatePhoneNumber } from "utils/helpers";

// Styles
import { useStyles } from "./forgot-password.styles";

const ForgotPasswordForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [phone, setPhone] = useState(null);

  const initialValues = {
    phoneNumber: "",
  };
  const validationSchema = Yup.object({
    phoneNumber: Yup.string().test(
      "validatePhone",
      "Please fill all the empty fields",
      (value) => {
        return value ? validatePhoneNumber(value).isValid : true;
      }
    )
      .required("Phone number is required"),
  });

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (phoneNumber) => dispatch(forgotPassword(phoneNumber)),
    {
      onSuccess: ({ email_or_phone }, { resetForm }) => {
        setPhone(email_or_phone);
        resetForm();
      },
    }
  );

  const onSubmit = (values, { resetForm }) => {
    const { phoneNumber } = values;
    mutate({
      phoneNumber: validatePhoneNumber(phoneNumber).value,
      resetForm
    });
  };

  const handleBackButtonClick = () => history.goBack();

  return (
    <MuiForm centered>
      <Avatar className={classes.avatar}>
        <VerifiedUser className={classes.icon} />
      </Avatar>
      <Typography component="h1" variant="h5">
        Phone verification
      </Typography>

      {isError && <Alert severity="error">{error.message}</Alert>}
      {isSuccess && (
        <Alert severity="success">
          Password reset link has been sent to {phone}
        </Alert>
      )}

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className={classes.form} noValidate autoComplete="off">
            <InputMask
              mask="+1\ (999) 999 9999"
              value={props.values.phoneNumber}
              onChange={props.handleChange}
            >
              {() => (
                <MuiInput
                  type="tel"
                  name="phoneNumber"
                  label="Phone number"
                  required
                />
              )}
            </InputMask>
            <Grid container item xs justifyContent="flex-end">
              <Link component="button" onClick={handleBackButtonClick} type="button">
                Back to login
              </Link>
            </Grid>
            <MuiButton disabled={isLoading} className={classes.button}>
              {isLoading ? <MuiLoader /> : "Verify"}
            </MuiButton>
          </Form>
        )}
      </Formik>
      <MuiCopyright />
    </MuiForm>
  );
};

export default ForgotPasswordForm;
