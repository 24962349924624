// Core
import { $http } from "api";

class AdminService {
  static async getCompanyUsers() {
    return $http.get("/admin/users");
  }

  static async getCompanyActiveAlerts() {
    return $http.get("/alert/settings");
  }

  static async getCompanyNewAlerts() {
    return $http.get("/alert/settings/new");
  }

  static async addNewAlerts(alerts) {
    return $http.post(`/alert/settings/insert`, {
      ...alerts,
    });
  }

  static async getUserDetails(userId) {
    return $http.get(`/admin/users/${userId}/details`);
  }

  static async updateUserDetails(userId, user) {
    return $http.post(`/admin/users/${userId}/details`, { ...user });
  }

  static async getUserChannels(userId) {
    return $http.get(`/admin/users/${userId}/user-channels`);
  }

  static async getChannels(userId) {
    return $http.get(`/admin/users/${userId}/channels`);
  }

  static async getUserNotifications(userId) {
    return $http.get(`/admin/users/${userId}/notification`);
  }

  static async updateUserNotifications(userId, notifications) {
    return $http.post(`/admin/users/${userId}/notification`, {
      ...notifications,
    });
  }

  static async addUserToChannel(userId, channelId) {
    return $http.post(`/admin/users/${userId}/add-channel`, {
      ...channelId,
    });
  }

  static async removeUserFromChannel(userId, channelId) {
    return $http.post(`/admin/users/${userId}/remove-channel`, {
      ...channelId,
    });
  }

  static async createUser(user) {
    return $http.post(`/admin/create-user`, {
      ...user,
    });
  }

  static async disableUser(userId) {
    return $http.get(`/admin/users/${userId}/disable-user`);
  }

  static async sendAuthSMS(userId) {
    return $http.get(`/admin/users/${userId}/auth-sms`);
  }

  static async getCompanyChannels() {
    return $http.get("/admin/channels");
  }

  static async getChannelDetails(channelId) {
    return $http.get(`/admin/channels/${channelId}/details`);
  }

  static async updateChannelDetails(channelId, channel) {
    return $http.post(`/admin/channels/${channelId}/details`, { ...channel });
  }

  static async getAlertDetails(alertId) {
    return $http.get(`/alert/settings/get/${alertId}`);
  }

  static async updateAlertDetails(alertId, alert) {
    return $http.post(`/alert/settings/update/${alertId}`, { ...alert });
  }

  static async deleteAlert(alertId) {
    return $http.get(`/alert/settings/delete/${alertId}`);
  }

  static async getChannelAlerts(channelId) {
    return $http.get(`/admin/channels/${channelId}/details`);
  }

  static async updateChannelAlerts(channelId, alerts) {
    return $http.post(`/admin/channels/${channelId}/alerts`, {
      ...alerts,
    });
  }
}

export default AdminService;
