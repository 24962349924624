// Core
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import clsx from "clsx";

// Actions
import { showSettingsSidebar } from "redux/actions/settings-actions";
import {
  getCompanyActiveAlerts,
  searchByAlerts,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import {
  MuiBackButton,
  MuiLoader,
  MuiSearch,
  MuiEmptySearch,
} from "components/common";
import { Paper, CreateCard, AdminTitle } from "components/settings";
import { Link, Grid, Typography, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { ReactComponent as CreateUserIcon } from "theme/icons/create-alert.svg";

// Tools
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./alerts.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminAlerts = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useBreakpoints();
  const searchAlertsValue = useSelector(
    (state) => state.admin.searchAlertsValue
  );
  const [alerts, setAlerts] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(
    ["admin_active_alerts"],
    () => dispatch(getCompanyActiveAlerts()),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (apiResponse) {
      if (searchAlertsValue.length > 0) {
        const filteredAlerts = apiResponse.filter(({ pretty_name, type }) =>
          searchBy({ pretty_name, type }, searchAlertsValue)
        );

        setAlerts(filteredAlerts);
      } else {
        setAlerts(apiResponse);
      }
    }
  }, [searchAlertsValue, apiResponse]);

  const handleBackButtonClick = () => dispatch(showSettingsSidebar(true));

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton
            to="/settings/admin/alerts"
            onClick={handleBackButtonClick}
          />
          <AdminTitle title="Alerts" />
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          <div className={classes.titleRow}>
            {isDesktop && <AdminTitle title="Alerts" />}

            {alerts && (
              <MuiSearch
                className={classes.search}
                placeholder="Search alerts"
                value={searchAlertsValue}
                onChange={(alert) => dispatch(searchByAlerts(alert))}
              />
            )}
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <CreateCard
                icon={<CreateUserIcon />}
                title="Add Alert"
                linkTo="/settings/admin/alerts/create-alert"
              />
            </Grid>

            {alerts &&
              alerts.map((alert) => (
                <Grid key={alert.id} item xs={12} sm={4} md={3}>
                  <section className={classes.card}>
                    <div className={classes.cardRow}>
                      <div className={classes.cardAvatar}>
                        {(alert?.type.startsWith("ops") ||
                          alert?.type.startsWith("cust")) &&
                          "O"}
                        {(alert?.type.startsWith("good") ||
                          alert?.type.startsWith("info")) &&
                          "A"}
                        {alert?.type.startsWith("message") && "M"}
                      </div>
                      <div className={classes.cardContent}>
                        <Typography
                          className={classes.cardTitle}
                          variant="subtitle1"
                        >
                          {alert?.pretty_name}
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                          {(alert?.type.startsWith("ops") ||
                            alert?.type.startsWith("cust")) &&
                            "Operational"}
                          {(alert?.type.startsWith("good") ||
                            alert?.type.startsWith("info")) &&
                            "Award"}
                          {alert?.type.startsWith("message") && "Message"}
                        </Typography>
                        {alert?.threshold !== null && (
                          <Typography
                            className={classes.cardThreshold}
                            variant="body1"
                          >
                            Threshold: {alert?.threshold}
                          </Typography>
                        )}
                        {alert?.ignore !== null && alert?.ignore.length > 0 && (
                          <Typography
                            className={classes.ignoreCount}
                            variant="body1"
                          >
                            Ignored count: {alert?.ignore.length}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <Link
                      className={classes.cardLink}
                      component={RouterLink}
                      to={`/settings/admin/alerts/${alert?.id}`}
                      color="inherit"
                      underline="none"
                    >
                      <Button
                        className={classes.cardButton}
                        size="small"
                        variant="outlined"
                        color="primary"
                        fullWidth
                      >
                        Details
                      </Button>
                    </Link>
                  </section>
                </Grid>
              ))}
          </Grid>

          {alerts?.length === 0 && <MuiEmptySearch text="No alerts found" />}
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminAlerts;
