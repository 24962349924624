// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0px 1px 0px rgba(68, 72, 81, 0.06)",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.common.white,
  },
  row: {
    display: "flex",
    alignItems: "stretch",
  },
  logo: {
    width: "177px",
    height: "auto",
  },
}));

export { useStyles };
