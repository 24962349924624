// Core
import React, { useState, useEffect } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, useMutation, useQueryClient } from "react-query";
import clsx from "clsx";

// Actions
import {
  getChannels,
  searchByChannels,
  addUserToChannel,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import {
  MuiBackButton,
  MuiLoader,
  MuiSearch,
  MuiEmptySearch,
} from "components/common";
import {
  Paper,
  ChannelCard,
  ChannelCardAddress,
  ChannelCardButton,
  ChannelCardTitle,
  AdminTitle,
} from "components/settings";
import { Typography, Grid, Breadcrumbs, Link } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { NavigateNext } from "@material-ui/icons";

// Tools
import { searchBy } from "utils/helpers";

// Styles
import { useStyles } from "./add-channel.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AdminUserAddChannel = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useBreakpoints();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const searchChannelsValue = useSelector(
    (state) => state.admin.searchChannelsValue
  );
  const [channels, setChannels] = useState(null);
  const [channelId, setChannelId] = useState(null);

  const {
    data: apiResponse,
    error,
    isLoading,
    isError,
  } = useQuery(
    ["admin_users_add_channel", id],
    () => dispatch(getChannels(id)),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const addUserToChannelQuery = useMutation(
    (channelId) => dispatch(addUserToChannel(id, channelId)),
    {
      onSuccess: (data) => {
        setChannels(data);
        queryClient.invalidateQueries(["admin_users_add_channel", id]);
      },
    }
  );

  useEffect(() => {
    if (apiResponse) {
      if (searchChannelsValue.length > 0) {
        const filteredChannels = apiResponse.filter(({ store }) => {
          if (!store) return false;
          const {
            address_line,
            state,
            city,
            manager_district_name,
            store_name,
          } = store;
          return searchBy(
            { address_line, state, city, manager_district_name, store_name },
            searchChannelsValue
          );
        });

        setChannels(filteredChannels);
      } else {
        setChannels(apiResponse);
      }
    }
  }, [searchChannelsValue, apiResponse]);

  const handleAddUser = (channel) => {
    setChannelId(channel.id);
    addUserToChannelQuery.mutate({ channelId: channel.id });
  };

  return (
    <Catcher>
      {isMobile && (
        <div className={globalClasses.container}>
          <MuiBackButton to={`/settings/admin/users/${id}`} />
          <AdminTitle title="All channels" />
        </div>
      )}

      <div className={clsx(isMobile && globalClasses.container)}>
        <Paper>
          {isDesktop && (
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumb}
              separator={<NavigateNext fontSize="small" />}
            >
              <Link
                className={classes.breadcrumbItemRoot}
                component={RouterLink}
                to={`/settings/admin/users/${id}`}
              >
                User details
              </Link>
              <Typography className={classes.breadcrumbItem}>
                All channels
              </Typography>
            </Breadcrumbs>
          )}

          <div className={classes.titleRow}>
            {isDesktop && <AdminTitle title="All channels" />}

            {channels && (
              <MuiSearch
                className={classes.search}
                placeholder="Search channel"
                value={searchChannelsValue}
                onChange={(channel) => dispatch(searchByChannels(channel))}
              />
            )}
          </div>

          {isLoading && <MuiLoader fullpage />}
          {isError && <Alert severity="error">{error.message}</Alert>}
          {addUserToChannelQuery.isError && (
            <Alert severity="error">
              {addUserToChannelQuery.error.message}
            </Alert>
          )}

          {channels?.length === 0 && (
            <MuiEmptySearch text="No channels found" />
          )}

          {channels && (
            <Grid container spacing={2}>
              {channels.map((channel) => (
                <Grid key={channel.id} item xs={12} sm={4} md={3}>
                  <ChannelCard>
                    <div>
                      <ChannelCardTitle title={channel.name} />
                      <ChannelCardAddress
                        addressLine={channel.store?.address_line}
                        state={channel.store?.state}
                        city={channel.store?.city}
                        description={channel.description}
                      />
                    </div>
                    <ChannelCardButton
                      onClick={() => handleAddUser(channel)}
                      isSubscribed={channel.is_subscribed}
                      disabled={
                        channelId === channel.id &&
                        addUserToChannelQuery.isLoading
                      }
                    >
                      {channelId === channel.id &&
                      addUserToChannelQuery.isLoading ? (
                        <MuiLoader fullpage size="20px" />
                      ) : (
                        "Assign user to channel"
                      )}
                    </ChannelCardButton>
                  </ChannelCard>
                </Grid>
              ))}
            </Grid>
          )}
        </Paper>
      </div>
    </Catcher>
  );
};

export default AdminUserAddChannel;
