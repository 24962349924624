// Types
import * as types from "redux/actiontypes/feed-types";

const initialState = {
  searchFeedAlertsValue: "",
};

const feedReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_BY_FEED_ALERTS:
      return {
        ...state,
        searchFeedAlertsValue: action.payload,
      };
    default:
      return state;
  }
};

export { feedReducer };
