// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions
import { logout } from "redux/actions/auth-actions";
import { clearChannel } from "redux/actions/chat-actions";
import { showSettingsSidebar } from "redux/actions/settings-actions";

// Components
import {
  AppBar,
  Toolbar,
  Typography,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@material-ui/core";

// Icons
import {
  NotificationsNone,
  Close,
  ExitToApp,
  Settings,
  Schedule as ScheduleIcon,
} from "@material-ui/icons";
import { ReactComponent as LogoIcon } from "theme/icons/logo.svg";
import { ReactComponent as AudioIcon } from "theme/icons/audio.svg";
import { ReactComponent as ReportIcon } from "theme/icons/report.svg";
import { ReactComponent as FeedIcon } from "theme/icons/store.svg";

// Styles
import { useStyles } from "./header.styles";

const MuiHeaderMobile = ({ title, icon }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleNavigate = (to) => {
    if (to === "/settings/user-info") {
      dispatch(showSettingsSidebar(true));
    }
    setIsOpen(false);
    setTimeout(() => {
      history.push(to);
    }, 100);
  };

  const handleChatClick = () => {
    dispatch(clearChannel());
    handleNavigate("/chat");
  };

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            className={classes.iconButton}
            aria-label="burger"
            disableFocusRipple
            disableRipple
            onClick={toggleDrawer(true)}
          >
            <div className={classes.row}>
              <div className={classes.burgerIcon}>
                <span className={classes.line}></span>
                <span className={classes.line}></span>
                <span className={classes.line}></span>
              </div>
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.burgerText}
              >
                Menu
              </Typography>
            </div>
          </IconButton>

          {(title || icon) && (
            <Typography
              variant="h2"
              color="textPrimary"
              className={classes.title}
            >
              <div className={classes.titleIcon}>{icon}</div>
              {title}
            </Typography>
          )}
        </Toolbar>
      </AppBar>

      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className={classes.list} role="presentation">
          <List>
            <ListItem
              className={classes.close}
              button
              onClick={toggleDrawer(false)}
            >
              <Close className={classes.closeIcon} fontSize="large" />
              <Typography
                variant="caption"
                color="textPrimary"
                className={classes.burgerText}
              >
                Close
              </Typography>
            </ListItem>
            <ListItem
              className={classes.logoItem}
              onClick={() => handleNavigate("/")}
            >
              <LogoIcon className={classes.logo} />
            </ListItem>
            <ListItem
              button
              className={classes.item}
              onClick={() => handleNavigate("/feed/unres")}
            >
              <ListItemIcon className={classes.itemIconWrapper}>
                <FeedIcon className={classes.itemIcon} />
              </ListItemIcon>
              <ListItemText primary="Feed" className={classes.itemText} />
            </ListItem>
            <ListItem button className={classes.item} onClick={handleChatClick}>
              <ListItemIcon className={classes.itemIconWrapper}>
                <NotificationsNone className={classes.itemIcon} />
              </ListItemIcon>
              <ListItemText primary="Chat" className={classes.itemText} />
            </ListItem>
            <ListItem
              button
              className={classes.item}
              onClick={() => handleNavigate("/audios")}
            >
              <ListItemIcon className={classes.itemIconWrapper}>
                <AudioIcon className={classes.itemIcon} />
              </ListItemIcon>
              <ListItemText primary="Audios" className={classes.itemText} />
            </ListItem>

            <ListItem
              button
              className={classes.item}
              onClick={() => handleNavigate("/daily")}
            >
              <ListItemIcon className={classes.itemIconWrapper}>
                <ScheduleIcon className={classes.itemIcon} />
              </ListItemIcon>
              <ListItemText primary="Daily" className={classes.itemText} />
            </ListItem>
            <ListItem
              button
              className={classes.item}
              onClick={() => handleNavigate("/reports")}
            >
              <ListItemIcon className={classes.itemIconWrapper}>
                <ReportIcon className={classes.itemIcon} />
              </ListItemIcon>
              <ListItemText primary="Reports" className={classes.itemText} />
            </ListItem>
            <ListItem
              button
              className={classes.item}
              onClick={() => handleNavigate("/settings/user-info")}
            >
              <ListItemIcon className={classes.itemIconWrapper}>
                <Settings className={classes.itemIcon} />
              </ListItemIcon>
              <ListItemText primary="Settings" className={classes.itemText} />
            </ListItem>
            <ListItem
              button
              className={classes.logoutItem}
              onClick={handleLogout}
            >
              <ListItemIcon className={classes.itemIconWrapper}>
                <ExitToApp className={classes.logoutIcon} />
              </ListItemIcon>
              <ListItemText primary="Log out" className={classes.logoutText} />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MuiHeaderMobile;
