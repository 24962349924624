// Core
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

// Components
import { useMediaQuery } from "@material-ui/core";
import { MuiHeader, MuiSidebar, MuiBarSpacer } from "components/common";
import { MuiHeaderMobile } from "components";

// Icons
import { ReactComponent as AudioIcon } from "theme/icons/audio.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
  },
}));

const SidebarLayout = ({ children }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <div className={classes.root}>
      {isMobile ? (
        <MuiHeaderMobile title="Audios" icon={<AudioIcon />} />
      ) : (
        <MuiHeader />
      )}
      {isDesktop && <MuiSidebar />}

      <div className={classes.content}>
        <MuiBarSpacer />
        {children}
      </div>
    </div>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SidebarLayout;
