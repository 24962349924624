// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0px 1px 0px rgba(68, 72, 81, 0.06)",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.common.white,
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    paddingLeft: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  title: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.main,
    userSelect: "none",
  },
  titleIcon: {
    marginRight: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 18,
      height: 16,
    },
  },
  logoutIcon: {
    color: theme.palette.text.grey,
  },
  logoItem: {
    marginBottom: theme.spacing(3),
    cursor: "pointer",
  },
  logo: {
    flex: "0 0 218px",
    height: "36px",
  },
  burgerIcon: {
    display: "flex",
    flexDirection: "column",
    marginRight: 12,
  },
  line: {
    height: 2,
    backgroundColor: theme.palette.text.main,
    width: 21,
    marginBottom: 6,
    "&:first-child": {
      width: 18,
    },
    "&:last-child": {
      width: 14,
      marginBottom: 0,
    },
  },
  burgerText: {
    fontFamily: theme.typography.bold.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.main,
    userSelect: "none",
    letterSpacing: "0.15px",
  },
  list: {
    width: 280,
    height: "100%",
    "& .MuiList-root": {
      height: "100%",
    },
  },
  close: {
    marginBottom: theme.spacing(3),
    paddingLeft: 10,
  },
  closeIcon: {
    marginRight: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(1.5),
  },
  itemText: {
    "& .MuiTypography-root": {
      fontFamily: theme.typography.bold.fontFamily,
      fontSize: theme.typography.pxToRem(18),
    },
  },
  itemIconWrapper: {
    minWidth: "40px",
  },
  itemIcon: {
    color: theme.palette.text.grey,
  },
  logoutItem: {
    paddingBottom: theme.spacing(6),
    [`${theme.breakpoints.down("sm")} and (orientation: portrait)`]: {
      position: "absolute",
      bottom: 60,
      paddingBottom: 8,
    },
  },
  logoutText: {
    "& .MuiTypography-root": {
      fontFamily: theme.typography.regular.fontFamily,
      fontSize: theme.typography.pxToRem(22),
    },
  },
  disabled: {
    userSelect: "none",
    cursor: "auto",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

export { useStyles };
