// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";

// Actions
import { setActiveDailyTab } from "redux/actions/daily-actions";

// Components
import { Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

// Styles
import { useStyles } from "./daily-metric.styles";

const DailyMetrics = ({ className = "", label }) => {
  const classes = useStyles();
  const daily = useSelector((state) => state.daily);
  const dispatch = useDispatch();

  const handleChange = (_, value) => {
    dispatch(setActiveDailyTab(value));
  };

  return (
    <div className={clsx(className, classes.row)}>
      <Typography
        variant="caption"
        color="textPrimary"
        className={classes.title}
      >
        {label}
      </Typography>
      <ToggleButtonGroup
        value={daily.activeDailyTab}
        exclusive
        size="small"
        onChange={handleChange}
        aria-label="metrics"
        className={classes.buttonGroup}
      >
        <ToggleButton
          className={classes.button}
          value={0}
          aria-label="upsell"
          disabled={daily.activeDailyTab === 0}
        >
          Upsell
        </ToggleButton>
        <ToggleButton
          className={classes.button}
          value={1}
          aria-label="sos"
          disabled={daily.activeDailyTab === 1}
        >
          SoS
        </ToggleButton>
        <ToggleButton
          className={classes.button}
          value={2}
          aria-label="rule"
          disabled={daily.activeDailyTab === 2}
        >
          Rule
        </ToggleButton>
        <ToggleButton
          className={classes.button}
          value={3}
          aria-label="ops"
          disabled={daily.activeDailyTab === 3}
        >
          Ops
        </ToggleButton>
        <ToggleButton
          className={classes.button}
          value={4}
          aria-label="good"
          disabled={daily.activeDailyTab === 4}
        >
          Awards
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

DailyMetrics.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

export default DailyMetrics;
