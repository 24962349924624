// Core
import React from "react";
import { Route } from "react-router-dom";

// Layout
import { MainLayout, SidebarLayout, StaticSidebarLayout } from "layout";

const MainRoute = ({ component: Component, title, icon, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout title={title} icon={icon}>
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

const SidebarRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <SidebarLayout>
          <Component {...props} />
        </SidebarLayout>
      )}
    />
  );
};

const StaticSidebarRoute = ({
  component: Component,
  title,
  icon,
  fullHeight,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <StaticSidebarLayout title={title} icon={icon} fullHeight={fullHeight}>
          <Component {...props} />
        </StaticSidebarLayout>
      )}
    />
  );
};

export { MainRoute, SidebarRoute, StaticSidebarRoute };
