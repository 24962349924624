// Service
import { ApiError } from "api";
import { ChatService } from "services";

// Types
import * as types from "redux/actiontypes/chat-types";

const getChannelMessages = (postData) => async () => {
  try {
    const { data } = await ChatService.getChannelMessages(postData);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserChannels = () => async () => {
  try {
    const { data } = await ChatService.getUserChannels();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getChatBadges = (channelId) => async () => {
  try {
    const { data } = await ChatService.getChatBadges(channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const createMessage = (message) => async () => {
  try {
    const { data } = await ChatService.createMessage(message);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateLastTimestamp = (channelId) => async () => {
  try {
    const { data } = await ChatService.updateLastTimestamp(channelId);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const createLike = (like) => async () => {
  try {
    const { data } = await ChatService.createLike(like);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const setChannelId = (id) => ({ type: types.SET_CHANNEL_ID, payload: id });

const setStore = (store) => ({ type: types.SET_STORE, payload: store });

const setSocket = (socket) => ({ type: types.SET_SOCKET, payload: socket });

const setNewLike = (like) => ({ type: types.SET_NEW_LIKE, payload: like });

const setNewMessage = (message) => ({
  type: types.SET_NEW_MESSAGE,
  payload: message,
});

const setChannelMessages = (messages) => ({
  type: types.SET_CHANNEL_MESSAGES,
  payload: messages,
});

const setParticipants = (participants) => ({
  type: types.SET_PARTICIPANTS,
  payload: participants,
});

const addNewMessage = (message) => ({
  type: types.ADD_NEW_MESSAGE,
  payload: message,
});

const updateMessage = (message) => ({
  type: types.UPDATE_MESSAGE,
  payload: message,
});

const setUnreadCount = (message) => ({
  type: types.SET_UNREAD_COUNT,
  payload: message,
});

const updateUnreadTotalCount = (channelId) => ({
  type: types.UPDATE_UNREAD_TOTAL_COUNT,
  payload: channelId,
});

const updateUnreadAlertCount = (channelId) => ({
  type: types.UPDATE_UNREAD_ALERT_COUNT,
  payload: channelId,
});

const clearUnreadCount = (channelId) => ({
  type: types.CLEAR_UNREAD_COUNT,
  payload: channelId,
});

const setReplyTo = (message) => ({
  type: types.SET_REPLY_TO,
  payload: message,
});

const searchByChannel = (channel) => ({
  type: types.SEARCH_BY_CHANNEl,
  payload: channel,
});

const showChannelsSidebar = (bool) => ({
  type: types.SHOW_CHANNELS_SIDEBAR,
  payload: bool,
});

const clearChannel = () => {
  localStorage.removeItem("channelId");
  return { type: types.CLEAR_CHANNEL };
};

export {
  getChannelMessages,
  getUserChannels,
  createMessage,
  updateLastTimestamp,
  createLike,
  setChannelId,
  setStore,
  setSocket,
  setNewLike,
  setNewMessage,
  setChannelMessages,
  setParticipants,
  addNewMessage,
  getChatBadges,
  updateMessage,
  setUnreadCount,
  updateUnreadTotalCount,
  updateUnreadAlertCount,
  clearUnreadCount,
  clearChannel,
  setReplyTo,
  searchByChannel,
  showChannelsSidebar,
};
