// Core
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";

// Actions
import { getChatBadges } from "redux/actions/chat-actions";

// Components
import {
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Icons
import { Close } from "@material-ui/icons";
import { ReactComponent as MedalIcon } from "theme/icons/medal-icon.svg";

// Utils
import { getMonthByValue } from "utils/helpers";
import { METRICS, GOOD_ALERT_ICONS } from "utils/constants";

// Styles
import { useStyles } from "./chat-badges.styles";

const ChatBadges = ({ channelId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const { data, error, isError } = useQuery(
    ["chat_badges", channelId],
    () => dispatch(getChatBadges(channelId)),
    {
      refetchOnWindowFocus: false,
      enabled: open,
    }
  );

  return (
    <>
      <MedalIcon className={classes.medalIcon} onClick={handleDialogOpen} />

      <Dialog
        onClose={handleDialogClose}
        aria-labelledby="dialog-title"
        fullWidth
        open={open}
      >
        <DialogTitle
          id="dialog-title"
          className={classes.dialogTitle}
          onClose={handleDialogClose}
        >
          <Typography variant="h6" component="div">
            Monthly badges score
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleDialogClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers className={classes.dialogContent}>
          {isError && <Alert severity="error">{error.message}</Alert>}
          {data && (
            <>
              <Typography className={classes.date} variant="subtitle2">
                {getMonthByValue(data?.month)} {data?.year}
              </Typography>
              <Typography
                className={classes.score}
                variant="subtitle2"
                gutterBottom
              >
                Total:{" "}
                <span className={classes.badgeTotal}>{data?.total}</span>
              </Typography>
              {data?.badges?.map((badge) => (
                <Box key={badge?.type} className={classes.badgeWrapper}>
                  <div className={classes.badgeIcon}>
                    {GOOD_ALERT_ICONS[badge?.type]}
                  </div>
                  <Typography
                    className={classes.badgeDescription}
                    variant="body1"
                  >
                    {METRICS[badge?.type]}:{" "}
                    <span className={classes.badgeTotal}>{badge?.total}</span>
                  </Typography>
                </Box>
              ))}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatBadges;
