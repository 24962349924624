// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "10px 24px 10px 12px",
    backgroundColor: theme.palette.common.white,
    boxShadow:
      "0px 0px 14px rgba(0, 0, 0, 0.1), 0px -1px 0px rgba(68, 72, 81, 0.06)",
    [theme.breakpoints.down("sm")]: {},
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  controls: {
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(1),
    },
  },
  wavesWrapper: {
    position: "relative",
    flex: 1,
    marginRight: theme.spacing(5),
    paddingLeft: "60px",
  },
  waves: {
    flex: 1,
    marginRight: "20px",
  },
  currentTime: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translateY(-50%)",
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
  },
  totalTime: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
  },
  volumeWrapper: {
    marginRight: theme.spacing(5),
  },
  volumeText: {
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
  },
  volumeIcon: {
    width: 21,
    height: 16,
  },
  volumeSlider: {
    width: 100,
    color: theme.palette.text.grey,
  },
  metaRow: {
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  meta: {
    marginRight: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  title: {
    marginBottom: 2,
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
    letterSpacing: "0.25px",
  },
  calendarIcon: {
    width: 12,
    height: 12,
    marginRight: 5,
  },
  date: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.grey,
    letterSpacing: "0.15px",
  },
  shareIcon: {
    width: 12,
    height: 11,
    fill: theme.palette.text.grey,
    cursor: "pointer",
  },
  shareText: {
    paddingLeft: theme.spacing(1),
    fontFamily: theme.typography.medium.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.grey,
    cursor: "pointer",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  playIcon: {
    width: 12,
    height: 16,
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 33,
    },
  },
  pauseIcon: {
    width: 12,
    height: 16,
    [theme.breakpoints.down("sm")]: {
      width: 25,
      height: 33,
    },
  },
  prevIcon: {
    width: 12,
    height: 16,
  },
  nextIcon: {
    width: 12,
    height: 16,
  },
}));

export { useStyles };
