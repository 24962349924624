// Core
import { createTheme } from "@material-ui/core/styles";
import { palette } from "./palette";
import { typography } from "./typography";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      small: 360,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography,
  palette,
});

theme.props = {
  MuiIconButton: { disableRipple: true, disableFocusRipple: true },
};

theme.overrides = {
  MuiAlert: {
    root: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderRadius: 8,
    },
  },
  MuiTableCell: {
    head: {
      position: "relative",
    },
  },
  MuiMenu: {
    paper: {
      width: "195px",
    },
  },
  MuiTooltip: {
    tooltip: {
      padding: 11,
      fontFamily: theme.typography.regular.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      backgroundColor: "#444851",
      borderRadius: 8,
    },
    arrow: {
      color: "#444851",
    },
  },
  MuiPopover: {
    paper: {
      borderRadius: 8,
      boxShadow: "0px 4px 18px rgba(0, 0, 0, 0.144231)",
    },
  },
  MuiPickersToolbarButton: {
    toolbarBtn: {
      "& .MuiButton-label": {
        padding: "0 16px",
      },
      "& .MuiPickersToolbarText-toolbarTxt": {
        color: theme.palette.text.grey,

        "&.MuiPickersToolbarText-toolbarBtnSelected": {
          color: theme.palette.text.grey,
          fontFamily: theme.typography.medium.fontFamily,
          fontSize: theme.typography.pxToRem(22),
          textDecoration: "none",
        },
      },
    },
  },
  MuiPickersTimePickerToolbar: {
    hourMinuteLabel: {
      "& button:first-child": {
        marginRight: theme.spacing(2),
      },
      "& .MuiPickersToolbarText-toolbarTxt": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 75,
        height: 45,
        border: `1px solid ${theme.palette.grey.border}`,
        borderRadius: 8,
        fontFamily: theme.typography.bold.fontFamily,
        fontSize: theme.typography.pxToRem(18),
        letterSpacing: "0.15px",
        backgroundColor: theme.palette.grey.light,
        color: theme.palette.text.grey,
      },
      "& .MuiPickersToolbarText-toolbarBtnSelected": {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.primary.main,
      },
      "& .MuiPickersTimePickerToolbar-separator": {
        display: "none",
      },
    },
    ampmSelection: {
      marginLeft: theme.spacing(2),
      marginRight: 0,
      "& .MuiPickersToolbarText-toolbarTxt": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 46,
        height: 22,
        border: `1px solid ${theme.palette.grey.border}`,
        borderRadius: 8,
        fontFamily: theme.typography.bold.fontFamily,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 0,
        letterSpacing: "0.15px",
        backgroundColor: theme.palette.grey.light,
        color: theme.palette.text.grey,
      },
      "& .MuiPickersToolbarText-toolbarBtnSelected": {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.background.blue,
        color: theme.palette.primary.main,
      },
      "& .MuiPickersToolbarButton-toolbarBtn:first-child": {
        "& .MuiPickersToolbarText-toolbarTxt": {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
      "& .MuiPickersToolbarButton-toolbarBtn:last-child": {
        "& .MuiPickersToolbarText-toolbarTxt": {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    toolbarAmpmLeftPadding: {
      paddingLeft: 0,
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      height: "auto",
      padding: "20px 0 0 0",
      backgroundColor: theme.palette.common.white,
    },
  },
  MuiPickersClock: {
    clock: {
      backgroundColor: theme.palette.grey.light,
      "& .MuiPickersClockNumber-clockNumber": {
        fontFamily: theme.typography.medium.fontFamily,
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.common.black,
      },
      "& .MuiPickersClockNumber-clockNumberSelected": {
        color: theme.palette.common.white,
      },
    },
  },
  MuiListItem: {
    root: {
      "&.MuiMenuItem-root": {
        fontSize: theme.typography.pxToRem(14),
        color: theme.palette.text.main,
      },
      "&.MuiMenuItem-root:hover": {
        backgroundColor: "rgba(75, 127, 238, 0.08)",
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.background.blue,
        fontFamily: theme.typography.medium.fontFamily,
        color: theme.palette.primary.main,
      },
      "&.Mui-selected:hover": {
        backgroundColor: theme.palette.background.blue,
      },
    },
  },
};

export default theme;
