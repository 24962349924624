// Core
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";

// Actions
import {
  getChannelAlerts,
  updateChannelAlerts,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { MuiLoader, MuiSwitch, MuiEmptySearch } from "components/common";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Styles
import { useStyles } from "./channel-alerts.styles";

const AdminChannelAlerts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [notifications, setNotifications] = useState(null);

  const getNotificationsQuery = useQuery(
    ["admin_channel_notifications", id],
    () => dispatch(getChannelAlerts(id)),
    {
      onSuccess: (data) => {
        setNotifications(data?.alerts);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateNotificationsQuery = useMutation(
    (notifications) => dispatch(updateChannelAlerts(id, notifications)),
    {
      onSuccess: (data) => {
        setNotifications(data);
      },
    }
  );

  const handleNotificationChange = (event, alert) => {
    const newAlert = {
      ...alert,
      active: event.target.checked,
    };
    const updatedAlerts = notifications.map((item) =>
      item.type === alert.type ? newAlert : item
    );
    updateNotificationsQuery.mutate({
      alerts: updatedAlerts,
    });
  };

  if (getNotificationsQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getNotificationsQuery.isError) {
    return (
      <Alert severity="error">{getNotificationsQuery.error.message}</Alert>
    );
  }

  return (
    <Catcher>
      {notifications?.length === 0 && (
        <MuiEmptySearch text="No notifications found" />
      )}

      {notifications && (
        <>
          <Typography className={classes.title} component="h1" variant="h5">
            Awards
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Select award notifications you want to receive
          </Typography>
          <Grid container spacing={1} className={classes.notifications}>
            {notifications
              .filter(
                (alert) =>
                  alert.type.startsWith("good") ||
                  alert.type.startsWith("info") ||
                  alert.type === "message"
              )
              .sort((a, b) => a.pretty_name.localeCompare(b.pretty_name))
              .map((alert) => (
                <Grid
                  className={classes.item}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  key={alert.type}
                >
                  <MuiSwitch
                    alert={alert}
                    disabled={updateNotificationsQuery.isLoading}
                    onChange={handleNotificationChange}
                  />
                </Grid>
              ))}
          </Grid>

          <Typography className={classes.title} component="h1" variant="h5">
            Operational
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Select operational notifications you want to receive
          </Typography>
          <Grid container spacing={1} className={classes.notifications}>
            {notifications
              .filter(
                (alert) =>
                  alert.type.startsWith("ops") || alert.type.startsWith("cust")
              )
              .sort((a, b) => a.pretty_name.localeCompare(b.pretty_name))
              .map((alert) => (
                <Grid
                  className={classes.item}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  key={alert.type}
                >
                  <MuiSwitch
                    alert={alert}
                    disabled={updateNotificationsQuery.isLoading}
                    onChange={handleNotificationChange}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </Catcher>
  );
};

export default AdminChannelAlerts;
