// Core
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import clsx from "clsx";

// Actions
import {
  getUserNotifications,
  updateUserNotifications,
} from "redux/actions/admin-actions";

// Components
import { Catcher } from "components";
import { TimeNotifications } from "components/settings";
import { MuiLoader, MuiSwitch, MuiEmptySearch } from "components/common";
import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// Styles
import { useStyles } from "./notifications.styles";

const AdminUserNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [notifications, setNotifications] = useState(null);

  const getNotificationsQuery = useQuery(
    ["admin_user_notifications", id],
    () => dispatch(getUserNotifications(id)),
    {
      onSuccess: (data) => {
        setNotifications(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateNotificationsQuery = useMutation(
    (notifications) => dispatch(updateUserNotifications(id, notifications)),
    {
      onSuccess: (data) => {
        setNotifications(data);
      },
    }
  );

  const handleNotificationChange = (event, alert) => {
    const newAlert = {
      ...alert,
      enabled: event.target.checked,
    };
    const updatedAlertTypes = notifications.alert_types.map((item) =>
      item.name === alert.name ? newAlert : item
    );
    updateNotificationsQuery.mutate({
      alert_types: updatedAlertTypes,
      is_sms: notifications.is_sms,
      time_from: notifications.time_from,
      time_to: notifications.time_to,
      week_days: notifications.week_days,
    });
  };

  if (getNotificationsQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getNotificationsQuery.isError) {
    return (
      <Alert severity="error">{getNotificationsQuery.error.message}</Alert>
    );
  }

  return (
    <Catcher>
      {notifications?.alert_types?.length === 0 && (
        <MuiEmptySearch text="No notifications found" />
      )}

      {notifications?.alert_types && (
        <>
          <Typography className={classes.title} component="h1" variant="h5">
            Awards
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Select award notifications you want to receive
          </Typography>
          <Grid container spacing={1} className={classes.notifications}>
            {notifications.alert_types
              .filter(
                (alert) =>
                  alert.name.startsWith("good") || alert.name.startsWith("info") || alert.name === "message"
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((alert) => (
                <Grid
                  className={classes.item}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  key={alert.name}
                >
                  <MuiSwitch
                    alert={alert}
                    disabled={updateNotificationsQuery.isLoading}
                    onChange={handleNotificationChange}
                  />
                </Grid>
              ))}
          </Grid>

          <Typography className={classes.title} component="h1" variant="h5">
            Operational
          </Typography>
          <Typography className={classes.subtitle} variant="subtitle1">
            Select operational notifications you want to receive
          </Typography>
          <Grid container spacing={1} className={classes.notifications}>
            {notifications.alert_types
              .filter((alert) => (alert.name.startsWith("ops") || alert.name.startsWith("cust")))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((alert) => (
                <Grid
                  className={classes.item}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  key={alert.name}
                >
                  <MuiSwitch
                    alert={alert}
                    disabled={updateNotificationsQuery.isLoading}
                    onChange={handleNotificationChange}
                  />
                </Grid>
              ))}
          </Grid>

          <Typography
            className={clsx(classes.subtitle, classes.timeSubtitle)}
            variant="subtitle1"
          >
            Set a time when you want to receive notifications
          </Typography>
          <TimeNotifications
            query={updateNotificationsQuery}
            notifications={notifications}
          />
        </>
      )}
    </Catcher>
  );
};

export default AdminUserNotifications;
