// Core
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import parse from "html-react-parser";

// Actions
import { getCommunicationAlert } from "redux/actions/alert-actions";

// Components
import { Catcher } from "components";
import { MuiWrapper, MuiLoader, MuiBackButton } from "components/common";
import { Typography, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReactComponent as AlertGuestServiceIcon } from "theme/icons/alert-communication.svg";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { ReactComponent as ManagerIcon } from "theme/icons/user.svg";

// Styles
import { useStyles } from "./alert-communication.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AlertCommunication = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { isMobile } = useBreakpoints();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [alert, setAlert] = useState(null);

  const { error, isLoading, isError } = useQuery(
    ["alert_communication", id],
    () => dispatch(getCommunicationAlert(id)),
    {
      onSuccess: (data) => {
        setAlert(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <MuiWrapper maxWidth="xl" isMobile={isMobile}>
        {alert && (
          <div className={isMobile ? globalClasses.container : classes.root}>
            <MuiBackButton to={`/chat/${alert.channel_id}`} />

            <div className={classes.iconWrapper}>
              <AlertGuestServiceIcon className="alert-good-icon" />
            </div>
            <section className={classes.info}>
              <Typography
                className={classes.title}
                variant="subtitle1"
                component="h1"
              >
                {alert?.subject}
              </Typography>
              {alert?.manager_name && (
                <Box className={classes.manager}>
                  <ManagerIcon className={classes.managerIcon} />
                  <Typography variant="body1" className={classes.managerName}>
                    {alert.manager_name}
                  </Typography>
                </Box>
              )}
              <Typography className={classes.text} variant="body1">
                {alert?.text &&
                  parse(alert?.text.replace(/(\r\n|\r|\n)/g, "<br>"))}
              </Typography>
            </section>
          </div>
        )}
      </MuiWrapper>
    </Catcher>
  );
};

export default AlertCommunication;
