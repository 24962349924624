// Core
import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";

// Components
import { Drawer } from "@material-ui/core";
import { MuiBarSpacer } from "components/common";

// Styles
import { useStyles } from "./static-sidebar.styles";

const MuiStaticSidebar = ({ children, hideOnMobile }) => {
  const classes = useStyles();
  const { showChannelsSidebar } = useSelector((state) => state.chat);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      className={clsx(
        hideOnMobile && classes.drawer,
        hideOnMobile && showChannelsSidebar ? "" : classes.hideDrawer
      )}
    >
      <div className={classes.wrapper}>
        <MuiBarSpacer />
        {children}
      </div>
    </Drawer>
  );
};

export default MuiStaticSidebar;
