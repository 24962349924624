// Types
import * as types from "redux/actiontypes/admin-types";

const initialState = {
  searchCompanyUsersValue: "",
  searchUsersInChannelValue: "",
  searchUserChannelsValue: "",
  searchChannelsValue: "",
  searchAlertsValue: "",
  activeUserDetailsTab: 0,
  activeChannelDetailsTab: 0,
  newAlerts: [],
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_BY_COMPANY_USERS:
      return {
        ...state,
        searchCompanyUsersValue: action.payload,
      };
    case types.SEARCH_BY_USER_CHANNELS:
      return {
        ...state,
        searchUserChannelsValue: action.payload,
      };
    case types.SEARCH_BY_CHANNELS:
      return {
        ...state,
        searchChannelsValue: action.payload,
      };
    case types.SET_ACTIVE_USER_DETAILS_TAB:
      return {
        ...state,
        activeUserDetailsTab: action.payload,
      };
    case types.SET_ACTIVE_CHANNEL_DETAILS_TAB:
      return {
        ...state,
        activeChannelDetailsTab: action.payload,
      };
    case types.SEARCH_USER_IN_CHANNEL:
      return {
        ...state,
        searchUsersInChannelValue: action.payload,
      };
    case types.SEARCH_BY_ALERTS:
      return {
        ...state,
        searchAlertsValue: action.payload,
      };
    case types.ADD_ADMIN_ALERT:
      return {
        ...state,
        newAlerts: [...state.newAlerts, action.payload],
      };
    case types.ADD_ALL_ADMIN_ALERTS:
      return {
        ...state,
        newAlerts: action.payload,
      };
    case types.REMOVE_ADMIN_ALERT:
      return {
        ...state,
        newAlerts: [
          ...state.newAlerts.filter((alert) => alert.type !== action.payload),
        ],
      };
    default:
      return state;
  }
};

export { adminReducer };
