// Core
import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import parse from "html-react-parser";

// Components
import { ChatLike, ChatReply, ChatMessageAlertReply } from "components";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
} from "@material-ui/core";

// Styles
import { useStyles } from "./chat-message.styles";

// Tools
import {
  getAmpmFromTimestamp,
  getFirstLettersFromUserName,
} from "utils/helpers";

const ChatMessage = ({ message, socket }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.auth);
  // const { participants } = useSelector((state) => state.chat);

  const parseMessageText = (message) => {
    let messageText = message.text;
    let formattedString = "";

    if (!messageText.includes("@")) {
      return messageText;
    }

    // participants.forEach((participant) => {
    //   if (
    //     messageText.includes(
    //       `@${participant.user.first_name} ${participant.user.last_name}`
    //     )
    //   ) {
    //     formattedString = messageText.replace(
    //       `@${participant.user.first_name} ${participant.user.last_name}`,
    //       `<span class="chat_handle">@${participant.user.first_name} ${participant.user.last_name}</span>`
    //     );
    //   }
    // });

    if (formattedString.length > 0) {
      return parse(formattedString);
    }
    return messageText;
  };

  return (
    <ListItem className={classes.wrapper} alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          className={clsx(
            classes.avatar,
            user.email === message.user.email ? classes.myAvatar : ""
          )}
        >
          {getFirstLettersFromUserName(message.user)}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <div className={clsx(classes.row, classes.title)}>
              <Typography variant="h5" className={classes.messageTitle}>
                {user.email === message.user.email
                  ? "You"
                  : `${message.user.first_name} ${message.user.last_name}`}
              </Typography>
              <span className={classes.messageTime}>
                {getAmpmFromTimestamp(message.timestamp)}
              </span>
            </div>

            {message.replyTo && message.replyTo.type === "message" && (
              <div className={classes.reply}>
                <Typography variant="h6" className={classes.replyTitle}>
                  Reply to{" "}
                  <span className={classes.replyTo}>
                    {message.replyTo.user.first_name}{" "}
                    {message.replyTo.user.last_name}
                  </span>
                </Typography>
                <span className={classes.replyText}>
                  {message.replyTo.text}
                </span>
              </div>
            )}

            {message.replyTo && message.replyTo.type === "alert" && (
              <ChatMessageAlertReply message={message} />
            )}
          </>
        }
        secondary={
          <span className={clsx(classes.column, classes.text)}>
            <span className={classes.messageText}>
              {parseMessageText(message)}
            </span>

            <span className={classes.row}>
              <ChatLike message={message} socket={socket} />
              <ChatReply message={message} />
            </span>
          </span>
        }
      />
    </ListItem>
  );
};

export default ChatMessage;
