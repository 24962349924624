// Core
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery, useMutation } from "react-query";
import * as Yup from "yup";

// Actions
import {
  getResetPassword,
  postResetPassword,
} from "redux/actions/auth-actions";

// Components
import { Avatar, Typography } from "@material-ui/core";
import {
  MuiInput,
  MuiButton,
  MuiForm,
  MuiCopyright,
  MuiLoader,
} from "components/common";
import { Alert } from "@material-ui/lab";

// Icons
import { VpnKeySharp } from "@material-ui/icons";

// Styles
import { useStyles } from "./reset-password.styles";

const ResetPasswordForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, token } = useParams();

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, "Password should be 8 characters or more")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .min(8, "Password should be 8 characters or more")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords don't match"),
  });

  const getResetPasswordQuery = useQuery(
    ["reset"],
    () => dispatch(getResetPassword(id, token)),
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (password) => dispatch(postResetPassword(id, token, password)),
    {
      onSuccess: (data, { resetForm }) => {
        resetForm();
      },
    }
  );

  const onSubmit = (values, { resetForm }) => {
    const { password } = values;
    mutate({ password, resetForm });
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        history.push("/login");
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, history]);

  if (getResetPasswordQuery.isLoading) {
    return <MuiLoader fullpage />;
  }

  if (getResetPasswordQuery.isError) {
    return (
      <Alert severity="error">{getResetPasswordQuery.error.message}</Alert>
    );
  }

  return (
    <MuiForm centered>
      <Avatar className={classes.avatar}>
        <VpnKeySharp className={classes.icon} />
      </Avatar>
      <Typography component="h1" variant="h5">
        Enter new password
      </Typography>

      {isError && <Alert severity="error">{error.message}</Alert>}
      {isSuccess && (
        <Alert severity="success">Password successfully changed</Alert>
      )}

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form className={classes.form} noValidate autoComplete="off">
            <MuiInput
              type="password"
              name="password"
              label="New password"
              required
            />
            <MuiInput
              type="password"
              name="confirmPassword"
              label="Confirm new password"
              required
            />
            <MuiButton disabled={isLoading} className={classes.button}>
              {isLoading ? <MuiLoader /> : "Reset password"}
            </MuiButton>
          </Form>
        )}
      </Formik>
      <MuiCopyright />
    </MuiForm>
  );
};

export default ResetPasswordForm;
