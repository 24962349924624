// Core
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";

// Constants
import { GOOD_ALERT_ICONS } from "utils/constants";

// Actions
import { getAlert } from "redux/actions/alert-actions";

// Components
import { Catcher } from "components";
import { MuiWrapper, MuiLoader, MuiBackButton } from "components/common";
import { Typography, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ReactComponent as AlertGuestServiceIcon } from "theme/icons/alert-guest-service.svg";

// Hooks
import { useBreakpoints } from "hooks";

// Icons
import { ReactComponent as ManagerIcon } from "theme/icons/user.svg";

// Tools
import { formatRate } from "utils/helpers";

// Styles
import { useStyles } from "./alert-good.styles";
import { useGlobalStyles } from "theme/useGlobalStyles";

const AlertGood = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const { isMobile } = useBreakpoints();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [alert, setAlert] = useState(null);

  const { error, isLoading, isError } = useQuery(
    ["alert_good", id],
    () => dispatch(getAlert(id)),
    {
      onSuccess: (data) => {
        setAlert(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return <MuiLoader fullpage />;
  }

  if (isError) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <Catcher>
      <MuiWrapper maxWidth="xl" isMobile={isMobile}>
        {alert && (
          <div className={isMobile ? globalClasses.container : classes.root}>
            {history.location?.state?.from === "feed" ? (
              <MuiBackButton previousRoute={true} />
            ) : (
              <MuiBackButton to={`/chat/${alert.channel_id}`} />
            )}

            <div className={classes.iconWrapper}>
              {GOOD_ALERT_ICONS[alert?.alert?.type] || (
                <AlertGuestServiceIcon className="alert-good-icon" />
              )}
            </div>
            <section className={classes.info}>
              <Typography
                className={classes.title}
                variant="subtitle1"
                component="h1"
              >
                {alert?.alert?.description}
              </Typography>
              {alert?.alert?.metric &&
                !alert?.alert.type.endsWith("_streak") && (
                  <Typography variant="body1" className={classes.metric}>
                    <span className={classes.metricValue}>
                      {formatRate(alert?.alert?.metric)}
                    </span>{" "}
                    of orders yesterday
                  </Typography>
                )}
              {alert?.alert?.metric &&
                alert?.alert?.type.endsWith("_streak") && (
                  <Typography variant="body1" className={classes.metric}>
                    for{" "}
                    <span className={classes.metricValue}>
                      {`${Math.floor(alert?.alert?.metric)}${
                        alert?.alert?.metric >= 10 ? "+" : ""
                      }`}
                    </span>{" "}
                    days in a row of orders yesterday
                  </Typography>
                )}
              {alert?.alert?.manager_name && (
                <Box className={classes.manager}>
                  <ManagerIcon className={classes.managerIcon} />
                  <Typography variant="body1" className={classes.managerName}>
                    {alert?.alert?.manager_name}
                  </Typography>
                </Box>
              )}
              <Typography className={classes.text} variant="body1">
                {alert?.alert?.info?.info}
              </Typography>
            </section>
          </div>
        )}
      </MuiWrapper>
    </Catcher>
  );
};

export default AlertGood;
