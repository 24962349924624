// Core
import React from "react";
import { useHistory } from "react-router-dom";

// Components
import { Typography } from "@material-ui/core";

// Styles
import { useStyles } from "./create-card.styles";

const CreateCard = ({ icon, title, linkTo }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root} onClick={() => history.push(linkTo)}>
      <div className={classes.icon}>{icon}</div>
      <Typography
        className={classes.title}
        variant="h5"
        component="h2"
        gutterBottom
      >
        {title}
      </Typography>
    </div>
  );
};

export default CreateCard;
