// Core
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";

// Actions
import { showSidebar } from "redux/actions/ui-actions";

// Components
import { Drawer, Toolbar, Button } from "@material-ui/core";
import {
  MuiFilterTags,
  MuiFilterStores,
  MuiBarSpacer,
} from "components/common";

// Icons
import { Tune } from "@material-ui/icons";

// Styles
import { useStyles } from "./sidebar.styles";

const MuiSidebar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !ui.sidebarIsOpen && classes.drawerPaperClose
        ),
      }}
      open={ui.sidebarIsOpen}
    >
      <div className={classes.wrapper}>
        <MuiBarSpacer />
        <Toolbar className={classes.toolbar}>
          {ui.sidebarIsOpen && (
            <Button
              className={classes.button}
              variant="outlined"
              color="primary"
              size="medium"
              startIcon={<Tune />}
              onClick={() => dispatch(showSidebar(false))}
            >
              Hide Filters
            </Button>
          )}
        </Toolbar>
        <MuiFilterStores />
        <MuiFilterTags />
      </div>
    </Drawer>
  );
};

export default MuiSidebar;
