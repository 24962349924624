// Core
import React from "react";

// Components
import { Paper } from "@material-ui/core";

// Styles
import { useStyles } from "./paper.styles";

const SettingsPaper = ({ children }) => {
  const classes = useStyles();
  return <Paper className={classes.paper}>{children}</Paper>;
};

export default SettingsPaper;
