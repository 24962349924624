// Core
import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

// Actions
import { updateAlertStatus } from "redux/actions/alert-actions";

// Components
import { ChatAlertOps, ChatAlertUpsell, ChatAlertGood } from "components";

const ChatAlert = ({ message, socket, isFeed = false }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(null);
  const isLowUpsell = alert?.type === "ops_low_upsell";

  useEffect(() => {
    setAlert(message.alert);
  }, [message]);

  const { mutate } = useMutation(
    ({ id, status }) => dispatch(updateAlertStatus(id, status)),
    {
      onSuccess: (alert) => {
        setAlert(alert);
      },
    }
  );

  const handleStatusChange = (id) => mutate({ id, status: "resolved" });

  const handleDetailsClick = (id) => {
    if (isFeed) {
      history.push(`/alert/${id}`, { from: "feed" });
    } else {
      history.push(`/alert/${id}`, { from: "chat" });
    }
  };

  return (
    <>
      {alert && (
        <>
          {isLowUpsell && (
            <ChatAlertUpsell
              message={message}
              alert={alert}
              socket={socket}
              onStatusChange={handleStatusChange}
              onDetailsClick={handleDetailsClick}
              isFeed={isFeed}
            />
          )}
          {alert.type.startsWith("good") && (
            <ChatAlertGood
              message={message}
              alert={alert}
              socket={socket}
              isFeed={isFeed}
            />
          )}
          {(alert.type.startsWith("ops") || alert.type.startsWith("cust")) && !isLowUpsell && (
            <ChatAlertOps
              message={message}
              alert={alert}
              socket={socket}
              onStatusChange={handleStatusChange}
              onDetailsClick={handleDetailsClick}
              isFeed={isFeed}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChatAlert;
