// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
  },
  emptySearch: {
    padding: theme.spacing(4),
    borderRadius: 8,
    boxShadow: "0px 1px 7px rgba(0, 0, 0, 0.07)",
  },
  list: {
    width: "100%",
    padding: 0,
  },
  itemWrapper: {
    borderBottom: `1px solid ${theme.palette.grey.border}`,
  },
  item: {
    padding: "0 150px 0 5px",
    backgroundColor: theme.palette.common.white,
    userSelect: "none",
    "&.Mui-selected": {
      backgroundColor: theme.palette.grey.light,
    },
    "&.Mui-selected:hover": {
      backgroundColor: theme.palette.grey.light,
    },
  },
  link: {
    flex: 1,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
    },
  },
  itemText: {
    flex: "0 0 250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    zIndex: 0,
    margin: 0,
    paddingTop: 12,
    paddingBottom: 12,
    marginRight: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 30,
    },
    "& .MuiListItemText-primary": {
      whiteSpace: "nowrap",
      fontSize: theme.typography.pxToRem(16),
      fontFamily: theme.typography.medium.fontFamily,
    },
    "& .MuiListItemText-secondary": {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  calendarIcon: {
    marginRight: theme.spacing(0.5),
  },
  date: {
    marginRight: theme.spacing(0.5),
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    flex: "0 0 65px",
    position: "relative",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "2.6rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: "3.5rem",
      },
    },
  },
  player: {
    width: "100%",
    position: "fixed",
    zIndex: 100,
    bottom: 0,
    left: 0,
    backgroundColor: "#f0f3f4",
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
  },
  isReady: {
    opacity: 1,
  },
  tags: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexWrap: "wrap",
    position: "static",
    top: "auto",
    right: "auto",
    transform: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down(theme.breakpoints.values.small)]: {
      flexDirection: "column",
    },
  },
  duration: {
    flex: "0 0 80px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      position: "absolute",
      bottom: "9px",
    },
  },
  durationText: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.grey,
    whiteSpace: "nowrap",
  },
  durationIcon: {
    marginRight: theme.spacing(1),
  },
  shareRow: {
    position: "absolute",
    right: 16,
    display: "flex",
    marginLeft: theme.spacing(3),
  },
  shareItem: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
    cursor: "pointer",
    "& span": {
      fontSize: theme.typography.pxToRem(13),
      fontFamily: theme.typography.medium.fontFamily,
      color: theme.palette.text.grey,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  shareIcon: {
    width: 12,
    height: 11,
    marginRight: theme.spacing(0.5),
    fill: theme.palette.text.grey,
  },
  tag: {
    height: 20,
    border: "none",
    borderRadius: 6,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    cursor: "pointer",
    textTransform: "lowercase",
    color: theme.palette.text.main,
    fontSize: theme.typography.pxToRem(14),
  },
  tagOps: {
    backgroundColor: theme.palette.mint,
  },
  tagUpsell: {
    backgroundColor: theme.palette.yellow.main,
  },
  tagProduct: {
    backgroundColor: theme.palette.blue.main,
  },
}));

export { useStyles };
