// Service
import { ApiError } from "api";
import { SettingsService } from "services";

// Types
import * as types from "redux/actiontypes/settings-types";

const getUserDetails = () => async () => {
  try {
    const { data } = await SettingsService.getUserDetails();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateUserDetails = (user) => async () => {
  try {
    const { data } = await SettingsService.updateUserDetails(user);
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const getUserNotifications = () => async () => {
  try {
    const { data } = await SettingsService.getUserNotifications();
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const updateUserNotifications = (notifications) => async () => {
  try {
    const { data } = await SettingsService.updateUserNotifications(
      notifications
    );
    return data;
  } catch (error) {
    const message = ApiError.getErrorMessage(error);
    throw new Error(message);
  }
};

const showSettingsSidebar = (bool) => ({
  type: types.SHOW_SETTINGS_SIDEBAR,
  payload: bool,
});

export {
  getUserDetails,
  updateUserDetails,
  getUserNotifications,
  updateUserNotifications,
  showSettingsSidebar,
};
