// Types
import * as types from "redux/actiontypes/ui-types";

const initialState = {
  sidebarIsOpen: true,
  snackbarIsOpen: false,
  audioIsPlaying: false,
  audioLoopIsActive: false,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        sidebarIsOpen: action.payload,
      };
    case types.SHOW_SNACKBAR:
      return {
        ...state,
        snackbarIsOpen: action.payload,
      };
    case types.AUDIO_IS_PLAYING:
      return {
        ...state,
        audioIsPlaying: action.payload,
      };
    case types.AUDIO_LOOP:
      return {
        ...state,
        audioLoopIsActive: action.payload,
      };
    default:
      return state;
  }
};

export { uiReducer };
