// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rank: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 11,
    height: 11,
    borderRadius: 100,
    marginRight: 6,
  },
  text: {
    lineHeight: 1.2,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
  red: {
    backgroundColor: theme.palette.red.alert,
  },
  yellow: {
    backgroundColor: theme.palette.yellow.rank,
  },
  green: {
    backgroundColor: theme.palette.green.rank,
  },
}));

export { useStyles };
