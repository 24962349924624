// Core
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    minWidth: 120,
  },
  select: {
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
    "&:before, &:after": {
      display: "none",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiSelect-icon": {
      width: 20,
      height: 20,
      top: "calc(50% - 10px)",
      color: theme.palette.text.main,
    },
  },
  title: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3.5),
    fontFamily: theme.typography.regular.fontFamily,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.main,
  },
}));

export { useStyles };
