// Core
import { $http } from "api";

class SettingsService {
  static async getUserDetails() {
    return $http.get("/settings/details");
  }

  static async updateUserDetails(user) {
    return $http.post("/settings/details", { ...user });
  }

  static async getUserNotifications() {
    return $http.get("/settings/notification");
  }

  static async updateUserNotifications(notifications) {
    return $http.post("/settings/notification", { ...notifications });
  }
}

export default SettingsService;
