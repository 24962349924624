// Core
import { $http } from "api";

class AuthService {
  static async loginWithEmailOrPhone(emailOrPhone, password) {
    return $http.post("/auth/login", { email_or_phone: emailOrPhone, password });
  }

  static async logout() {
    return $http.post("/auth/logout");
  }

  static async changePassword(passwords) {
    return $http.post("/auth/change-password", passwords);
  }

  static async forgotPassword(phoneNumber) {
    return $http.post("/auth/forgot-password", phoneNumber);
  }

  static async getResetPassword(userId, token) {
    return $http.get(`/auth/reset-password/${userId}/${token}`);
  }

  static async postResetPassword(userId, token, password) {
    return $http.post(`/auth/reset-password/${userId}/${token}`, password);
  }

  static async checkAuth() {
    return $http.get("/auth/refresh", { withCredentials: true });
  }
}

export default AuthService;
